import React from 'react';

import { getDiagrams } from '@vlabs/shared/assets/images/diagrams/diagrams';
import { useTheme } from '@vlabs/shared/context/ThemeContext';
import { routes } from '@vlabs/shared/navigation/routes';
import { Page } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import st from './StatisticPage.module.sass';
import { StatisticsCard } from './StatisticsCard';

export const StatisticPage = () => {
  const { t } = useTranslation();
  const { isLightTheme } = useTheme();
  const history = useHistory();

  const diagrams = getDiagrams(isLightTheme);

  const onClick = (type) => {
    const eventLink = generatePath(routes.events.statistic.read, { diagramType: type });
    history.push(eventLink);
  };

  return (
    <Page title={t('statistic:page title')}>
      <div className={st.StatisticsGrid}>
        <StatisticsCard
          image={diagrams.AgeDistribution}
          onClick={() => onClick('ageDistribution')}
          title={t('statistic:titles.age distribution')}
        />
        <StatisticsCard
          image={diagrams.AttendanceByGender}
          onClick={() => onClick('attendanceByGender')}
          title={t('statistic:titles.attendance by gender')}
        />
        <StatisticsCard
          image={diagrams.GenderDistribution}
          onClick={() => onClick('genderDistribution')}
          title={t('statistic:titles.gender distribution')}
        />
        <StatisticsCard
          image={diagrams.GenderRatio}
          onClick={() => onClick('genderRatio')}
          title={t('statistic:titles.gender ratio')}
        />
        <StatisticsCard
          image={diagrams.EmotionDistribution}
          onClick={() => onClick('emotionDistribution')}
          title={t('statistic:titles.emotion distribution')}
        />
        <StatisticsCard
          image={diagrams.AverageAgeDynamics}
          onClick={() => onClick('averageAgeByGender')}
          title={t('statistic:titles.average age dynamics')}
        />
      </div>
    </Page>
  );
};
