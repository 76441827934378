import React, { useState } from 'react';

import { ArrowInCircleRightIcon } from '@vlabs/icons';
import { EmptyImage } from '@vlabs/shared/components/empty-image';
import { routes } from '@vlabs/shared/navigation/routes';
import { Tag } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import st from './MatchItem.module.sass';

export const MatchItem = ({
  avatar,
  faceId,
  similarity,
  children,
  className,
}) => {
  const { t } = useTranslation();
  const [isEmpty, setIsEmpty] = useState(!avatar);

  return (
    <div className={cn(st.MatchItem__Root, className)}>
      <div className={st.Image__Wrapper}>
        {avatar && !isEmpty && (
          <img
            alt="face avatar"
            className={st.Image}
            onError={() => setIsEmpty(true)}
            src={avatar}
          />
        )}
        {isEmpty && <EmptyImage className={st.Image} />}

        <div className={st.MatchLink__Wrapper}>
          <a
            aria-label="ArrowInCircleRightIcon"
            href={generatePath(routes.facePage, { faceId })}
            rel="noreferrer"
            target="_blank"
            title={t('faces:детали лица')}
          >
            <ArrowInCircleRightIcon />
          </a>
        </div>
        <Tag
          className={st.SimilarityTag}
          kind="attention"
          value={`${similarity}%`}
        />
      </div>

      {children}
    </div>
  );
};

MatchItem.propTypes = {
  similarity: PropTypes.number,
  avatar: PropTypes.string,
  faceId: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

MatchItem.defaultProps = {
  avatar: undefined,
  faceId: undefined,
  similarity: undefined,
  className: undefined,
  children: undefined,
};
