import { chartTypeOptions, frequencyOptions, timeDimensionOptions, diagramTypeOptions, timeGroupingTypeOptions, targetOptions, aggregatorOptions } from './constants';

export const chartConfigurations = {
  ageDistribution: {
    diagramType: diagramTypeOptions.bar,
    name: chartTypeOptions?.ageDistribution?.label,
    mainTargets: [
      {
        column: targetOptions.event_id,
        aggregator: aggregatorOptions.count,
      },
    ],
    groupByTargets: [{
      column: targetOptions.age,
      aggregator: { label: 'group_by', value: 'group_by' },
    }],
    group_by: {
      type: null,
      count: '',
      timeDimension: null,
      frequency: null,
    },
    filters: [],
  },
  attendanceByGender: {
    diagramType: diagramTypeOptions.stack,
    name: chartTypeOptions?.attendanceByGender?.label,
    mainTargets: [
      {
        column: targetOptions.event_id,
        aggregator: aggregatorOptions.count,
      },
    ],
    groupByTargets: [{
      column: targetOptions.gender,
      aggregator: { label: 'group_by', value: 'group_by' },
    }],
    group_by: {
      type: timeGroupingTypeOptions?.interval,
      count: 1,
      timeDimension: timeDimensionOptions?.d,
      frequency: null,
    },
    filters: [],
  },
  genderDistribution: {
    diagramType: diagramTypeOptions.radar,
    name: chartTypeOptions?.genderDistribution?.label,
    mainTargets: [
      {
        column: targetOptions.event_id,
        aggregator: aggregatorOptions.count,
      },
    ],
    groupByTargets: [{
      column: targetOptions.gender,
      aggregator: { label: 'group_by', value: 'group_by' },
    }],
    group_by: {
      type: timeGroupingTypeOptions?.frequency,
      frequency: frequencyOptions?.dayOfWeek,
      count: '',
      timeDimension: null,
    },
    filters: [],
  },
  genderRatio: {
    diagramType: diagramTypeOptions.doughnut,
    name: chartTypeOptions?.genderRatio?.label,
    mainTargets: [
      {
        column: targetOptions.event_id,
        aggregator: aggregatorOptions.count,
      },
    ],
    groupByTargets: [{
      column: targetOptions.gender,
      aggregator: { label: 'group_by', value: 'group_by' },
    }],
    group_by: {
      type: null,
      count: '',
      timeDimension: null,
      frequency: null,
    },
    filters: [],
  },
  emotionDistribution: {
    diagramType: diagramTypeOptions.pie,
    name: chartTypeOptions?.emotionDistribution?.label,
    mainTargets: [
      {
        column: targetOptions.event_id,
        aggregator: aggregatorOptions.count,
      },
    ],
    groupByTargets: [{
      column: targetOptions.emotion,
      aggregator: { label: 'group_by', value: 'group_by' },
    }],
    group_by: {
      type: null,
      count: '',
      timeDimension: null,
      frequency: null,
    },
    filters: [],
  },
  averageAgeByGender: {
    diagramType: diagramTypeOptions.line,
    name: chartTypeOptions?.averageAgeByGender?.label,
    mainTargets: [
      {
        column: targetOptions.age,
        aggregator: aggregatorOptions.avg,
      },
    ],
    groupByTargets: [{
      column: targetOptions.gender,
      aggregator: { label: 'group_by', value: 'group_by' },
    }],
    group_by: {
      type: timeGroupingTypeOptions?.interval,
      count: 1,
      timeDimension: timeDimensionOptions?.M,
      frequency: null,
    },
    filters: [],
  },
};
