import React, { useEffect, useRef, useMemo } from 'react';

import validate from '@vlabs/shared/validators';
import { Control, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { aggregatorOptions, diagramTypeOptions, targetOptions } from '../constants';
import st from './ClusteringForm.module.sass';

const aggregatorAvailability = {
  event_id: ['count'],
  external_id: ['count'],
  handler_id: ['count'],
  stream_id: ['count'],
  source: ['count'],
  top_matching_candidates_label: ['count'],
  top_similar_object_id: ['count'],
  top_similar_external_id: ['count'],
  top_similar_object_similarity: ['count', 'max', 'min', 'avg'],
  create_time: ['count', 'max', 'min'],
  end_time: ['count', 'max', 'min'],
  face_id: ['count'],
  gender: ['count', 'max', 'min', 'avg'],
  age: ['count', 'max', 'min', 'avg'],
  emotion: ['count', 'max', 'min', 'avg'],
  mask: ['count', 'max', 'min', 'avg'],
  liveness: ['count', 'max', 'min', 'avg'],
  deepfake: ['count', 'max', 'min', 'avg'],
  user_data: ['count'],
  tags: ['count', 'max', 'min'],
  city: ['count'],
  area: ['count'],
  district: ['count'],
  street: ['count'],
  house_number: ['count'],
  geo_position: ['count'],
  track_id: ['count'],
  apparent_age: ['count', 'max', 'min', 'avg'],
  apparent_gender: ['count', 'max', 'min', 'avg'],
  sleeve_length: ['count'],
  headwear_state: ['count', 'max', 'min', 'avg'],
  headwear_apparent_color: ['count'],
  upper_clothing_colors: ['count'],
  lower_garment_colors: ['count'],
  lower_garment_type: ['count'],
  shoes_apparent_color: ['count'],
  backpack_state: ['count', 'max', 'min', 'avg'],
  meta: ['count', 'max', 'min', 'avg'],
};

const filterAggregatorOptions = (columnValue) => {
  const availableAggregators = aggregatorAvailability[columnValue] || [];
  return aggregatorOptions.raw.filter((option) => availableAggregators.includes(option.value));
};

export const CommonParamsForm = () => {
  const { t } = useTranslation();
  const { control, register, formState: { errors }, setValue, resetState } = useFormContext();
  const selectedColumn = useWatch({ name: 'mainTargets.[0].column' });
  const previousSelectedColumnRef = useRef(selectedColumn);

  const availableAggregatorOptions = useMemo(() => {
    return filterAggregatorOptions(selectedColumn.value);
  }, [selectedColumn]);

  useEffect(() => {
    if (resetState.current.commonForm) {
      resetState.current.commonForm = false;
      return;
    }

    if (previousSelectedColumnRef?.current?.value === selectedColumn?.value) return;
    const options = availableAggregatorOptions.length === 1 ? availableAggregatorOptions[0] : null;
    setValue('mainTargets.[0].aggregator', options);

    previousSelectedColumnRef.current = selectedColumn;
  }, [selectedColumn, setValue]);

  return (
    <>
      <SettingsItemWrapper
        contentClassName={st.SettingsItem}
        title={t('statistic:form.common.name.label')}
      >
        <Control.Input
          {...register('name')}
          errors={errors}
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        contentClassName={st.SettingsItem}
        title={t('statistic:form.common.diagramType.label')}
      >
        <Control.Select
          control={control}
          name="diagramType"
          options={diagramTypeOptions.raw}
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        contentClassName={st.SettingsItem}
        data-tooltip-content={t('statistic:form.common.mainTargets.column.tooltip')}
        title={t('statistic:form.common.mainTargets.column.label')}
      >
        <Control.Select
          control={control}
          name="mainTargets.[0].column"
          options={targetOptions.raw}
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        contentClassName={st.SettingsItem}
        data-tooltip-content={t('statistic:form.common.aggregator.tooltip')}
        title={t('statistic:form.common.aggregator.label')}
      >
        <Control.Select
          control={control}
          errors={errors}
          name="mainTargets.[0].aggregator"
          options={availableAggregatorOptions}
          rules={{ required: validate.required() }}
        />
      </SettingsItemWrapper>
    </>
  );
};
