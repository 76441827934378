import React, { useState, useEffect, useCallback } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { CrosshairIcon } from '@vlabs/icons';
import { actionColumnProps } from '@vlabs/shared/utils';
import { Page, Panel, Table, TableCells, openConfirmPopup, useTableHook, Modal } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ImageCell } from '../components/image-cell/ImageCell';
import { selectPlan } from '../plans/selectors';
import * as plansStore from '../plans/store';
import CreateSourcePage from './CreateSourcePage';
import { selectLunaSourcesOptions } from './selectors';
import st from './SourceListPage.module.sass';
import { createBreadcrumbs } from './sourcesHelpers';
import * as sourceStore from './store';

const CalibrationCell = ({ row }) => {
  const { t } = useTranslation();
  const { planId } = useParams();
  const history = useHistory();

  const goToCalibrationPage = useCallback(({ id }) => {
    history.push(`/plans/${planId}/calibrations/${id}`);
  }, [planId, history]);

  return (
    <div
      id={row.original?.id}
      title={t('sources:калибрация источника')}
    >
      <CrosshairIcon className={st.Icon} onClick={() => goToCalibrationPage(row.original)} />
    </div>
  );
};

function SourceListPageComponent({
  breadcrumbs,
  lunaSourcesOptions,
  fetchPlan,
  fetchLunaSourcesOptions,
  deleteSource,
  submitSource,
}) {
  const { t } = useTranslation();
  const { planId } = useParams();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSource, setSelectedSource] = useState(undefined);

  const closeModal = () => {
    setSelectedSource(undefined);
    setModalIsOpen(false);
  };

  const openModal = (source) => {
    setSelectedSource(source);
    setModalIsOpen(true);
  };

  useEffect(() => {
    fetchPlan(planId);
    fetchLunaSourcesOptions();
  }, [planId, fetchPlan, fetchLunaSourcesOptions]);

  const onFetch = useCallback(({ page = 0, pageSize }) => apiContainer.vaReporterClient.sources
    .getAll({ plan_id: planId, page: page + 1, page_size: pageSize })
    .then((data) => ({ data })),
  [planId]);

  const tableProps = useTableHook(onFetch);

  const $deleteSource = ({ id: sourceId }) => {
    const message = t('sources:подтверждение.удаление.message');

    const onConfirm = async () => {
      await deleteSource(sourceId);
      tableProps.onFetch();
    };

    openConfirmPopup({
      title: t('sources:подтверждение.удаление.title'),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  const onCreateSource = async (values) => {
    await submitSource(planId, {
      ...selectedSource,
      ...values,
    });
    tableProps.onFetch();
    closeModal();
  };

  return (
    <Page
      breadcrumbs={breadcrumbs}
    >
      <Modal
        appElement={document.getElementById('root')}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      >
        <CreateSourcePage
          defaultValues={selectedSource}
          lunaSourcesOptions={lunaSourcesOptions}
          onSubmit={onCreateSource}
        />
      </Modal>

      <Panel>
        <Table
          {...tableProps}
          columns={[
            {
              Header: t('sources:source id'),
              accessor: 'id',
              width: 200,
            },
            {
              Header: t('sources:source name'),
              accessor: 'source_name.label',
              Cell: ({ value }) => value ?? '',
              width: 200,
            },
            {
              Header: t('sources:preview'),
              accessor: 'source_image_url',
              Cell: ({ value }) => ImageCell(value),
              width: 200,
            },
            actionColumnProps({
              id: 'validate',
              Cell: CalibrationCell,
            }),
            actionColumnProps({
              id: 'edit',
              Cell: TableCells.EditCell,
            }),
            actionColumnProps({
              id: 'delete',
              Cell: TableCells.DeleteCell,
            }),
          ]}
          onActions={{
            onAddRow: { handler: openModal },
            onEditRow: { handler: openModal },
            onDeleteRow: { handler: $deleteSource },
          }}
          paginationType="offsetBased"
        />
      </Panel>
    </Page>
  );
}

export const SourceListPage = connect((state, ownProps) => ({
  lunaSourcesOptions: selectLunaSourcesOptions(state),
  breadcrumbs: createBreadcrumbs({
    route: ownProps.match,
    plan: selectPlan(state),
  }),
}), (dispatch) => ({
  fetchPlan: (planId) => dispatch(plansStore.fetchPlan(planId)),
  fetchLunaSourcesOptions: () => dispatch(sourceStore.fetchLunaSourcesOptions),
  deleteSource: (sourceId) => sourceStore.deleteSource(sourceId),
  submitSource: (planId, params) => dispatch(sourceStore.submitSource(planId, params)),
}))(SourceListPageComponent);
