import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import './Avatar.sass';
import { IconRound } from './IconRound';
import { IconSquare } from './IconSquare';

const VARIANTS = {
  round: <IconRound />,
  square: <IconSquare />,
};

const Avatar = ({
  className,
  photo,
  name,
  variant,
}) => {
  return (
    <div className={cn(className, 'Avatar')}>
      {photo && (
        <img
          alt={name}
          className={cn('Avatar__Photo', `Avatar__Photo-${variant}`)}
          data-testid="avatarImage"
          src={photo}
        />
      )}

      {!photo && (
        <div
          className={cn('Avatar__Photo', `Avatar__Photo-${variant}`)}
          data-testid="avatarImage"
        >
          {VARIANTS[variant]}
        </div>
      )}

    </div>
  );
};

Avatar.propTypes = {
  photo: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['round', 'square']),
};

Avatar.defaultProps = {
  photo: undefined,
  name: undefined,
  className: undefined,
  variant: 'round',
};

export { Avatar };
