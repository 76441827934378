import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const StorageIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="StorageIcon" ref={iconRef} viewBox="0 0 50 50" {...props}>
      <path stroke="currentColor" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth={strokeWidth} d="M44 13.767H6m38 10.745H6m38 10.744H6m4-14.651h6m19-1.954v2.93m4-2.93v2.93M10 31.35h6m19-1.954v2.93m4-2.93v2.93m-29 9.768h6m19-1.953v2.93m4-2.93v2.93M8 46h34c1.1 0 2-.88 2-1.953V12.693a1.9 1.9 0 0 0-.67-1.455l-7.76-6.74A2.02 2.02 0 0 0 34.24 4H15.76c-.49 0-.96.176-1.33.498l-7.76 6.74c-.43.37-.67.898-.67 1.455v31.354C6 45.12 6.9 46 8 46Z" />
    </IconInner>
  );
};

export const StorageIcon = memo(StorageIconInner);
