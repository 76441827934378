/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { FaceEditForm } from '@vlabs/pages/face-details-page/forms';
import { prepareFilters } from '@vlabs/pages/lists/helpers/flatFilters';
import { ButtonWithPermissions } from '@vlabs/shared/components/button-with-permissions/ButtonWithPermissions';
import LoadingPage from '@vlabs/shared/components/loading-page/LoadingPage';
import { Sample } from '@vlabs/shared/components/sample/Sample';
import { GoToCell } from '@vlabs/shared/components/table';
import { TableCellsWithPermissions } from '@vlabs/shared/components/table/cells/index';
import { TableBottomPanelWithPermissions } from '@vlabs/shared/components/table/TableBottomPanelWithPermissions';
import { permissions } from '@vlabs/shared/config';
import { useApiRequest, usePagination } from '@vlabs/shared/hooks/index';
import { routes } from '@vlabs/shared/navigation/routes';
import { actionColumnProps } from '@vlabs/shared/utils';
import { CreateFaceWidget } from '@vlabs/shared/widgets/faces';
import { ExporterTaskWidget } from '@vlabs/shared/widgets/tasks';
import {
  TableCells, TableFilters, Table,
  openConfirmPopup, Panel, Divider, Page,
  useModal,
} from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DetachCell } from '../components/DetachCell';

import './FaceListPage.sass';

export default function FaceListPage() {
  const { listId } = useParams();
  const { t } = useTranslation();
  const editFaceModal = useModal({ size: 'small' });
  const [currentFace, setCurrentFace] = useState(undefined);
  const [filters, setFilters] = useState([]);

  const { pageIndex, setPageIndex, pageSize, setPageSize, pageSizeOptions } = usePagination();

  const facesRequestParameters = useMemo(() => {
    return [{
      list_id: listId,
      page: pageIndex + 1,
      page_size: pageSize,
      ...prepareFilters(filters),
    },
    {
      withCount: true,
    }];
  }, [filters, listId, pageIndex, pageSize]);

  const {
    state: listState,
    isLoading: isListLoading,
  } = useApiRequest({ apiRequest: apiContainer.lunaClient.lists.get, parameters: listId });

  const {
    state: facesState,
    isLoading: isFacesLoading,
    fetchData: fetchFaces,
  } = useApiRequest({ apiRequest: apiContainer.lunaClient.faces.getAll, parameters: facesRequestParameters });

  const listOption = { value: listId, label: listState?.data?.user_data || listState?.data?.list_id };

  const onDetachHandler = (data) => {
    const isDataArray = Array.isArray(data);
    const faceIds = isDataArray ? data.map(({ face_id }) => face_id) : [data.face_id];

    const message = t('faces:подтверждение.текст', {
      context: 'открепление',
      count: faceIds.length,
      item: faceIds[0],
    });

    const onConfirm = async () => {
      if (faceIds.length > 0) {
        await apiContainer.lunaClient.lists.updateFaces(listId, {
          action: 'detach',
          face_ids: faceIds,
        });
        fetchFaces();
      }
    };

    openConfirmPopup({
      title: t('faces:подтверждение.заголовок', { context: 'открепление' }),
      message,
      type: 'warning',
      onConfirm,
      cancelLabel: t('faces:подтверждение.отмена', { context: 'открепление' }),
      confirmLabel: t('faces:подтверждение.submit', { context: 'открепление' }),
    });
  };

  const cols = useMemo(() => ([
    actionColumnProps({
      id: 'selection',
      Header: TableCells.SelectAllRowsCell,
      Cell: TableCells.SelectRowCell,
    }),
    {
      id: 'thumb',
      accessor: 'uiAvatarURL',
      Cell: ({ value }) => <Sample className="FaceListPage__Sample" image={value} />,
      width: 100,
    },
    {
      Header: <TableCells.TableHeaderCell
        label={t('faces:table.columns.user_data.header.label')}
        title={t('faces:table.columns.user_data.header.title')}
      />,
      accessor: 'user_data',
      Filter: TableFilters.buildTextFilter(),
    },
    {
      Header: <TableCells.TableHeaderCell
        label={t('faces:table.columns.external_id.header.label')}
        title={t('faces:table.columns.external_id.header.title')}
      />,
      accessor: 'external_id',
      Filter: TableFilters.buildTextFilter(),
    },
    {
      Header: <TableCells.TableHeaderCell
        label={t('faces:table.columns.create_time.header.label')}
        title={t('faces:table.columns.create_time.header.title')}
      />,
      accessor: 'create_time',
      Cell: TableCells.DateCell,
      Filter: TableFilters.buildDateFilter(),
    },
    actionColumnProps({ id: 'action-edit', Cell: TableCellsWithPermissions.EditCell }),
    actionColumnProps({ id: 'action-detach', Cell: ({ row }) => DetachCell({ row, onClick: onDetachHandler, permissions: { rules: [permissions.list.modification] } }) }),
    actionColumnProps({ id: 'action-delete', Cell: TableCellsWithPermissions.DeleteCell }),
    actionColumnProps({
      id: 'action-go-to-face',
      accessor: ({ face_id }) => generatePath(routes.facePage, { faceId: face_id }),
      Cell: ({ value }) => GoToCell({ value, permissions: { rules: [permissions.face.view] } }),
      width: 50,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ]), [t]);

  const afterCreateFace = () => fetchFaces();

  const onEditFace = async (data) => {
    const { face_id: faceId, lists: prevLists } = currentFace;
    const { lists: newLists, ...params } = data;

    await apiContainer.lunaClient.faces.patch(faceId, params);

    const listsToAttach = newLists?.filter((id) => !prevLists.includes(id)) || [];
    const listsToDetach = prevLists?.filter((id) => !newLists.includes(id)) || [];

    await Promise.all([
      ...listsToAttach.map((id) => apiContainer.lunaClient.lists.updateFaces(id, {
        action: 'attach',
        face_ids: [faceId],
      }),
      ),
      ...listsToDetach.map((id) => apiContainer.lunaClient.lists.updateFaces(id, {
        action: 'detach',
        face_ids: [faceId],
      }),
      ),
    ]);

    setTimeout(fetchFaces, 300);
    setCurrentFace(undefined);
    editFaceModal.close();
    toast.success(t('faces:обновление.успех'));
  };

  const onDeleteHandler = (data) => {
    const faceIds = Array.isArray(data) ? data.map((item) => item.face_id) : [data.face_id];

    const message = t('faces:подтверждение.текст', {
      context: 'удаление',
      count: faceIds.length,
      item: faceIds[0],
    });

    const onConfirm = async () => {
      if (faceIds.length > 0) {
        await apiContainer.lunaClient.faces.deleteMany(faceIds);
        toast.success(`${t('faces:удаление.успех', { count: faceIds.length })}.`);
        fetchFaces();
      }
    };

    openConfirmPopup({
      title: t('faces:подтверждение.заголовок', { context: 'удаление' }),
      message,
      type: 'delete',
      onConfirm,
    });
  };

  const pageTitle = t('faces:заголовок', { title: listState?.data?.user_data, facesCount: facesState?.meta?.count });

  const onOpenEditFaceForm = (face) => {
    setCurrentFace(face);
    editFaceModal.open();
  };

  const extraButtons = (context) => {
    const { selectedFlatRows } = context;
    const selectedRows = selectedFlatRows.map(({ original }) => original);

    return (
      <>
        <CreateFaceWidget afterSubmit={afterCreateFace} defaultValues={{ lists: [listOption] }} />
        {/* Для отображения в форме передаем в Select сформированный опшин */}
        <ExporterTaskWidget filters={{ list_id: listOption }} objectType="faces" />
        <ButtonWithPermissions
          data-testid="table.detachFace"
          disabled={selectedRows.length === 0}
          kind="attention"
          onClick={() => onDetachHandler(selectedRows)}
          permissions={{ rules: [permissions.list.modification] }}
        >
          {t('faces:кнопки.открепление')}
        </ButtonWithPermissions>
      </>
    );
  };

  if (isListLoading || isFacesLoading || !facesState.data) { return <LoadingPage />; }

  return (
    <Page
      className="Global__Table_fullScreen_wrapper"
      title={pageTitle}
    >
      {editFaceModal.wrap(<FaceEditForm
        data-testid="faceEditForm"
        defaultValues={currentFace}
        onSubmit={onEditFace}
      />)}

      <Divider small />
      <Panel className="FaceListPage__Table_fullScreen">
        <Table
          columns={cols}
          CustomBottomPanel={TableBottomPanelWithPermissions}
          data={facesState?.data}
          disableSortBy={false}
          extraButtons={extraButtons}
          filters={filters}
          meta={{ count: facesState?.meta?.count }}
          onActions={{
            onEditRow: { handler: onOpenEditFaceForm, permissions: { rules: [permissions.face.modification] } },
            onDeleteRow: { handler: onDeleteHandler, permissions: { rules: [permissions.face.deletion] } },
          }}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          paginationType="offsetBased"
          rowProps={({ original }) => ({ 'data-face-id': original.face_id })}
          setFilters={setFilters}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
        />
      </Panel>
    </Page>
  );
}
