import React, { useCallback } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { EditIcon } from '@vlabs/icons';
import ListCreateForm from '@vlabs/pages/lists/forms/ListCreateForm';
import { ButtonWithPermissions } from '@vlabs/shared/components/button-with-permissions/ButtonWithPermissions';
import { useModal } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { fetchLists as fetchListsAction } from '../../store';

function EditListWidget({ afterSubmit, permissions, listData, updateListsStore }) {
  const { t } = useTranslation();
  const editListModal = useModal({ size: 'small' });
  const { rules = [], method = 'oneof' } = permissions || {};

  const onSubmit = useCallback(async ({ user_data: inputData }) => {
    if (!inputData) return;
    await apiContainer.lunaClient.lists.update(listData.list_id, { user_data: inputData });
    updateListsStore();
    if (afterSubmit) afterSubmit();
    editListModal.close();
    toast.success(t('lists:подтверждение.обновление.успех', { list: inputData }));
  }, [listData.list_id, afterSubmit, updateListsStore, editListModal, t]);

  return (
    <>
      {editListModal.wrap(
        <ListCreateForm
          defaultValues={{ list_id: listData.list_id, user_data: listData.user_data }}
          onSubmit={onSubmit}
        />)}
      <ButtonWithPermissions
        data-testid="editCell"
        icon={<EditIcon />}
        kind="primary"
        onClick={editListModal.open}
        permissions={{ rules, method }}
        title={t('lists:widgets.edit list widget.button.edit button.title')}
        variant="dimmed"
      />
    </>

  );
}

EditListWidget.propTypes = {
  afterSubmit: PropTypes.func,
  permissions: PropTypes.shape({
    rules: PropTypes.arrayOf(PropTypes.string).isRequired,
    method: PropTypes.oneOf(['oneof', 'allof']),
  }),
  updateListsStore: PropTypes.func.isRequired,
  listData: PropTypes.objectOf({
    list_id: PropTypes.string,
    account_id: PropTypes.string,
    user_data: PropTypes.string,
    create_time: PropTypes.string,
    last_update_time: PropTypes.string,
  }).isRequired,
};

EditListWidget.defaultProps = {
  afterSubmit: undefined,
  permissions: {},
};

const mapDispatchToProps = (dispatch) => ({
  updateListsStore: () => fetchListsAction(dispatch),
});

export default connect(
  null, mapDispatchToProps,
)(EditListWidget);
