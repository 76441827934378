import AgeDistributionDark from './dark/ageDistribution_dark.png';
import AttendanceByGenderDark from './dark/attendanceByGender_dark.png';
import AverageAgeDynamicsDark from './dark/averageAgeDynamics_dark.png';
import EmotionDistributionDark from './dark/emotionDistribution_dark.png';
import GenderDistributionDark from './dark/genderDistribution_dark.png';
import GenderRatioDark from './dark/genderRatio_dark.png';
import AgeDistributionLight from './light/ageDistribution.png';
import AttendanceByGenderLight from './light/attendanceByGender.png';
import AverageAgeDynamicsLight from './light/averageAgeDynamics.png';
import EmotionDistributionLight from './light/emotionDistribution.png';
import GenderDistributionLight from './light/genderDistribution.png';
import GenderRatioLight from './light/genderRatio.png';

export const getDiagrams = (isLightTheme) => ({
  AgeDistribution: isLightTheme ? AgeDistributionLight : AgeDistributionDark,
  AttendanceByGender: isLightTheme ? AttendanceByGenderLight : AttendanceByGenderDark,
  AverageAgeDynamics: isLightTheme ? AverageAgeDynamicsLight : AverageAgeDynamicsDark,
  EmotionDistribution: isLightTheme ? EmotionDistributionLight : EmotionDistributionDark,
  GenderDistribution: isLightTheme ? GenderDistributionLight : GenderDistributionDark,
  GenderRatio: isLightTheme ? GenderRatioLight : GenderRatioDark,
});
