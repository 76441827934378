import React from 'react';

import { ROLE_OPTIONS } from '@vlabs/api-bindings/src/constants';
import {
  ClockIcon,
  ListIcon,
  VideoIcon,
  PersonsIcon,
  SlidersIcon,
  LogOutIcon,
  HomeIcon,
  CrosshairIcon,
  FileIcon,
  NotificationIcon,
  ServerIcon,
  ThermometerIcon,
  PersonCheckIcon,
  MapIcon,
  FileTextIcon,
  DollarSignIcon,
  MoonIcon,
  SunIcon,
  GridIcon,
  BookIcon,
  RecognitionIcon,
} from '@vlabs/icons';
import { AccountMenuLinkWithPermissions } from '@vlabs/shared/components/account-menu-item-with-permissions/AccountMenuLinkWithPermissions';
import { NavbarLinkWithPermissions } from '@vlabs/shared/components/navbar-link-with-permissions/NavbarLinkWithPermissions';
import { permissions } from '@vlabs/shared/config';
import { useTheme } from '@vlabs/shared/context/ThemeContext';
import { routes } from '@vlabs/shared/navigation/routes';
import {
  selectIsAppFeatureAvailable,
  selectAppServiceState,
} from '@vlabs/shared/selectors/appSelectors';
import {
  Layout,
  AccountMenuButton,
  AccountMenuLink,
  LanguageSelector,
  Control,
  Account,
} from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import {
  selectIsAuthenticated,
  selectAccountRole,
  selectAccountModel,
} from '@vlabs/pages/auth/selectors';
import { logout } from '@vlabs/pages/auth/store';

const Navigation = ({
  account,
  role,
  onLogout,
  isAuthenticated,
  lunaPlatformIsAvailable,
  thermoIsAvailable,
  lunaStreamsIsAvailable,
  clementineAuthIsAvailable,
  clementineDepartmentsIsAvailable,
  notifierIsAvailable,
  vlAccessIsAvailable,
  vaReporterIsAvailable,
  isFaceQualityAvailable,
}) => {
  const { t } = useTranslation();
  const { isLightTheme, toggleTheme } = useTheme();

  if (!isAuthenticated) return null;

  return (
    <Layout.Navbar>
      {lunaPlatformIsAvailable && (
        <NavbarLinkWithPermissions
          icon={<ClockIcon />}
          permissions={{ rules: [permissions.event.view] }}
          to={routes.events.latest}
        >
          {t('последние события')}
        </NavbarLinkWithPermissions>
      )}
      {lunaPlatformIsAvailable && (
        <NavbarLinkWithPermissions
          icon={<ListIcon />}
          permissions={{ rules: [permissions.event.view] }}
          to={routes.events.archive}
        >
          {t('архив событий')}
        </NavbarLinkWithPermissions>
      )}
      {lunaPlatformIsAvailable && (
        <NavbarLinkWithPermissions
          icon={<CrosshairIcon />}
          permissions={{ rules: [permissions.event.matching, permissions.face.matching] }}
          to={routes.search}
        >
          {t('поиск')}
        </NavbarLinkWithPermissions>
      )}
      {lunaPlatformIsAvailable && (
        <NavbarLinkWithPermissions
          icon={<PersonsIcon />}
          permissions={{ rules: [permissions.face.view] }}
          to={routes.faces.all}
        >
          {t('лица')}
        </NavbarLinkWithPermissions>
      )}
      {lunaPlatformIsAvailable && (
        <NavbarLinkWithPermissions
          icon={<ListIcon />}
          permissions={{ rules: [permissions.list.view] }}
          to={routes.lists.all}
        >
          {t('списки')}
        </NavbarLinkWithPermissions>
      )}
      {vaReporterIsAvailable && (
        <>
          <Layout.NavbarLink icon={<MapIcon />} to={routes.vaReporter.plans.listAll}>
            {t('планы')}
          </Layout.NavbarLink>
          <Layout.NavbarLink icon={<FileTextIcon />} to={routes.vaReporter.reports}>
            {t('отчеты')}
          </Layout.NavbarLink>
        </>
      )}

      <LanguageSelector />

      <Control.Button
        icon={isLightTheme ? <MoonIcon /> : <SunIcon />}
        kind="secondary"
        onClick={toggleTheme}
        variant="flat"
      />

      {/* eslint-disable-next-line jsx-a11y/aria-role */}
      <Account name={account.name} role={ROLE_OPTIONS[role]?.label}>
        {lunaPlatformIsAvailable && (
          <AccountMenuLinkWithPermissions
            icon={<SlidersIcon />}
            permissions={{ rules: [permissions.handler.view] }}
            to={routes.handlers.all}
          >
            {t('сценарии')}
          </AccountMenuLinkWithPermissions>
        )}

        {lunaPlatformIsAvailable && (
          <AccountMenuLinkWithPermissions
            icon={<PersonCheckIcon />}
            permissions={{ rules: [permissions.verifier.view] }}
            to={routes.verifiers.all}
          >
            {t('верификация')}
          </AccountMenuLinkWithPermissions>
        )}

        {(lunaStreamsIsAvailable || vlAccessIsAvailable) && (
          <AccountMenuLink
            icon={<VideoIcon />}
            to={routes.sources.main}
          >
            {t('источники событий')}
          </AccountMenuLink>
        )}

        {lunaPlatformIsAvailable && (
          <AccountMenuLinkWithPermissions
            icon={<FileIcon />}
            permissions={{ rules: [permissions.task.view] }}
            to={routes.tasks.all}
          >
            {t('задачи')}
          </AccountMenuLinkWithPermissions>
        )}

        {lunaPlatformIsAvailable && (
          <AccountMenuLink
            icon={<FileTextIcon />}
            to={routes.events.statistic.all}
          >
            {t('Статистика')}
          </AccountMenuLink>
        )}

        {lunaPlatformIsAvailable && isFaceQualityAvailable && (
          <AccountMenuLinkWithPermissions
            icon={<RecognitionIcon />}
            permissions={{
              rules: [permissions.resources.iso, permissions.resources.sdk, permissions.emit_events.allowed],
            }}
            to={routes.checks.read}
          >
            {t('checks:page.title.checks')}
          </AccountMenuLinkWithPermissions>
        )}

        {clementineAuthIsAvailable && (
          <AccountMenuLinkWithPermissions
            icon={<BookIcon />}
            permissions={{ rules: [permissions.event.view, permissions.face.view] }}
            to={routes.searchByDocuments}
          >
            {t('поиск по документам')}
          </AccountMenuLinkWithPermissions>
        )}

        {clementineAuthIsAvailable && (
          <AccountMenuLinkWithPermissions
            icon={<PersonsIcon />}
            permissions={{ rules: [permissions.account.view] }}
            to={routes.accounts.all}
          >
            {t('пользователи')}
          </AccountMenuLinkWithPermissions>
        )}

        {clementineDepartmentsIsAvailable && (
          <AccountMenuLinkWithPermissions
            icon={<HomeIcon />}
            permissions={{ rules: [permissions.department.view] }}
            to={routes.departments.all}
          >
            {t('отделения')}
          </AccountMenuLinkWithPermissions>
        )}

        {notifierIsAvailable && (
          <AccountMenuLinkWithPermissions
            icon={<NotificationIcon />}
            permissions={{ rules: [permissions.notification.view] }}
            to={routes.notifications.all}
          >
            {t('уведомления')}
          </AccountMenuLinkWithPermissions>
        )}

        {thermoIsAvailable && (
          <AccountMenuLinkWithPermissions
            icon={<ThermometerIcon />}
            permissions={{ rules: [permissions.thermo.read] }}
            to={routes.thermo.read}
          >
            {t('Термо')}
          </AccountMenuLinkWithPermissions>
        )}

        <AccountMenuLinkWithPermissions
          icon={<ServerIcon />}
          permissions={{ rules: [permissions.appInfo.view] }}
          to={routes.app.info}
        >
          {t('мониторинг')}
        </AccountMenuLinkWithPermissions>

        <AccountMenuLinkWithPermissions
          icon={<DollarSignIcon />}
          permissions={{ rules: [permissions.appInfo.view] }}
          to={routes.app.features}
        >
          {t('features')}
        </AccountMenuLinkWithPermissions>

        <AccountMenuLinkWithPermissions
          icon={<GridIcon />}
          permissions={{ rules: [permissions.appInfo.view] }}
          to={routes.app.plugins}
        >
          {t('plugins:title')}
        </AccountMenuLinkWithPermissions>

        <AccountMenuButton
          icon={<LogOutIcon />}
          onClick={onLogout}
        >
          {t('выйти')}
        </AccountMenuButton>

      </Account>
    </Layout.Navbar>
  );
};

Navigation.propTypes = {
  account: PropTypes.shape({
    name: PropTypes.string,
  }),
  role: PropTypes.number,
  onLogout: PropTypes.func.isRequired,
  lunaPlatformIsAvailable: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  thermoIsAvailable: PropTypes.bool,
  lunaStreamsIsAvailable: PropTypes.bool,
  clementineAuthIsAvailable: PropTypes.bool,
  clementineDepartmentsIsAvailable: PropTypes.bool,
  notifierIsAvailable: PropTypes.bool,
  vlAccessIsAvailable: PropTypes.bool,
  vaReporterIsAvailable: PropTypes.bool,
  isFaceQualityAvailable: PropTypes.bool,
};
Navigation.defaultProps = {
  account: undefined,
  role: 0,
  isAuthenticated: false,
  lunaPlatformIsAvailable: false,
  thermoIsAvailable: false,
  lunaStreamsIsAvailable: false,
  clementineAuthIsAvailable: false,
  clementineDepartmentsIsAvailable: false,
  notifierIsAvailable: false,
  vlAccessIsAvailable: false,
  vaReporterIsAvailable: false,
  isFaceQualityAvailable: false,
};

export default connect((state) => ({
  isAuthenticated: selectIsAuthenticated(state),
  account: selectAccountModel(state),
  role: selectAccountRole(state) ?? ROLE_OPTIONS[0],
  lunaPlatformIsAvailable: selectAppServiceState(state, 'lunaPlatform') === 'loaded',
  thermoIsAvailable: selectAppServiceState(state, 'thermo') === 'loaded',
  lunaStreamsIsAvailable: selectAppServiceState(state, 'lunaStreams') === 'loaded',
  vlAccessIsAvailable: selectAppServiceState(state, 'vlAccess') === 'loaded',
  clementineAuthIsAvailable: selectAppServiceState(state, 'clementineAuth') === 'loaded',
  vaReporterIsAvailable: selectAppServiceState(state, 'vaReporter') === 'loaded',
  clementineDepartmentsIsAvailable: selectAppServiceState(state, 'clementineDepartments') === 'loaded',
  notifierIsAvailable: selectAppServiceState(state, 'vlNotifier') === 'loaded',
  isFaceQualityAvailable: selectIsAppFeatureAvailable(state, 'estimations.face_quality'),
}), (dispatch) => ({
  onLogout: () => dispatch(logout),
}))(Navigation);
