import { createRef, useEffect, useState } from 'react';

function useAfterPaintEffect(effect, dependencies) {
  useEffect(() => {
    requestAnimationFrame(() => {
      setTimeout(() => {
        effect();
      }, 0);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}

function getComputedSize(node) {
  if (!node) return undefined;

  // Пытаемся получить реальные размеры
  const { clientHeight, clientWidth } = node;
  const { width: cssWidth, height: cssHeight } = getComputedStyle(node);
  let w = clientWidth;
  let h = clientHeight;
  // Реальные размеры получить не удалось (узел скрыт/не отображается)
  if (h === undefined || h === 0) {
    // Пытаемся получить данные из CSS
    if (cssHeight?.endsWith('px')) {
      h = parseInt(cssHeight, 10);
    }
  }
  if (w === undefined || w === 0) {
    if (cssWidth?.endsWith('px')) {
      w = parseInt(cssWidth, 10);
    }
  }

  // Возвращаем минимальный размер
  return Math.min(w, h);
}
export const useDynamicStroke = () => {
  const iconRef = createRef();
  const [strokeWidth, setStrokeWidth] = useState(2);

  useAfterPaintEffect(() => {
    if (!iconRef.current) return;

    const x = getComputedSize(iconRef.current);

    if (x < 16) {
      setStrokeWidth(1);
    } else if (x < 20) {
      setStrokeWidth(1.33);
    } else if (x < 24) {
      setStrokeWidth(1.67);
    } else {
      setStrokeWidth(2);
    }
  }, [iconRef]);

  return {
    iconRef,
    strokeWidth,
  };
};
