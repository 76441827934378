import React, { useRef, useEffect } from 'react';

import UTIF from 'utif';

import { handleBase64ToBlob } from '../utils';
import st from './shared.module.sass';

const renderTiff = (data, canvasRef) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    const buffer = new Uint8Array(e.target.result);
    const ifds = UTIF.decode(buffer);
    UTIF.decodeImage(buffer, ifds[0]);
    const width = ifds[0].t256[0];
    const height = ifds[0].t257[0];
    const rgba = UTIF.toRGBA8(ifds[0]);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const imageData = new ImageData(new Uint8ClampedArray(rgba), width, height);
    canvas.width = width;
    canvas.height = height;
    ctx.putImageData(imageData, 0, 0);
  };

  reader.readAsArrayBuffer(data);
};

export const TiffFileRenderer = ({ photo, setRenderedImage }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!photo) return;

    const byteArray = handleBase64ToBlob(photo, 'image/tiff');
    if (byteArray) {
      renderTiff(byteArray, canvasRef);
    }
  }, [photo]);

  useEffect(() => {
    const updateRenderedImage = () => {
      if (!canvasRef.current) return;
      if (!setRenderedImage) return;

      const imgElement = new Image();
      imgElement.src = canvasRef.current.toDataURL();
      imgElement.alt = 'TIFF Rendered';
      imgElement.className = st.Image;
      imgElement.width = canvasRef.current.offsetWidth;
      imgElement.height = canvasRef.current.offsetHeight;
      setRenderedImage(imgElement);
    };

    // отслеживание изменений размеров
    const observer = new ResizeObserver(updateRenderedImage);
    observer.observe(canvasRef.current);

    return () => observer.disconnect();
  }, [canvasRef, setRenderedImage]);

  return <canvas className={st.Image} ref={canvasRef} />;
};
