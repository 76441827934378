import React, { useRef, useEffect } from 'react';

import { handleBase64ToArrayBuffer } from '../utils';
import st from './shared.module.sass';

const renderPpm = (data, canvasRef) => {
  const textDecoder = new TextDecoder();
  const ppmText = textDecoder.decode(data);
  const ppmHeaderRegex = /P6\s+(?:#.*\s+)*(\d+)\s+(\d+)\s+(\d+)\s/;
  const headerParts = ppmHeaderRegex.exec(ppmText);
  if (!headerParts) {
    console.error('Ошибка парсинга заголовка PPM.');
    return;
  }

  const imageWidth = parseInt(headerParts[1], 10);
  const imageHeight = parseInt(headerParts[2], 10);

  const headerSize = headerParts[0].length;
  const pixelData = data.subarray(headerSize);

  const canvas = canvasRef.current;
  const ctx = canvas.getContext('2d');
  const imageData = ctx.createImageData(imageWidth, imageHeight);

  // Заполнение данных пикселей
  for (let i = 0, j = 0; i < pixelData.length; i += 3, j += 4) {
    imageData.data[j] = pixelData[i]; // Красный
    imageData.data[j + 1] = pixelData[i + 1]; // Зеленый
    imageData.data[j + 2] = pixelData[i + 2]; // Синий
    imageData.data[j + 3] = 255; // Прозрачность
  }

  canvas.width = imageWidth;
  canvas.height = imageHeight;
  ctx.putImageData(imageData, 0, 0);
};

export const PpmFileRenderer = ({ photo, setRenderedImage }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!photo) return;

    const arrayBuffer = handleBase64ToArrayBuffer(photo, 'image/x-portable-pixmap');
    if (arrayBuffer) {
      renderPpm(arrayBuffer, canvasRef);
    }
  }, [photo]);

  useEffect(() => {
    const updateRenderedImage = () => {
      if (!canvasRef.current) return;
      if (!setRenderedImage) return;

      const imgElement = new Image();
      imgElement.src = canvasRef.current.toDataURL();
      imgElement.alt = 'PPM Rendered';
      imgElement.className = st.Image;
      imgElement.width = canvasRef.current.offsetWidth;
      imgElement.height = canvasRef.current.offsetHeight;
      setRenderedImage(imgElement);
    };

    // отслеживание изменений размеров
    const observer = new ResizeObserver(updateRenderedImage);
    observer.observe(canvasRef.current);

    return () => observer.disconnect();
  }, [canvasRef, setRenderedImage]);

  return <canvas className={st.Image} ref={canvasRef} />;
};
