import { BackButton } from './back-button/BackButton';
import { AlarmButton } from './button/alarm-button/AlarmButton';
import { BaseButton } from './button/base-button/BaseButton';
import { Button } from './button/Button';
import { LinkButton } from './button/LinkButton';
import { RoundButton } from './button/RoundButton';
import { Checkbox } from './checkbox/Checkbox';
import { CoordinatesInput } from './coordinates-input/CoordinatesInput';
import { DateInput } from './date-input/DateInput';
import { ImageFileRenderer } from './file-dropzone/file-renderer/ImageFileRenderer';
import { PpmFileRenderer } from './file-dropzone/file-renderer/PpmFileRendered';
import { TextFileRenderer } from './file-dropzone/file-renderer/TextFileRenderer';
import { TiffFileRenderer } from './file-dropzone/file-renderer/TiffFileRenderer';
import { FileDropzone } from './file-dropzone/FileDropzone';
import { FileInput } from './file-input/FileInput';
import { Input } from './input/Input';
import { Link } from './link/Link';
import { Radio } from './radio/Radio';
import { Select } from './select/Select';
import { Switch } from './switch/Switch';
import { Textarea } from './textarea/Textarea';

export const Control = {
  BaseButton,
  AlarmButton,
  Button,
  RoundButton,
  LinkButton,
  Checkbox,
  DateInput,
  FileDropzone,
  ImageFileRenderer,
  TextFileRenderer,
  TiffFileRenderer,
  PpmFileRenderer,
  FileInput,
  CoordinatesInput,
  Input,
  Radio,
  BackButton,
  Select,
  Switch,
  Textarea,
  Link,
};
