import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const ListIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="ListIcon" ref={iconRef} viewBox="0 0 24 24" {...props}>
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth} d="M8 6h13M8 12h13M8 18h13M3 6h.01M3 12h.01M3 18h.01" />
    </IconInner>
  );
};

export const ListIcon = memo(ListIconInner);
