import React, { useEffect, useCallback, useState } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { LIVENESS_OPTIONS } from '@vlabs/api-bindings/src/luna-client/constants';
import { ArrowInCircleRightIcon, ZoomInIcon, PersonIcon, FolderIcon, ListIcon, VideoIcon } from '@vlabs/icons';
import { LivenessImage } from '@vlabs/shared/assets/images';
import { EventActions } from '@vlabs/shared/components/action-button-groups/EventActions';
import { SampleWithActions } from '@vlabs/shared/components/sample/SampleWithActions';
import { SimilarityTag } from '@vlabs/shared/components/similarity-tag/SimilarityTag';
import StreamLink from '@vlabs/shared/components/stream-link/StreamLink';
import { TabsWithPermissions } from '@vlabs/shared/components/tabs-with-permissions/TabsWithPermissions';
import { permissions } from '@vlabs/shared/config';
import InfoRow from '@vlabs/shared/legacy-components/info-row/InfoRow';
import ListLink from '@vlabs/shared/legacy-components/list-link/ListLink';
import { AdditionalPropertiesTab } from '@vlabs/shared/legacy-components/tabs/AdditionalPropertiesTab';
import { AttributesTab } from '@vlabs/shared/legacy-components/tabs/AttributesTab';
import { BodyAttributesTab } from '@vlabs/shared/legacy-components/tabs/BodyAttributesTab';
import { routes } from '@vlabs/shared/navigation/routes';
import { Tab, Panel, Divider, ButtonScrimGroup, ButtonScrimItem, Control } from '@vlabs/uikit';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath, Link as RouterLink } from 'react-router-dom';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import { SearchLink } from './SearchLink';
import st from './TopMatchCard.module.sass';

const FaceTopMatch = ({ event }) => {
  const { t } = useTranslation();
  if (!event?.isExist) return <span>{t('faces:лицо удалено')}</span>;

  return (
    <>
      <SampleWithActions
        actions={(
          <ButtonScrimGroup childrenAs={Control.LinkButton}>
            <ButtonScrimItem
              disabled={!event?.uiAvatarURL}
              href={event?.uiAvatarURL}
              icon={<ZoomInIcon />}
              round
              title={t('посмотреть в полном размере')}
              variant="dimmed"
            />
            <ButtonScrimItem
              as={RouterLink}
              icon={<ArrowInCircleRightIcon />}
              round
              to={generatePath(routes.facePage, { faceId: event?.face_id })}
              variant="dimmed"
            />
          </ButtonScrimGroup>
        )}
        className={st.FaceSample__Size_large}
        data-testid="sampleImage"
        imageUrl={event?.uiAvatarURL}
        type="face"
      />
      <div>
        <TabsWithPermissions testId="topMatchInfoTabs">
          <Tab
            header={t('атрибуты')}
            headerClassName={st.TabHeader}
            name="topMatchAttributes"
            permissions={{ rules: [permissions.attribute.view] }}
          >
            <AttributesTab faceId={event?.face_id} />
          </Tab>
          <Tab
            header={t('дополнительные свойства')}
            headerClassName={st.TabHeader}
            name="topMatchAdditionalProperties"
            permissions={{ rules: [permissions.resources.sdk] }}
          >
            <AdditionalPropertiesTab sampleId={event?.face_descriptor_samples?.[0]} />
          </Tab>
        </TabsWithPermissions>
      </div>
    </>
  );
};

const EventTopMatch = ({ event }) => {
  const { t } = useTranslation();
  if (!event?.isExist) return <span>{t('events:event deleted')}</span>;

  return (
    <>
      <div>
        {event.face_detections && (
          <SampleWithActions
            actions={(
              <EventActions
                extraActions={(
                  <ButtonScrimItem
                    as={RouterLink}
                    icon={<ArrowInCircleRightIcon />}
                    round
                    to={generatePath(routes.events.eventDetails, { eventId: event.event_id })}
                    variant="dimmed"
                  />
                )}
                fullFrameURL={event?.detectionWithUiFullFrame?.uiFullFrame}
                rect={event?.detectionWithUiFullFrame?.detection?.rect}
                sampleURL={event?.uiFaceDetection?.uiSampleURL}
                track={event?.uiFaceTrack?.map((i) => event?.face_detections[i])}
              />
            )}
            className={st.FaceSample__Size_large}
            data-testid="sampleImage"
            imageUrl={event?.uiFaceDetection?.uiSampleURL}
            type="face"
          />
        )}
        {event.body_detections && (
          <SampleWithActions
            actions={(
              <EventActions
                fullFrameURL={event?.detectionWithUiFullFrame?.uiFullFrame}
                rect={event?.detectionWithUiFullFrame?.detection?.rect}
                sampleURL={event.uiBodyDetection?.uiSampleURL}
                // FIXME: это заглушка, в массиве uiBodyTrack всегда один элемент
                // мы никогда не показываем треки для тел, потому что просмотр трека подразумевает больше чем один элемент
                track={event?.uiBodyTrack}
              />
            )}
            className={st.BodySample__Size_large}
            data-testid="sampleImage"
            imageUrl={event?.uiBodyDetection?.uiSampleURL}
            type="body"
          />
        )}
      </div>
      <div>
        <TabsWithPermissions testId="topMatchInfoTabs">
          <Tab
            header={t('атрибуты')}
            headerClassName={st.TabHeader}
            name="topMatchAttributes"
          >
            {event.face_detections && <AttributesTab eventId={event?.event_id} />}
            {event.body_detections && <BodyAttributesTab event={event} />}
          </Tab>

          {event?.uiFaceDetection?.sample_id && (
            <Tab
              header={t('дополнительные свойства')}
              headerClassName={st.TabHeader}
              name="topMatchAdditionalProperties"
              permissions={{ rules: [permissions.resources.sdk] }}
            >
              <AdditionalPropertiesTab sampleId={event?.uiFaceDetection?.sample_id} />
            </Tab>
          )}
        </TabsWithPermissions>
      </div>
    </>
  );
};

export const TopMatchCardComponent = ({ topMatch, can }) => {
  const { t } = useTranslation();
  const [event, setEvent] = useState({
    ...topMatch,
    isExist: true,
    eventType: topMatch.event_id ? 'event' : 'face',
  });

  const fetchEvent = useCallback(async () => {
    try {
      if (topMatch?.event_id) {
        const { data } = await apiContainer.lunaClient.events.get(topMatch.event_id);
        setEvent({ ...data, ...event });
      }
      if (topMatch?.face_id) {
        const [{ data }, { data: { attributes: { basic_attributes, ...attributes } } }] = await Promise.all([
          apiContainer.lunaClient.faces.get(topMatch.face_id),
          apiContainer.lunaClient.faces.attributes(topMatch.face_id).get(),
        ]);
        setEvent({ ...data, ...basic_attributes, ...attributes, ...event });
      }
    } catch ({ error }) {
      // Если событие удалено - 'Object not found'. error_code === 23001 -- удаленное событие. error_code === 22002 -- удаленное лицо.
      if (error.response.data.error_code === 23001 || error.response.data.error_code === 22002) {
        setEvent({ ...event, isExist: false });
        console.error('Event not found:', error);
      } else {
        throw error;
      }
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchEvent(); }, []);

  if (!can([permissions.face.view])) {
    return (
      <div className={st.BlockWithNoPermission}>
        <div className={st.TitleBlock}>
          <h6>{t(`events:top match card.title.${event.eventType}`)}</h6>
        </div>
        <span>{i18n.t('events:недостаточно прав для просмотра карточки лица')}</span>
      </div>
    );
  }

  if (!event) return null;

  return (
    <Panel className={st.Root}>
      <div className={st.TitleBlock}>
        <div className={st.TitleBlock}>
          <h6>{t(`events:top match card.title.${event.eventType}`)}</h6>
          <SimilarityTag
            className={st.Tag}
            value={event?.similarity}
          />
        </div>
        {event.isExist && <SearchLink event={event} />}
      </div>

      <div>
        {topMatch?.event_id && (
          <>
            <InfoRow
              className={st.InlineRow}
              icon={(
                <img
                  alt="liveness icon"
                  className="StaticIcon"
                  src={LivenessImage}
                />
              )}
              title={`${t('Liveness')}:`}
              value={LIVENESS_OPTIONS[event.liveness]?.label}
            />
            <InfoRow
              className={st.InlineRow}
              title={`${t('source')}:`}
              value={event.source}
            />
            <InfoRow
              className={st.InlineRow}
              icon={<VideoIcon className="StaticIcon" />}
              title={`${t('video stream')}:`}
              value={<StreamLink streamId={event.stream_id} />}
            />
          </>
        )}
        {topMatch?.face_id && (
          <>
            <InfoRow
              className={st.InlineRow}
              icon={<FolderIcon className="StaticIcon" />}
              title={`${t('information')}:`}
              value={event.user_data}
            />
            <InfoRow
              className={st.InlineRow}
              icon={<PersonIcon className="StaticIcon" />}
              title={`${t('external id')}:`}
              value={event.external_id}
            />
            {!!event?.lists?.length && (
              <InfoRow
                className={st.ListInfoRow}
                icon={<ListIcon className="StaticIcon" />}
                title={`${t('списки')} (${event.lists.length}):`}
                value={<ListLink lists={event.lists} />}
              />
            )}
          </>
        )}
      </div>

      <Divider small />

      <div className={st.Content}>
        {topMatch?.face_id && <FaceTopMatch event={event} />}
        {topMatch?.event_id && <EventTopMatch event={event} />}
      </div>
    </Panel>
  );
};

export const TopMatchCard = connect((state) => ({
  can: viewerCan(state),
}))(TopMatchCardComponent);
