import { apiContainer } from '@vlabs/api-bindings';
import { STREAM_STATUS_OPTIONS } from '@vlabs/api-bindings/src/luna-streams/constants';
// eslint-disable-next-line import/no-unresolved
import i18n from 'app/translations/i18n';
import { toast } from 'react-toastify';

import { SLICE_NAME } from './constants';
import { selectGroupOptions } from './selectors';
import
{
  setFilters as setFiltersAction,
  setPageIndex as setPageIndexAction,
  setPageSize as setPageSizeAction,
  setPageData as setPageDataAction,
  setStreams as setStreamsAction,
  setGroups,
  setGroupsPageData,
}
  from './store';

// streams

export const fetchStreams = async (dispatch, getState) => {
  const state = getState();
  const params = {};
  if (state.account) {
    params.account_id = state.account.currentDomainToken?.account_id;
    params.account_id = params.account_id || state.account.model?.account_id;
  } try {
    const streams = await apiContainer.lunaStreamsClient.streams.getAll(params);
    dispatch(setStreamsAction(streams));
  } catch (error) {
    dispatch(setStreamsAction([]));
    throw error;
  }
};

export const fetchPageData = async (dispatch, getState) => {
  const state = getState();
  const { [SLICE_NAME]: { pageIndex, pageSize, filters } } = state;

  const localFilters = [];
  filters.forEach(({ id, value }) => {
    if (typeof value === 'object') {
      localFilters[id] = value.value;
    } else {
      localFilters[id] = value;
    }
  });

  const params = {
    page: pageIndex + 1,
    page_size: pageSize,
    order: 'desc',
    ...localFilters,
  };
  if (state.account) {
    params.account_id = state.account.currentDomainToken?.account_id;
    params.account_id = params.account_id || state.account.model?.account_id;
  }
  try {
    const pageData = await apiContainer.lunaStreamsClient.streams.getPage(params);
    dispatch(setPageDataAction(pageData));
  } catch (error) {
    dispatch(setPageDataAction([]));
    // error_code === 12012 это ошибка падает если ввели некорректные данные для квери запроса, например, невалидный stream_ids
    // FIXME: Изменить обработку фильтрации после апдйта кита и удалить эту проверку. Ожидается, что добавив валидацию мы не будем попадать в этот блок
    if (error.error_code === 12012) {
      return;
    }
    throw error;
  }
};

export const startStream = (streamId) => async (dispatch) => {
  await apiContainer.lunaStreamsClient.streams.update(streamId, {
    status: STREAM_STATUS_OPTIONS.pending.value,
  });
  dispatch(fetchPageData);
};
export const pauseStream = (streamId) => async (dispatch) => {
  await apiContainer.lunaStreamsClient.streams.update(streamId, {
    status: STREAM_STATUS_OPTIONS.pause.value,
  });
  dispatch(fetchPageData);
};
export const stopStream = (streamId) => async (dispatch) => {
  await apiContainer.lunaStreamsClient.streams.update(streamId, {
    status: STREAM_STATUS_OPTIONS.cancel.value,
  });
  dispatch(fetchPageData);
};

export const setFilters = (params) => async (dispatch) => {
  dispatch(setFiltersAction(params));
  dispatch(fetchPageData);
};

export const setPageSize = (params) => async (dispatch) => {
  dispatch(setPageSizeAction(params));
  dispatch(fetchPageData);
};
export const setPageIndex = (params) => async (dispatch) => {
  dispatch(setPageIndexAction(params));
  dispatch(fetchPageData);
};

const processGroup = async ({ groups, account_id, group_name }) => {
  if (group_name === undefined) return;

  const isGroupExists = groups
    .find(({ value: existedGroupName }) => existedGroupName === group_name);
  if (!isGroupExists) {
    await apiContainer.lunaStreamsClient.groups.create({ account_id, group_name });
  }
};

export const createStream = (params) => async (_, getState) => {
  processGroup({
    groups: selectGroupOptions(getState()),
    account_id: params.account_id,
    group_name: params?.group_name?.value,
  });

  const { stream_id } = await apiContainer.lunaStreamsClient.streams.create(params);
  toast.success(i18n.t('lunaStreamsStreams:создание.успех', { stream: { ...params, stream_id } }));
  return stream_id;
};

export const updateStream = (stream_id, params) => async (_, getState) => {
  processGroup({
    groups: selectGroupOptions(getState()),
    account_id: params.account_id,
    group_name: params.group_name?.value,
  });

  await apiContainer.lunaStreamsClient.streams.replace(stream_id, params);
  toast.success(i18n.t('lunaStreamsStreams:обновление.успех', { stream: { ...params, stream_id } }));
};

export const deleteStream = (stream_id) => async (dispatch) => {
  await apiContainer.lunaStreamsClient.streams.delete(stream_id);
  dispatch(fetchPageData);
  toast.success(i18n.t('lunaStreamsStreams:удаление.успех', { stream: { stream_id } }));
};

export const init = (dispatch) => {
  dispatch(fetchStreams);
};

// groups

export const fetchGroups = async (dispatch) => {
  try {
    const groups = await apiContainer.lunaStreamsClient.groups.getAll();

    dispatch(setGroups(groups));
  } catch (error) {
    if (error.response.status === 404) {
      console.error('Группы отсутсвуют в плагинах');
      return;
    }

    throw error;
  }
};

export const fetchGroupsPageData = async (dispatch, getState) => {
  const { [SLICE_NAME]: { pageSize, pageIndex, filters } } = getState();
  dispatch(fetchStreams);
  try {
    const pageData = await apiContainer.lunaStreamsClient.groups.getPage({
      page: pageIndex + 1,
      page_size: pageSize,
      ...filters,
    }, { withCount: true });
    let streams = await apiContainer.lunaStreamsClient.streams.getAll();
    streams = streams.reduce((acc, stream) => {
      stream.groups.forEach(({ value: group_name }) => {
        if (acc[group_name] === undefined) acc[group_name] = [];
        acc[group_name].push(stream);
      });
      return acc;
    }, {});
    // eslint-disable-next-line no-param-reassign
    pageData.data.forEach((group) => { group.streams = streams[group.group_name] || []; });
    dispatch(setGroupsPageData(pageData));
  } catch (error) {
    dispatch(setGroupsPageData([]));
    throw error;
  }
};
export const setGroupsPageIndex = (pageIndex) => (dispatch) => {
  dispatch(setPageIndexAction(pageIndex));
  dispatch(fetchGroupsPageData);
};
export const setGroupsPageSize = (pageSize) => (dispatch) => {
  dispatch(setPageSizeAction(pageSize));
  dispatch(fetchGroupsPageData);
};

export const createGroup = (params) => async (dispatch) => {
  try {
    const { group_name, group_id } = await apiContainer.lunaStreamsClient.groups.create(params);
    dispatch(fetchGroupsPageData);
    toast.success(i18n.t('lunaStreamsGroups:api.создание.успех', { group: { ...params, group_name, group_id } }));
    return group_id;
  } catch (error) {
    if (error.response.status === 409) {
      const toastMessage = i18n.t('lunaStreamsGroups:api.создание.валидация.неуникальный account_id', { group: { ...params } });
      toast.warning(toastMessage);
      return undefined;
    }
    throw error;
  }
};

export const updateGroup = (group_id, params) => async (dispatch) => {
  await apiContainer.lunaStreamsClient.groups.update(group_id, params);
  dispatch(fetchGroupsPageData);
  toast.success(i18n.t('lunaStreamsGroups:обновление.успех', { group: { ...params, group_id } }));
};

export const deleteGroup = (group_id) => async (dispatch, getState) => {
  const { [SLICE_NAME]: { groupById } } = getState();
  const group_name = groupById[group_id]?.group_name;
  await apiContainer.lunaStreamsClient.groups.delete(group_id);
  dispatch(fetchGroupsPageData);
  toast.success(i18n.t('lunaStreamsGroups:удаление.успех', { group: { group_name, group_id } }));
};
