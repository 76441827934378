/* eslint-disable react/prop-types */
import React from 'react';

import { calcSimilarityPercent, calcTagType } from '@vlabs/shared/utils';
import { Tag } from '@vlabs/uikit';
import PropTypes from 'prop-types';

export const SimilarityTag = ({ value, kind, ...props }) => {
  return (
    <Tag
      kind={kind ? calcTagType(kind) : calcTagType(value)}
      value={calcSimilarityPercent(value)}
      {...props}
    />
  );
};

SimilarityTag.propTypes = {
  value: PropTypes.number.isRequired,
  kind: PropTypes.number,
};

SimilarityTag.defaultProps = {
  kind: undefined,
};
