import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const PersonCheckIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="PersonCheckIcon" ref={iconRef} viewBox="0 0 24 24" {...props}>
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth} d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2m7.5-10a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm8.5 0 2 2 4-4" />
    </IconInner>
  );
};

export const PersonCheckIcon = memo(PersonCheckIconInner);
