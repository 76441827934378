import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import { rbacRules } from '@vlabs/shared/config';

const store = createSlice({
  name: 'account',
  initialState: {
    isAuthenticated: false,
    model: null,
    data: [],
    meta: {},
  },
  reducers: {
    setAccount(state, { payload }) {
      //  Передаем пермишены так, как они будут приходить после подключения токенов
      // FIXME: после переезда удалить
      state.model = {
        ...payload,
        permissionsRaw: rbacRules[payload.role],
      };
      // переделать потом обратно на этот вариант -
      // state.model = payload;

      state.isAuthenticated = true;
    },
    logoutSuccess(state) {
      state.model = null;
      state.isAuthenticated = false;
    },
  },
});

export default store.reducer;

export const {
  setAccount,
  logoutSuccess,
} = store.actions;

export const fetchCurrentUser = async (dispatch) => {
  try {
    const { data: { data: account } } = await apiContainer.clementineAuthClient.auth.getCurrentAccount();
    dispatch(setAccount(account));
  } catch (error) {
    if (error?.response?.status === 401) return;
    throw error;
  }
};

export const logout = async (dispatch) => {
  await apiContainer.clementineAuthClient.auth.logout();
  await apiContainer.lunaClient.ws.disconnect();
  await apiContainer.lunaClient.generalWs.disconnectAll();

  dispatch({ type: 'events/setFilters', payload: {} });
  dispatch({ type: 'latestEvents/setParams', payload: {} });
  dispatch({ type: 'matcherSearch/resetSearchState', payload: {} });

  dispatch(logoutSuccess());
  window.history.replaceState({}, '', '/');
};

export const resetPasswordLogout = async (dispatch, getState) => {
  const {
    account: { isAuthenticated },
  } = getState();
  if (isAuthenticated) {
    await apiContainer.clementineAuthClient.auth.logout();
    await apiContainer.lunaClient.ws.disconnect();
    await apiContainer.lunaClient.generalWs.disconnectAll();

    dispatch({ type: 'events/setFilters', payload: {} });
    dispatch({ type: 'latestEvents/setParams', payload: {} });
    dispatch({ type: 'matcherSearch/resetSearchState', payload: {} });

    dispatch(logoutSuccess());
  }
};

export const resetPassword = async ({
  token, password,
}) => apiContainer.clementineAuthClient.auth.resetPassword(token, password);

export const login = async ({
  email,
  password,
}) => apiContainer.clementineAuthClient.auth.login({ email, password });
