import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';

import { SettingsIcon, PercentIcon } from '@vlabs/icons';
import { isEqual, isObject } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useModal } from '../../../hooks/useModal';
import { Divider } from '../../divider/Divider';
import { Page } from '../../page/Page';
import { ROI } from '../../roi/ROI';
import { Tooltip } from '../../tooltip/Tooltip';
import { denormalizeRect, normalizeRect } from '../../utils/normalize';
import { Button } from '../button/Button';
import { RoundButton } from '../button/RoundButton';
import { Input } from '../input/Input';

import './CoordinatesInput.sass';

const ROUND_DIGITS = 5;
const COORDS_MODES = ['abs', 'percent'];
const DEFAULT_VALUES = {
  [COORDS_MODES[0]]: { x: 0, y: 0, width: 0, height: 0 },
  [COORDS_MODES[1]]: { x: 0, y: 0, width: 100, height: 100 },
};
const defaultMode = COORDS_MODES[0];

const rectToString = (v) => (v === undefined ? '' : [v.x, v.y, v.width, v.height].toString());
const rectFromString = (stringValue) => {
  const v = stringValue.split(',').map((e) => e.trim()).map(parseFloat);
  if (!Array.isArray(v)) return undefined;
  if (v.length < 4) return undefined;
  return {
    x: v[0],
    y: v[1],
    width: v[2],
    height: v[3],
  };
};
const isValidRect = (v) => {
  if (!isObject(v)) return false;
  if (typeof v.x !== 'number' || Number.isNaN(v.x)) return false;
  if (typeof v.y !== 'number' || Number.isNaN(v.y)) return false;
  if (typeof v.width !== 'number' || Number.isNaN(v.width)) return false;
  if (typeof v.height !== 'number' || Number.isNaN(v.height)) return false;
  return true;
};

const toFixed = (number) => Math.trunc(number * 10 ** ROUND_DIGITS) / 10 ** ROUND_DIGITS;

const initialState = {
  sourceDimensions: undefined,
  mode: defaultMode,
  roi: DEFAULT_VALUES[defaultMode],
};
const actionTypes = {
  SET_MODE: 'set-mode',
  SET_ROI: 'set-roi',
  SET_SOURCE_DIMENSIONS: 'set-source-dimensions',
  SET_EXTERNAL_VALUE: 'set-external-value',
};

function reducer(previousState, action) {
  switch (action.type) {
    case actionTypes.SET_SOURCE_DIMENSIONS: {
      if (isEqual(previousState.sourceDimensions, action.payload)) return previousState;
      return {
        ...previousState,
        sourceDimensions: action.payload,
      };
    }
    case actionTypes.SET_MODE: {
      if (previousState.sourceDimensions === undefined || !isValidRect(previousState.roi)) {
        return {
          ...previousState,
          roi: DEFAULT_VALUES[action.payload],
          mode: action.payload,
        };
      }

      switch (action.payload) {
        case 'abs': {
          if (isEqual(DEFAULT_VALUES.percent, previousState.roi)) {
            return {
              ...previousState,
              roi: {
                x: 0,
                y: 0,
                width: previousState.sourceDimensions?.width ?? 0,
                height: previousState.sourceDimensions?.height ?? 0,
              },
              mode: action.payload,
            };
          }
          const newCoords = denormalizeRect(previousState.roi, {
            ...previousState.sourceDimensions,
            denominator: 100,
          });
          return {
            ...previousState,
            roi: {
              x: Math.min(Math.floor(newCoords.x), previousState.sourceDimensions.width),
              y: Math.min(Math.floor(newCoords.y), previousState.sourceDimensions.height),
              width: Math.min(Math.floor(newCoords.width), previousState.sourceDimensions.width),
              height: Math.min(Math.floor(newCoords.height), previousState.sourceDimensions.height),
            },
            mode: action.payload,
          };
        }
        case 'percent': {
          if (previousState.sourceDimensions !== undefined
            && isEqual(DEFAULT_VALUES.abs, previousState.roi)) {
            return {
              ...previousState,
              roi: DEFAULT_VALUES.percent,
              mode: action.payload,
            };
          }
          const newCoords = normalizeRect(previousState.roi, {
            ...previousState.sourceDimensions,
            denominator: 100,
          });
          return {
            ...previousState,
            roi: {
              x: toFixed(newCoords.x),
              y: toFixed(newCoords.y),
              width: toFixed(newCoords.width),
              height: toFixed(newCoords.height),
            },
            mode: action.payload,
          };
        }
        default: {
          throw new Error(`undefined coordinates mode: ${action.payload}`);
        }
      }
    }
    case actionTypes.SET_ROI: {
      let newROI = action.payload;
      if (typeof (action.payload) === 'string') newROI = rectFromString(newROI);
      if (isEqual(newROI, previousState.roi)) return previousState;
      return {
        ...previousState,
        roi: newROI,
      };
    }
    case actionTypes.SET_EXTERNAL_VALUE: {
      const newRoi = { ...action.payload };
      delete newRoi.mode;
      const prevValue = {
        ...previousState.roi,
        mode: previousState.mode,
      };

      if (isEqual(prevValue, action.payload)) return previousState;
      return {
        ...previousState,
        roi: newRoi,
        mode: action.payload.mode,
      };
    }
    default: {
      throw new Error(`action ${action.type} is not registered`);
    }
  }
}

const CoordinatesInput = ({
  value,
  onChange,
  previewImage,
  texts,
  errors,
  hideVisualEditor,
  sourceDimensions,
  ...props
}) => {
  const valueInputRef = useRef(null);
  const [store, dispatch] = useReducer(reducer, initialState);
  const isMounted = useRef(false);
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false);

  const { t } = useTranslation();
  const modal = useModal();

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  const setInputValue = useCallback((ref, v) => {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
    nativeInputValueSetter.call(ref.current, v);
    ref.current.dispatchEvent(new Event('input', { bubbles: false }));
  }, []);

  useEffect(() => {
    const inputValue = rectToString(store.roi);
    if (inputValue === '') {
      setInputValue(valueInputRef, rectToString(DEFAULT_VALUES[store.mode]));
    } else {
      setInputValue(valueInputRef, inputValue);
    }
  }, [store.mode, setInputValue]);

  useEffect(() => {
    if (onChange) onChange({ ...store.roi, mode: store.mode });
  }, [store, onchange]);

  useEffect(() => {
    dispatch({ type: actionTypes.SET_EXTERNAL_VALUE, payload: value });
    if (
      [value?.x, value?.y, value?.width, value?.height]
        .every((v) => !Number.isNaN(v) && v !== undefined)
    ) setInputValue(valueInputRef, rectToString(value));
  }, [value, dispatch]);

  useEffect(() => {
    if (isEqual(store.sourceDimensions, sourceDimensions)) return;
    dispatch({ type: actionTypes.SET_SOURCE_DIMENSIONS, payload: sourceDimensions });
  }, [sourceDimensions]);

  useEffect(() => {
    if (!previewImage) {
      setIsPreviewAvailable(false);
      return;
    }

    let img = new Image();
    img.onload = (e) => {
      if (isMounted.current) setIsPreviewAvailable(true);
      dispatch({ type: actionTypes.SET_SOURCE_DIMENSIONS,
        payload: {
          width: e.target.naturalWidth,
          height: e.target.naturalHeight,
        } });
    };
    img.onerror = () => {
      if (isMounted.current) setIsPreviewAvailable(false);
      dispatch({ type: actionTypes.SET_SOURCE_DIMENSIONS, payload: undefined });
    };
    img.src = previewImage;
    img = null;

    // eslint-disable-next-line consistent-return
    return () => { img = null; };
  }, [previewImage, isMounted]);

  const saveROIForm = useCallback((v) => {
    dispatch({ type: actionTypes.SET_ROI, payload: v });
    setInputValue(valueInputRef, rectToString(v));
    modal.close();
  }, [modal, valueInputRef.current, setInputValue]);

  const changeCoordsMode = useCallback(() => {
    const currentCoordsModeIdx = COORDS_MODES.findIndex((v) => v === store.mode);
    const nextCoordsModeIdx = (currentCoordsModeIdx + 1) % COORDS_MODES.length;
    const newCoordsMode = COORDS_MODES[nextCoordsModeIdx];

    dispatch({ type: actionTypes.SET_MODE, payload: newCoordsMode });
  }, [setInputValue, valueInputRef.current, store.mode, store.sourceDimensions, dispatch]);

  return (
    <div className="CoordinatesInput">
      {modal.wrap(
        <Page
          className="CoordinatesInput__RoiForm"
          title={texts.title || t('uikit:control.coordinatesInput.modal.заголовок')}
        >
          <Divider small />
          <ROI
            coordinatesRounding={store.mode === 'percent' ? ROUND_DIGITS : 0}
            isNormalized={store.mode === 'percent'}
            isRectangle
            maxModalSizeRatio={0.6}
            normalizeBy={100}
            onCancel={modal.close}
            onROISave={saveROIForm}
            preview={previewImage}
            roi={store.roi}
            sizeRounding={store.mode === 'percent' ? ROUND_DIGITS : 0}
          />
        </Page>,
      )}

      <div className="CoordinatesInput__Input">
        <Input
          errors={errors}
          onChange={(e) => dispatch({ type: actionTypes.SET_ROI, payload: e.target.value })}
          ref={valueInputRef}
          {...props}
        />
      </div>

      <Button
        className="CoordinatesInput__ModeButton"
        icon={store.mode === 'percent' ? <PercentIcon /> : undefined}
        kind="secondary"
        onClick={changeCoordsMode}
        variant="flat"
      >
        {store.mode === 'abs' && ('px')}
      </Button>

      {!hideVisualEditor && (
        <Tooltip
          className="CoordinatesInput__Tooltip"
          data-tooltip-content={
            texts.tip || isPreviewAvailable
              ? t('uikit:control.coordinatesInput.подсказка с превью')
              : t('uikit:control.coordinatesInput.подсказка без превью')
          }
        >
          <RoundButton
            disabled={!isPreviewAvailable}
            icon={<SettingsIcon />}
            kind="secondary"
            onClick={() => {
              if (!isValidRect(store.roi)) {
                dispatch({ type: actionTypes.SET_ROI, payload: DEFAULT_VALUES[store.mode] });
              }
              modal.open();
            }}
            variant="flat"
          />
        </Tooltip>
      )}
    </div>
  );
};

CoordinatesInput.propTypes = {
  value: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    mode: PropTypes.oneOf(COORDS_MODES),
  }),
  errors: PropTypes.object,
  texts: PropTypes.shape({
    title: PropTypes.string,
    tip: PropTypes.string,
  }),
  previewImage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  hideVisualEditor: PropTypes.bool,
  sourceDimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};

CoordinatesInput.defaultProps = {
  previewImage: undefined,
  errors: undefined,
  texts: {},
  hideVisualEditor: false,
  sourceDimensions: undefined,
  value: undefined,
};

export { CoordinatesInput };
