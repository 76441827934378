import React from 'react';

import { AccountListPage } from '@vlabs/pages/accounts';
import { FeaturesPage, PluginsPage, InfoPage } from '@vlabs/pages/app';
import { ResetPasswordPage } from '@vlabs/pages/auth';
import ChecksPage from '@vlabs/pages/checks/ChecksPage';
import { DepartmentListPage, DepartmentPage } from '@vlabs/pages/departments';
import { EditStreamPage as LunaStreamsEditStreamPage } from '@vlabs/pages/editStream/EditStreamPage';
import EventPage from '@vlabs/pages/events/event-page/EventPage';
import EventListPage from '@vlabs/pages/events/events/EventListPage';
import LatestEventListPage from '@vlabs/pages/events/latest-events/LatestEventListPage';
import { ExternalIdPage } from '@vlabs/pages/external-id';
import FacePage from '@vlabs/pages/face-details-page/FacePage';
import FacesPage from '@vlabs/pages/faces/FacesPage';
import CreateHandlerPage from '@vlabs/pages/handlers/CreateHandlerPage';
import { EditHandlerPage } from '@vlabs/pages/handlers/EditHandlerPage';
import HandlerListPage from '@vlabs/pages/handlers/HandlerListPage';
import OtherScenarioStepper from '@vlabs/pages/handlers/preconfigured-scenarios/other/OtherScenarioStepper';
import FaceListPage from '@vlabs/pages/lists/face-list-page/FaceListPage';
import ListsListPage from '@vlabs/pages/lists/ListsListPage';
import { NotificationListPage, NotificationPage } from '@vlabs/pages/notifications';
import { SearchByDocuments } from '@vlabs/pages/search-by-documents/SearchByDocuments';
import SearchPage from '@vlabs/pages/search/SearchContainer';
import SourceListPage from '@vlabs/pages/sources/SourceListPage';
import { ChartSettings } from '@vlabs/pages/statistic/ChartSettings';
import { StatisticPage } from '@vlabs/pages/statistic/StatisticPage';
import { StreamPage as LunaStreamsStreamPage } from '@vlabs/pages/stream/StreamPage';
import TaskListPage from '@vlabs/pages/tasks/TaskListPage';
import { ThermoPage } from '@vlabs/pages/thermo';
import {
  SourceListPage as VAReporterSourceListPage,
  SourcePage as VAReporterSourcePage,
  ValidationPage as VAReporterValidationPage,
  PlansListPage as VAReporterPlansListPage,
  ReportListPage as VAReporterReportListPage,
} from '@vlabs/pages/va-reporter';
import CreateVerifier from '@vlabs/pages/verifiers/CreateVerifier';
import VerifierFormPage from '@vlabs/pages/verifiers/VerifierFormPage';
import VerifierListPage from '@vlabs/pages/verifiers/VerifierListPage';
import VerifierTestPage from '@vlabs/pages/verifiers/VerifierTestPage';
import { permissions } from '@vlabs/shared/config';
import withControl from '@vlabs/shared/hocs/routeControllerHOC';
import { routes } from '@vlabs/shared/navigation/routes';
import { selectAppServiceState } from '@vlabs/shared/selectors/appSelectors';
import { Pages } from '@vlabs/uikit';
import { Route, Redirect, Switch, generatePath } from 'react-router-dom';

// import { fetchAccounts } from 'features/accounts/store';
import { selectEventsState } from '@vlabs/pages/events/events/selectors';
import { fetchEvents } from '@vlabs/pages/events/events/store';
import { selectLatestEventsState } from '@vlabs/pages/events/latest-events/selectors';
import { fetchLatestEvents } from '@vlabs/pages/events/latest-events/store';
import { selectHandlerStateById } from '@vlabs/pages/handlers/selectors';
import { fetchHandlerList } from '@vlabs/pages/handlers/store';

export default () => (
  <Switch>

    {/* [App] Monitoring */}
    <Route
      component={withControl({
        authRule: [permissions.appInfo.view],
      })(InfoPage)}
      exact
      path={routes.app.info}
    />

    {/* [Luna] Monitoring */}
    <Route
      component={withControl({
        componentState: (state) => selectAppServiceState(state, 'lunaPlatform'),
      })(StatisticPage)}
      exact
      path={routes.events.statistic.all}
    />

    <Route
      component={withControl({
        componentState: (state) => selectAppServiceState(state, 'lunaPlatform'),
      })(ChartSettings)}
      exact
      path={routes.events.statistic.read}
    />

    {/* [App] Features */}
    <Route
      component={withControl({
        authRule: [permissions.appInfo.view],
      })(FeaturesPage)}
      exact
      path={routes.app.features}
    />

    {/* [App] Plugins */}
    <Route
      component={withControl({
        authRule: [permissions.appInfo.view],
      })(PluginsPage)}
      exact
      path={routes.app.plugins}
    />

    {/* [Clementine-auth] */}
    <Route component={ResetPasswordPage} path={routes.auth.reset} />
    <Route
      component={withControl({
        authRule: [permissions.account.view],
        componentState: (state) => selectAppServiceState(state, 'clementineAuth'),
        // onInit: ({ dispatch }) => dispatch(fetchAccounts),
      })(AccountListPage)}
      path={routes.accounts.all}
    />

    {/* [Luna] latest events (ws) */}
    <Route
      component={withControl({
        authRule: [permissions.event.view],
        componentState: selectLatestEventsState,
        onLoaded: ({ dispatch }) => dispatch(fetchLatestEvents),
      })(LatestEventListPage)}
      exact
      path={routes.events.latest}
    />

    {/* [Luna] events */}
    <Route
      component={withControl({
        authRule: [permissions.event.view],
        componentState: selectEventsState,
        onLoaded: ({ dispatch }) => dispatch(fetchEvents),
      })(EventListPage)}
      exact
      path={routes.events.archive}
    />
    <Route
      component={withControl({
        authRule: [permissions.event.view],
      })(EventPage)}
      exact
      path={routes.events.eventDetails}
    />

    <Route
      component={withControl({
        authRule: [permissions.event.view, permissions.face.view],
        componentState: (state) => selectAppServiceState(state, 'lunaPlatform'),
      })(SearchByDocuments)}
      path={routes.searchByDocuments}
    />

    {/* [Luna] matching */}
    <Route
      component={withControl({
        componentState: (state) => selectAppServiceState(state, 'lunaPlatform'),
        authRule: [permissions.event.matching, permissions.face.matching],
      })(SearchPage)}
      path={routes.search}
    />

    {/* [Luna] event sources */}
    <Route
      component={withControl({
        componentState: (state) => ([
          selectAppServiceState(state, 'lunaStreams'),
          selectAppServiceState(state, 'vlAccess'),
        ].find((v) => v === 'loaded') || 'failed'),
      })(SourceListPage)}
      exact
      path={routes.sources.main}
    />

    {/* [Luna streams] */}
    <Route
      exact
      path={routes.sources.lunaStreams.streams.create}
      render={(router) => {
        const Component = withControl({
          authRule: [permissions.source.creation],
          componentState: (state) => selectAppServiceState(state, 'lunaStreams'),
        })(LunaStreamsEditStreamPage);
        return (
          <Component
            afterCreateStream={(streamId) => {
              router.history.replace(generatePath(routes.sources.lunaStreams.streams.view, { streamId }));
            }}
            afterDeleteStream={() => { router.history.push(routes.sources.main); }}
            streamId="create"
            {...router}
          />
        );
      }}
    />
    <Route
      path={routes.sources.lunaStreams.streams.edit}
      render={(router) => {
        const Component = withControl({
          authRule: [permissions.source.modification],
          componentState: (state) => selectAppServiceState(state, 'lunaStreams'),
        })(LunaStreamsEditStreamPage);
        return (
          <Component
            afterCreateStream={(streamId) => {
              router.history.replace(generatePath(routes.sources.lunaStreams.streams.view, { streamId }));
            }}
            afterDeleteStream={() => { router.history.push(routes.sources.main); }}
            streamId={router.match.params.streamId}
            {...router}
          />
        );
      }}
    />
    <Route
      path={routes.sources.lunaStreams.streams.view}
      render={(router) => {
        const Component = withControl({
          componentState: (state) => selectAppServiceState(state, 'lunaStreams'),
        })(LunaStreamsStreamPage);
        return (
          <Component streamId={router.match.params.streamId} {...router} />
        );
      }}
    />

    {/* [Luna] handlers */}
    <Route
      component={withControl({
        authRule: [permissions.handler.creation],
        componentState: (state) => selectAppServiceState(state, 'lunaPlatform'),
      })(OtherScenarioStepper)}
      exact
      path={routes.handlers.other}
    />
    <Route
      component={withControl({
        authRule: [permissions.handler.creation],
        componentState: (state) => selectAppServiceState(state, 'lunaPlatform'),
      })(CreateHandlerPage)}
      path={routes.handlers.create}
    />
    <Route
      component={withControl({
        authRule: [permissions.handler.view],
        componentState: (state, { match }) => (
          selectHandlerStateById(state, match?.params?.handlerId)
        ),
      })(EditHandlerPage)}
      path={routes.handlers.read}
    />
    <Route
      component={withControl({
        authRule: [permissions.handler.view],
        componentState: (state) => selectAppServiceState(state, 'lunaPlatform'),
        onInit: ({ dispatch }) => dispatch(fetchHandlerList),
      })(HandlerListPage)}
      exact
      path={routes.handlers.all}
    />

    {/* [Luna] verifiers */}
    <Route
      component={withControl({
        authRule: [permissions.verifier.view],
        componentState: (state) => selectAppServiceState(state, 'lunaPlatform'),
      })(VerifierTestPage)}
      exact
      path={routes.verifiers.verify}
    />
    <Route
      component={withControl({
        authRule: [permissions.verifier.creation],
        componentState: (state) => selectAppServiceState(state, 'lunaPlatform'),
      })(CreateVerifier)}
      path={routes.verifiers.create}
    />
    <Route
      component={withControl({
        authRule: [permissions.verifier.view],
        componentState: (state) => selectAppServiceState(state, 'lunaPlatform'),
      })(VerifierFormPage)}
      exact
      path={routes.verifiers.read}
    />
    <Route
      component={withControl({
        authRule: [permissions.verifier.view],
        componentState: (state) => selectAppServiceState(state, 'lunaPlatform'),
      })(VerifierListPage)}
      exact
      path={routes.verifiers.all}
    />

    {/* [Luna] lists */}
    <Route
      component={withControl({
        authRule: [permissions.face.view],
        componentState: (state) => selectAppServiceState(state, 'lunaPlatform'),
      })(FaceListPage)}
      path={routes.lists.read}
    />
    <Route
      component={withControl({
        authRule: [permissions.list.view],
        componentState: (state) => selectAppServiceState(state, 'lunaPlatform'),
      })(ListsListPage)}
      exact
      path={routes.lists.all}
    />

    {/* [Luna] faces */}
    <Route
      component={withControl({
        authRule: [permissions.face.view],
      })(FacePage)}
      path={routes.facePage}
    />
    <Route
      component={withControl({
        authRule: [permissions.face.view],
        componentState: (state) => selectAppServiceState(state, 'lunaPlatform'),
      })(ExternalIdPage)}
      path={routes.externalId.read}
    />
    <Route
      component={withControl({
        authRule: [permissions.face.view],
        componentState: (state) => selectAppServiceState(state, 'lunaPlatform'),
      })(FacesPage)}
      path={routes.faces.all}
    />

    {/* [Luna] tasks */}
    <Route
      component={withControl({
        authRule: [permissions.task.view],
        componentState: (state) => selectAppServiceState(state, 'lunaPlatform'),
      })(TaskListPage)}
      path={routes.tasks.all}
    />

    {/* [Luna] checks */}
    <Route
      component={withControl({
        authRule: [permissions.resources.iso, permissions.resources.sdk, permissions.emit_events.allowed],
        componentState: (state) => {
          const lunaPlatformState = selectAppServiceState(state, 'lunaPlatform');
          if (lunaPlatformState !== 'loaded') return lunaPlatformState;
          return 'loaded';
        },
      })(ChecksPage)}
      path={routes.checks.read}
    />

    {/* [Clementine-departments] */}
    <Route
      component={withControl({
        authRule: [permissions.department.view],
        componentState: (state) => selectAppServiceState(state, 'clementineDepartments'),
      })(DepartmentPage)}
      path={routes.departments.read}
    />
    <Route
      component={withControl({
        authRule: [permissions.department.view],
        componentState: (state) => selectAppServiceState(state, 'clementineDepartments'),
      })(DepartmentListPage)}
      exact
      path={routes.departments.all}
    />

    {/* [VLNotifier] */}
    <Route
      component={withControl({
        authRule: [permissions.notification.view],
        componentState: (state) => selectAppServiceState(state, 'vlNotifier'),
      })(NotificationPage)}
      path={routes.notifications.read}
    />
    <Route
      component={withControl({
        authRule: [permissions.notification.view],
        componentState: (state) => selectAppServiceState(state, 'vlNotifier'),
      })(NotificationListPage)}
      exact
      path={routes.notifications.all}
    />

    {/* [VAReporter] */}
    <Route
      component={withControl({
        componentState: (state) => selectAppServiceState(state, 'vaReporter'),
      })(VAReporterReportListPage)}
      path={routes.vaReporter.reports}
    />
    <Route
      component={withControl({
        componentState: (state) => selectAppServiceState(state, 'vaReporter'),
      })(VAReporterValidationPage)}
      path={routes.vaReporter.plans.validation}
    />
    <Route
      component={withControl({
        componentState: (state) => selectAppServiceState(state, 'vaReporter'),
      })(VAReporterSourcePage)}
      path={routes.vaReporter.plans.calibration}
    />
    <Route
      component={withControl({
        componentState: (state) => selectAppServiceState(state, 'vaReporter'),
      })(VAReporterSourceListPage)}
      path={routes.vaReporter.plans.calibrationList}
    />
    <Route
      component={withControl({
        componentState: (state) => selectAppServiceState(state, 'vaReporter'),
      })(VAReporterPlansListPage)}
      path={routes.vaReporter.plans.listAll}
    />

    {/* VLThermo */}
    <Route
      component={withControl({
        authRule: [permissions.thermo.read],
        componentState: (state) => selectAppServiceState(state, 'thermo'),
      })(ThermoPage)}
      exact
      path={routes.thermo.read}
    />

    {/* Router settings */}
    <Route exact path="/" render={() => <Redirect to={routes.events.latest} />} />
    <Route component={Pages.NotFoundPage} path="*" />
  </Switch>
);
