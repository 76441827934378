import React, { useEffect, useMemo, useState, useRef } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { DownloadIcon, RefreshRightIcon, RotationLeftIcon } from '@vlabs/icons';
import { routes } from '@vlabs/shared/navigation/routes';
import { downloadJson } from '@vlabs/shared/utils/files';
import { Page, Panel, Tabs, Divider, Tab, Control, openConfirmPopup } from '@vlabs/uikit';
import FileSaver from 'file-saver';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, generatePath } from 'react-router-dom';

import { chartConfigurations } from './chartDefaultConfigurations';
import ChartPanel from './ChartPanel';
import st from './ChartSettings.module.sass';
import { chartTypeOptions, diagramTypeOptions } from './constants';
import { ClusteringForm } from './forms/ClusteringForm';
import { CommonParamsForm } from './forms/CommonParamsForm';
import { FiltersForm } from './forms/FiltersForm';

const downloadCSV = (fileName, data) => {
  const headers = data.fields.map((field) => field.split(',')[0].trim());

  let csvContent = `${headers.join(',')}\n`;
  data.stats.forEach((row) => {
    csvContent += `${row.map((value) => (value === null ? 'null' : value)).join(',')}\n`;
  });

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  FileSaver.saveAs(blob, `${fileName}.csv`);
};

export const ChartSettings = () => {
  const { t } = useTranslation();
  const { diagramType } = useParams();
  const defaultValues = chartConfigurations[diagramType];
  const form = useForm({ defaultValues });
  const [chartData, setChartData] = useState(null);
  const [chartType, setChartType] = useState(defaultValues.diagramType.value);
  const previousDiagramTypeRef = useRef(chartType);
  const resetState = useRef(false);

  const watchedDiagramType = useWatch({ control: form.control, name: 'diagramType' });
  const groupByTypeValue = useWatch({ control: form.control, name: 'group_by.type' })?.value;
  const groupByTargets = useWatch({ control: form.control, name: 'groupByTargets' });

  const isPieOrDoughnutChartType = useMemo(() => {
    return [diagramTypeOptions.pie?.value, diagramTypeOptions.doughnut?.value].includes(watchedDiagramType.value);
  }, [watchedDiagramType]);

  const requiresConfirmation = useMemo(() => {
    return groupByTypeValue || (groupByTargets?.length > 1);
  }, [groupByTypeValue, groupByTargets]);

  const fetchChartData = async (formValues) => {
    const response = await apiContainer.lunaClient.events.getStatistic({
      targets: [...formValues.mainTargets, ...formValues.groupByTargets],
      group_by: formValues.group_by,
      period: formValues.period,
      filters: formValues.filters,
    });
    setChartData(response?.data);
  };

  useEffect(() => { fetchChartData(defaultValues); }, []);

  const resetForm = async () => {
    setChartData(undefined);
    resetState.current = {
      commonForm: true,
      clusteringForm: true,
    };

    form.reset(chartConfigurations[diagramType]);

    setChartType(watchedDiagramType.value);
    previousDiagramTypeRef.current = watchedDiagramType;
    await fetchChartData(form.getValues());
  };

  const resetFormAndFetchData = async (currentValues) => {
    setChartData(undefined);
    form.reset({
      ...currentValues,
      group_by: {
        type: null,
        count: '',
        timeDimension: null,
        frequency: null,
      },
      groupByTargets: currentValues.groupByTargets.length > 1 ? [currentValues.groupByTargets[0]] : currentValues.groupByTargets,
    });
    setChartType(watchedDiagramType.value);
    previousDiagramTypeRef.current = watchedDiagramType;
    await fetchChartData(form.getValues());
  };

  const handleDiagramTypeChange = () => {
    const currentValues = form.getValues();
    openConfirmPopup({
      title: t('statistic:diagram type.change title'),
      message: t('statistic:diagram type.change message'),
      type: 'warning',
      onConfirm: () => resetFormAndFetchData(currentValues),
      onCancel: () => form.setValue('diagramType', previousDiagramTypeRef.current),
    });
  };

  useEffect(() => {
    if (isPieOrDoughnutChartType && requiresConfirmation) {
      handleDiagramTypeChange();
      return;
    }

    setChartType(watchedDiagramType.value);
    previousDiagramTypeRef.current = watchedDiagramType;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedDiagramType]);

  const breadcrumbs = [
    { caption: t('statistic:page title'), link: generatePath(routes.events.statistic.all) },
    { caption: chartTypeOptions?.[diagramType]?.label },
  ];

  const context = {
    ...form,
    groupByTypeValue,
    chartType,
    isPieOrDoughnutChartType,
    resetState,
  };

  return (
    <FormProvider {...context}>
      <form onSubmit={form.handleSubmit(fetchChartData)}>
        <Page breadcrumbs={breadcrumbs} className={st.Page} title={chartTypeOptions?.[diagramType]?.label}>
          <div className={st.ContentWrapper}>
            <Panel className={st.SettingsPanel}>
              <div className={st.TabsWrapper}>
                <Tabs testId="tabs">
                  <Tab header={t('statistic:tabs.basic parameters')} name="basicParameters">
                    <CommonParamsForm />
                  </Tab>
                  <Tab header={t('statistic:tabs.grouping')} name="grouping">
                    <ClusteringForm />
                  </Tab>
                  <Tab header={t('statistic:tabs.filters')} name="filters">
                    <FiltersForm />
                  </Tab>
                </Tabs>
              </div>
              <div className={st.SettingsPanelButtonGroup}>
                <div className={st.ButtonWrapper}>
                  <Control.Button fullWidth icon={<RefreshRightIcon />} type="submit">
                    {t('statistic:btn.refresh')}
                  </Control.Button>
                </div>
                <Divider small />
                <div className={st.ButtonWrapper}>
                  <Control.Button fullWidth icon={<RotationLeftIcon />} kind="negative" onClick={() => resetForm()}>
                    {t('statistic:btn.reset to default')}
                  </Control.Button>
                </div>
              </div>
            </Panel>
            <Panel className={st.ChartPanel}>
              <div className={st.ChartPanelButtonGroup}>
                <Control.Button icon={<DownloadIcon />} kind="secondary" onClick={() => downloadJson(chartType, chartData)}>
                  JSON
                </Control.Button>
                <Divider small />
                <Control.Button icon={<DownloadIcon />} kind="secondary" onClick={() => downloadCSV(chartType, chartData)}>
                  CSV
                </Control.Button>
              </div>
              <ChartPanel chartData={chartData} chartType={chartType} />
            </Panel>
          </div>
        </Page>
      </form>
    </FormProvider>
  );
};
