import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const FilterIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="FilterIcon" ref={iconRef} viewBox="0 0 24 24" {...props}>
      <path d="M20 4H4L10.4 12.4089V18.2222L13.6 20V12.4089L20 4Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    </IconInner>
  );
};

export const FilterIcon = memo(FilterIconInner);
