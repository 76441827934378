const getBarOptions = (label) => ({
  responsive: true,
  plugins: { title: { display: true } },
  scales: {
    x: {
      categoryPercentage: 0.8,
      barPercentage: 0.9,
      maxBarThickness: 50,
      title: {
        display: true,
        text: label || '',
      },
    },
    y: {
      beginAtZero: true,
    },
  },
}
);

const getStackOptions = (label) => ({
  responsive: true,
  plugins: { title: { display: true } },
  scales: {
    x: {
      stacked: true,
      title: {
        display: true,
        text: label || '',
      },
    },
    y: {
      stacked: true,
      beginAtZero: true,
    },
  },
});

const getLineOptions = (label) => ({
  responsive: true,
  plugins: { title: { display: true } },
  scales: {
    x: {
      title: { display: true, text: label || '' },
    },
    y: {
      beginAtZero: true,
    },
  },
});

const getAreaOptions = (label) => ({
  responsive: true,
  plugins: { title: { display: true } },
  elements: { line: { fill: true, tension: 0.4 } },
  scales: {
    x: {
      title: { display: true, text: label || '' },
    },
    y: {
      beginAtZero: true,
    },
  },
});

const getStepAreaOptions = (label) => ({
  responsive: true,
  plugins: {
    title: { display: true },
    legend: { display: true },
  },
  elements: {
    line: {
      fill: true,
      stepped: true,
    },
  },
  scales: {
    x: {
      title: { display: true, text: label || '' },
    },
    y: {
      beginAtZero: true,
    },
  },
});

const getPieOptions = (label) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: !!label,
      text: label || '',
    },
    datalabels: {
      display: true,
    },
  },
  animation: {
    animateRotate: true,
    animateScale: true,
  },
});

const getDoughnutOptions = (label) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { position: 'top' },
    title: { display: true, text: label || '' },
  },
  cutout: '50%',
});

const getRadarOptions = (label) => ({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    r: {
      ticks: {
        beginAtZero: true,
        stepSize: 20,
      },
    },
  },
  plugins: {
    legend: { position: 'top' },
    title: {
      text: label || '',
      display: true,
    },
  },
  elements: {
    point: {
      radius: 3,
      hoverRadius: 6,
    },
  },
});

const getHorizontalBarOptions = (label) => ({
  responsive: true,
  plugins: { legend: { position: 'top' } },
  indexAxis: 'y',
  scales: {
    x: {
      beginAtZero: true,
    },
    y: {
      title: { display: true, text: label || '' },
    },
  },
});

export const getOptionsForChart = (chartType, xAxisLabel) => {
  switch (chartType) {
    case 'bar':
      return getBarOptions(xAxisLabel);
    case 'horizontalBar':
      return getHorizontalBarOptions(xAxisLabel);
    case 'stack':
      return getStackOptions(xAxisLabel);
    case 'line':
      return getLineOptions(xAxisLabel);
    case 'area':
      return getAreaOptions(xAxisLabel);
    case 'stepArea':
      return getStepAreaOptions(xAxisLabel);
    case 'pie':
      return getPieOptions(xAxisLabel);
    case 'doughnut':
      return getDoughnutOptions(xAxisLabel);
    case 'radar':
      return getRadarOptions(xAxisLabel);
    default:
      return getBarOptions(xAxisLabel);
  }
};
