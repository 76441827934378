import { CleanSchema, Schema, get } from '../../../json-transform';

const stringFields = [
  'tags',
  'external_id',
  'top_similar_external_id',
  'top_matching_candidates_label',
  'city',
  'area',
  'district',
  'street',
  'track_id',
  'user_data',
];
// FIXME: обсудить с Димой где все таки делать обрабку, в схеме или вынести в утилиту и запускать перед сабмитом
// и как это можно сделать проще
const normalizeValue = (value, fieldName = null) => {
  if (typeof value === 'string') {
    if (value === 'null') return null;

    const numberValue = parseFloat(value);

    if (fieldName && stringFields.includes(fieldName)) {
      return value;
    }

    if (!Number.isNaN(numberValue) && String(numberValue) === value.trim()) {
      return numberValue;
    }

    return value;
  }

  if (typeof value === 'number') {
    if (fieldName && stringFields.includes(fieldName)) {
      return String(value);
    }
    return value;
  }

  if (Array.isArray(value)) {
    return value.map((item) => normalizeValue(item, fieldName));
  }

  if (typeof value === 'object' && value !== null) {
    return normalizeValue(value.value, fieldName);
  }

  return value;
};

const processPeriodEntries = (entries) => {
  return entries
    .filter(([_, value]) => value !== null && value !== undefined && value.length)
    .map(([key, value]) => new Schema({
      operator: get().as(() => key),
      value: get().as(() => (Array.isArray(value) ? value[0] : value)),
    })({}));
};

export const postStatistic = new CleanSchema({
  filters: get('filters').asArrayOf(new Schema({
    column: get('column.value'),
    operator: get('operator.value'),
    value: get().as(({ operator, value, column }) => {
      if (operator.value === 'covered') {
        const convertedObj = Object.fromEntries(
          Object.entries(value)
            .filter(([k, v]) => v !== undefined && v !== null && v !== '')
            .map(([k, v]) => [k, parseFloat(v)]),
        );

        return convertedObj;
      }

      const processedValue = normalizeValue(value, column?.value);

      if (['like', 'nlike', 'in', 'nin'].includes(operator?.value)) {
        return Array.isArray(processedValue) ? processedValue : [processedValue];
      }

      return processedValue;
    }),
  })),
  period: get('period').as((period) => {
    if (Array.isArray(period)) {
      return period.flatMap((v) => processPeriodEntries(Object.entries(v)));
    }
    return processPeriodEntries(Object.entries(period));
  }).ifEmpty(undefined),
  targets: get('targets').asArrayOf(new Schema({
    aggregator: get('aggregator.value'),
    column: get('column.value'),
  })),
  group_by: get('group_by').as((item) => {
    if (!item?.type?.value) return undefined;
    if (typeof item === 'string') return item;
    switch (item?.type?.value) {
      case 'interval': return `${item.count}${item.timeDimension.value}`;
      case 'frequency': return item.frequency.value;
      default: return undefined;
    }
  }).ifEmpty(undefined),
});
