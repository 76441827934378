import React, { useEffect, useRef } from 'react';

import { CrossIcon, PlusIcon } from '@vlabs/icons';
import validate from '@vlabs/shared/validators';
import { Control, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { timeGroupingTypeOptions, timeDimensionOptions, frequencyOptions, targetForGroupByAggregatorOptions } from '../constants';
import st from './ClusteringForm.module.sass';

export const ClusteringForm = () => {
  const { t } = useTranslation();
  const {
    groupByTypeValue,
    isPieOrDoughnutChartType,
    getValues,
    setValue,
    clearErrors,
    control,
    register,
    formState: { errors },
    resetState,
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: 'groupByTargets' });
  const previousGroupByTypeValueRef = useRef(groupByTypeValue);

  const isIntervalTypeSelected = groupByTypeValue === timeGroupingTypeOptions.interval.value;
  const isFrequencyTypeSelected = groupByTypeValue === timeGroupingTypeOptions.frequency.value;

  useEffect(() => {
    if (resetState.current.clusteringForm) {
      resetState.current.clusteringForm = false;
      return;
    }
    if (previousGroupByTypeValueRef.current === groupByTypeValue) return;

    const fieldsToReset = ['group_by.frequency', 'group_by.count', 'group_by.timeDimension'];
    fieldsToReset.forEach((field) => setValue(field, null));

    clearErrors('group_by');
    previousGroupByTypeValueRef.current = groupByTypeValue;
  }, [groupByTypeValue, setValue, getValues]);

  const isGroupByDisabled = isPieOrDoughnutChartType || fields.length === 2;
  let groupByTimeTooltipContent = null;
  if (fields.length === 2) {
    groupByTimeTooltipContent = t('statistic:form.clustering.tooltip.two fields');
  } else if (isPieOrDoughnutChartType) {
    groupByTimeTooltipContent = t('statistic:form.clustering.tooltip.pie or doughnut');
  }

  const conditions = {
    hasTwoFieldsWithoutGroupBy: !groupByTypeValue && fields.length === 2,
    hasOneFieldForGroupBy: (isPieOrDoughnutChartType || groupByTypeValue) && fields.length === 1,
  };
  const isAddFieldButtonDisabled = conditions.hasTwoFieldsWithoutGroupBy || conditions.hasOneFieldForGroupBy;

  return (
    <div>
      <div className={st.Title}>{t('statistic:form.clustering.group by time')}</div>
      <SettingsItemWrapper
        contentClassName={st.SettingsItem}
        data-tooltip-content={groupByTimeTooltipContent}
        disabled={isGroupByDisabled}
        title={t('statistic:form.clustering.group by')}
      >
        <Control.Select
          control={control}
          disabled={isGroupByDisabled}
          isClearable
          name="group_by.type"
          options={timeGroupingTypeOptions.raw}
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        contentClassName={st.SettingsItem}
        data-tooltip-content={!isIntervalTypeSelected && t('statistic:form.clustering.tooltip.interval')}
        disabled={!isIntervalTypeSelected}
        title={t('statistic:form.clustering.count')}
      >
        <Control.Input
          {...register('group_by.count',
            {
              validate: validate.number(),
              required: groupByTypeValue === timeGroupingTypeOptions.interval.value && validate.required(),
            })}
          disabled={!isIntervalTypeSelected}
          errors={errors}
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        contentClassName={st.SettingsItem}
        data-tooltip-content={!isIntervalTypeSelected && t('statistic:form.clustering.tooltip.interval')}
        disabled={!isIntervalTypeSelected}
        title={t('statistic:form.clustering.dimension')}
      >
        <Control.Select
          control={control}
          disabled={!isIntervalTypeSelected}
          errors={errors}
          name="group_by.timeDimension"
          options={timeDimensionOptions.raw}
          rules={{ required: isIntervalTypeSelected && validate.required() }}
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        contentClassName={st.SettingsItem}
        data-tooltip-content={!isFrequencyTypeSelected && t('statistic:form.clustering.tooltip.frequency')}
        disabled={!isFrequencyTypeSelected}
        title={t('statistic:form.clustering.frequency')}
      >
        <Control.Select
          control={control}
          disabled={!isFrequencyTypeSelected}
          errors={errors}
          name="group_by.frequency"
          options={frequencyOptions.raw}
          rules={{ required: isFrequencyTypeSelected && validate.required() }}
        />
      </SettingsItemWrapper>

      <div className={st.Title}>{t('statistic:form.clustering.group by field')}</div>
      {fields.map((field, i) => (
        <div key={field.id}>
          <SettingsItemWrapper
            contentClassName={st.SettingsItem}
            title={t('statistic:form.clustering.field')}
          >
            <Control.Select
              control={control}
              errors={errors}
              isClearable
              name={`groupByTargets.${i}.column`}
              options={targetForGroupByAggregatorOptions.raw}
              rules={{ required: validate.required() }}
            />
          </SettingsItemWrapper>
          <div className={st.DeleteButton}>
            <Control.Button
              icon={<CrossIcon />}
              kind="negative"
              onClick={() => remove(i)}
              variant="flat"
            >
              {t('statistic:btn.remove')}
            </Control.Button>
          </div>
        </div>
      ))}
      <Control.Button
        disabled={isAddFieldButtonDisabled}
        icon={<PlusIcon />}
        onClick={() => append({ column: '', aggregator: { label: 'group_by', value: 'group_by' } })}
        variant="flat"
      >
        {t('statistic:btn.add field')}
      </Control.Button>
    </div>
  );
};
