function getFileType(fileRaw) {
  const extension = fileRaw.name.split('.').pop().toLowerCase();
  // формат PPM (Portable Pixmap) не имеет MIME-тип
  if (extension === 'ppm') {
    return 'image/x-portable-pixmap';
  }
  return fileRaw.type;
}

export function addFileType(newFile) {
  const type = getFileType(newFile);
  return new File([newFile], newFile.name, { ...newFile, type });
}

export function handleBase64ToArrayBuffer(base64String) {
  try {
    const base64Data = base64String.startsWith('data:image') ? base64String.split(',')[1] : base64String;
    const byteCharacters = atob(base64Data);
    const byteNumbers = Array.from(byteCharacters).map((c) => c.charCodeAt(0));

    return new Uint8Array(byteNumbers);
  } catch (error) {
    console.error('Ошибка обработки base64 данных:', error);
    return null;
  }
}

export function handleBase64ToBlob(base64String, defaultType = 'image/png') {
  try {
    const byteArray = handleBase64ToArrayBuffer(base64String);
    return new Blob([byteArray], { type: defaultType });
  } catch (error) {
    console.error('Ошибка обработки base64 данных:', error);
    return null;
  }
}
