import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const CircleCheckFilledIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="CircleCheckFilledIcon" ref={iconRef} viewBox="0 0 24 24" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12ZM18.2071 9.2071C18.5976 8.81658 18.5976 8.18341 18.2071 7.79289C17.8166 7.40237 17.1834 7.40237 16.7929 7.7929L10.5 14.0858L7.7071 11.2929C7.31657 10.9024 6.68341 10.9024 6.29289 11.2929C5.90237 11.6834 5.90237 12.3166 6.2929 12.7071L9.72223 16.1364C10.1518 16.566 10.8483 16.566 11.2779 16.1364L18.2071 9.2071Z" fill="currentColor" />
    </IconInner>
  );
};

export const CircleCheckFilledIcon = memo(CircleCheckFilledIconInner);
