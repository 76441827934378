import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const RecognitionIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="RecognitionIcon" ref={iconRef} viewBox="0 0 50 50" {...props}>
      <path stroke="currentColor" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth={strokeWidth} d="M43.053 25.028 30.036 38.045l-6.008-6.008-4.005 4.005 10.013 10.014 17.023-17.023-4.006-4.005Z" /><path stroke="currentColor" strokeMiterlimit="10" strokeWidth={strokeWidth} d="M31.037 30.035v-1.002c0-4.005-5.51-7.001-10.013-7.001-4.502 0-10.013 2.996-10.013 7.001v6.008m10.013-13.017c-3.612 0-6.008-2.42-6.008-5.407V13.41c0-2.986 2.396-5.407 6.008-5.407s6.008 2.42 6.008 5.407v3.206c0 2.986-2.396 5.407-6.008 5.407Z" /><path stroke="currentColor" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth={strokeWidth} d="M38 12V4h-8M4 34v8h8M4 12V4h8" />
    </IconInner>
  );
};

export const RecognitionIcon = memo(RecognitionIconInner);
