import React, { useState, useEffect, useCallback } from 'react';

import { detectionType } from '@vlabs/uikit/src/types';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import st from './DetectionSelector.module.sass';
import { ImagePicker } from './ImagePicker';

export const DetectionSelector = ({
  detections,
  onSelect,
  selectedId,
  imageRenderer,
  renderedImage,
  selectedStateProps,
  isMultiDetections,
}) => {
  const { t } = useTranslation();

  // Используем состояния, если selectProps не передан
  const [internalSelected, setInternalSelected] = useState([]);
  const [selected, setSelected] = selectedStateProps
    ? [selectedStateProps.selectedDetections, selectedStateProps.setSelectedDetections]
    : [internalSelected, setInternalSelected];

  const isSelected = useCallback((detectionId) => {
    return selected.some((d) => d.id === detectionId);
  }, [selected]);

  const onSelectHandler = useCallback((detectionId) => {
    setSelected([detections.find((d) => d.id === detectionId)]);
  }, [detections, setSelected]);

  useEffect(() => {
    if (!onSelect) return;
    onSelect(selected.map((d) => d.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (detections?.length === 1 || selectedId) {
      setSelected([detections[0] || detections[selectedId]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detections]);

  return (
    <>
      {detections?.length > 0 && !isMultiDetections && (
      <span className={st.Count} data-testid="detectionSelector.count">
          {t('uikit:detectionSelector.number of detected faces', { count: detections.length })}
      </span>
      )}
      <div className={st.Root} data-testid="detectionSelector">
        {!detections?.length && imageRenderer}
        {detections?.length > 0 && (
        <div className={st.PickerContainer}>
          <div className={st.Column}>
            <ImagePicker
              detections={detections}
              imageRenderer={imageRenderer}
              isSelected={isSelected}
              onSelect={onSelectHandler}
              renderedImage={renderedImage}
            />
          </div>
        </div>
        )}
      </div>
    </>
  );
};

DetectionSelector.propTypes = {
  imageRenderer: PropTypes.node.isRequired,
  detections: PropTypes.arrayOf(detectionType),
  onSelect: PropTypes.func,
  selectedId: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  isMultiDetections: PropTypes.bool,
  selectedStateProps: PropTypes.shape({
    selectedDetections: PropTypes.array,
    setSelectedDetections: PropTypes.func,
  }),
  renderedImage: PropTypes.any,
};

DetectionSelector.defaultProps = {
  detections: [],
  isMultiDetections: false,
  onSelect: undefined,
  renderedImage: undefined,
  selectedId: undefined,
  selectedStateProps: undefined,
};
