import React, { createContext, useContext, useMemo, useState } from 'react';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isLightTheme, setIsLightTheme] = useState(window.localStorage.getItem('vl-theme') !== 'dark');

  const toggleTheme = () => {
    const newTheme = isLightTheme ? 'dark' : 'light';
    window.localStorage.setItem('vl-theme', newTheme);

    const style = document.getElementById('theme-changing');
    style.sheet.insertRule('* { transition: unset !important; }');
    document.documentElement.setAttribute('data-theme', newTheme);
    setTimeout(() => style.sheet.deleteRule(0), 0);
    setIsLightTheme(!isLightTheme);
  };

  const value = useMemo(() => ({ isLightTheme, toggleTheme }), [isLightTheme]);

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
