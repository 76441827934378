import React from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { ButtonWithPermissions } from '@vlabs/shared/components/button-with-permissions/ButtonWithPermissions';
import { permissions } from '@vlabs/shared/config';
import { useModal, openConfirmPopup } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { GarbageCollectionStepperForm } from './GarbageCollectionStepperForm';

export const GarbageCollectionWidget = ({ afterSubmit, filters }) => {
  const { t } = useTranslation();
  const modal = useModal();

  const submitForm = (values) => {
    openConfirmPopup({
      title: t('tasks:GCTask.подтверждение.заголовок'),
      message: t('tasks:GCTask.подтверждение.сообщение'),
      type: 'delete',
      onConfirm: async () => {
        await apiContainer.lunaClient.tasks.createGCTask(values);
        toast.info(t('tasks:api.GCTask.создание.успех'));
        modal.close();
        if (afterSubmit) afterSubmit();
      },
      confirmLabel: t('кнопка.ок'),
    });
  };

  return (
    <>
      {modal.wrap(<GarbageCollectionStepperForm filters={filters} onSubmit={submitForm} />)}
      <ButtonWithPermissions
        data-testid="garbageCollectionButton"
        onClick={modal.open}
        permissions={{ rules: [permissions.task.creation, permissions.face.deletion], method: 'allof' }}
      >
        {t('tasks:GCTask.кнопка.удаление лиц из списка')}
      </ButtonWithPermissions>
    </>
  );
};

GarbageCollectionWidget.propTypes = {
  afterSubmit: PropTypes.func,
  filters: PropTypes.shape({
    listId: PropTypes.string,
  }),
};

GarbageCollectionWidget.defaultProps = {
  afterSubmit: undefined,
  filters: {},
};
