import React, { useMemo } from 'react';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, RadarController, LineController, PointElement, LineElement, RadialLinearScale, Filler, Colors } from 'chart.js';
import { parseISO, format } from 'date-fns';
import { ru, enUS } from 'date-fns/locale';
import i18n from 'i18next';
import { Bar, Line, Pie, Doughnut, Radar } from 'react-chartjs-2';

import { getOptionsForChart } from './optionsMapping';

const GROUP_BY_TIME = 'Group by time';
const GROUP_BY_AGGREGATOR = "aggregator 'group_by'";

const LOCALES = {
  ru,
  en: enUS,
};

ChartJS.register(CategoryScale,
  LinearScale,
  Filler,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  RadarController,
  LineController,
  PointElement,
  LineElement,
  Colors,
  RadialLinearScale);

const chartComponents = {
  bar: Bar,
  horizontalBar: Bar,
  stack: Bar,
  line: Line,
  area: Line,
  stepArea: Line,
  pie: Pie,
  doughnut: Doughnut,
  radar: Radar,
};

const buildDataset = (label, data) => ({
  label: i18n.t(`statistic:${label}`),
  data,
  borderWidth: 1,
  minBarLength: 3,
});

const parseGroupAggregator = (input) => {
  const [grouping, aggregatorPart] = input.split(',').map((part) => part.trim());

  const aggregator = aggregatorPart?.match(/'([^']+)'/)?.[1] || '';

  return { grouping, aggregator };
};

const buildGroupWithAggregatorLabel = (grouping, aggregator) => {
  const groupingTranslation = i18n.t(`statistic:groupings.${grouping}.label`, { defaultValue: grouping });
  const aggregatorTranslation = i18n.t(`statistic:aggregators.${aggregator}`, { defaultValue: aggregator });
  return `${groupingTranslation}, ${aggregatorTranslation}`;
};

const buildGroupWithValueLabel = (groupLabel, value) => {
  const groupLabelTranslation = i18n.t(`statistic:groupings.${groupLabel}.label`, { defaultValue: groupLabel });
  const valueTranslation = i18n.t(`statistic:groupings.${groupLabel}.value.${value}`, { defaultValue: value });

  return `${groupLabelTranslation}, ${valueTranslation}`;
};

const FREQUENCY_GROUPS = ['monthOfYear', 'weekOfYear', 'dayOfYear', 'dayOfMonth', 'dayOfWeek', 'hourOfDay', 'minuteOfHour'];

const processDate = (value, timeField) => {
  const groupType = timeField.replace('Group by time: ', '').trim();

  if (FREQUENCY_GROUPS.includes(groupType)) {
    if (!Number.isNaN(Number(value))) return value;

    return i18n.t(`statistic:date.${value}`);
  }

  const parsedDate = parseISO(value);
  const currentLanguage = i18n.language || 'en';
  const locale = LOCALES[currentLanguage] || enUS;

  if (['d', 'w', 'M', 'y'].some((unit) => groupType.endsWith(unit))) {
    return format(parsedDate, 'dd MMMM yyyy', { locale });
  }

  return format(parsedDate, 'dd MMMM yyyy HH:mm:ss', { locale });
};

const getGroupTypeAndIndex = (fields) => {
  const timeIndex = fields.findIndex((field) => field.includes(GROUP_BY_TIME));
  if (timeIndex >= 0) {
    return { groupType: GROUP_BY_TIME, fieldName: fields[timeIndex], index: timeIndex };
  }

  const groupByIndex = fields.findIndex((field) => field.includes(GROUP_BY_AGGREGATOR));
  if (groupByIndex >= 0) {
    return { groupType: GROUP_BY_AGGREGATOR, fieldName: fields[groupByIndex], index: groupByIndex };
  }

  return { groupType: 'default', fieldName: fields[0], index: 0 };
};

const getUniqueLabels = (stats, index) => [...new Set(stats.map((stat) => stat[index] ?? 'Оценка не проводилась'))];

const getXAxisLabels = (input) => {
  const { fields, stats } = input;
  const { groupType, index: xAxisIndex, fieldName } = getGroupTypeAndIndex(fields);

  const { grouping, aggregator } = parseGroupAggregator(fieldName);
  const xAxisLabelTranslation = buildGroupWithAggregatorLabel(grouping, aggregator);
  const rawLabels = getUniqueLabels(stats, xAxisIndex);

  switch (groupType) {
    case GROUP_BY_TIME: {
      const displayLabels = rawLabels.map((v) => processDate(v, fieldName));
      const timeGroupingKey = fieldName.split(':')[1].trim();
      return {
        labels: { rawLabels, displayLabels },
        xAxisLabel: i18n.t(`statistic:filters.groupByTime.${timeGroupingKey}.label`, {
          defaultValue: i18n.t('statistic:filters.groupByTime.defaultLabel') }),
      };
    }
    case GROUP_BY_AGGREGATOR: {
      const groupLabel = fields[xAxisIndex].split(',')[0].trim();
      const displayLabels = rawLabels.map((label) => i18n.t(`statistic:groupings.${groupLabel}.value.${label}`, { defaultValue: label }) ?? 'Неизвестно');

      return { labels: { displayLabels, rawLabels }, xAxisLabel: xAxisLabelTranslation };
    }
    default: {
      return { labels: { displayLabels: rawLabels }, xAxisLabel: xAxisLabelTranslation };
    }
  }
};

const generateDatasets = (chartData, labels) => {
  const { fields, stats } = chartData;
  const { displayLabels, rawLabels } = labels;
  const { groupType, index: primaryIndex } = getGroupTypeAndIndex(fields);
  const datasets = [];

  const groupByAggregatorIndexes = fields.reduce((indexes, field, index) => {
    if (field.includes(GROUP_BY_AGGREGATOR)) {
      indexes.push(index);
    }
    return indexes;
  }, []);

  const valueFieldIndex = fields.findIndex(
    (field) => !field.includes(GROUP_BY_AGGREGATOR) && !field.includes(GROUP_BY_TIME),
  );

  switch (groupType) {
    case GROUP_BY_TIME: {
      if (groupByAggregatorIndexes.length === 1) {
        const groupFieldIndex = groupByAggregatorIndexes[0];

        const groupValues = [...new Set(stats.map((stat) => stat[groupFieldIndex]))];
        const groupLabel = fields[groupFieldIndex].split(',')[0].trim();

        groupValues.forEach((value) => {
          const data = displayLabels.map((label, labelIndex) => {
            const rawLabel = rawLabels[labelIndex];

            const matchingStat = stats.find((stat) => {
              const isTimeMatch = stat[primaryIndex] === rawLabel;
              const isGroupMatch = stat[groupFieldIndex] === value;

              return isTimeMatch && isGroupMatch;
            });
            return matchingStat ? matchingStat[valueFieldIndex] : 0;
          });

          const datasetLabel = buildGroupWithValueLabel(groupLabel, value);

          datasets.push(buildDataset(datasetLabel, data));
        });
      } else {
        const data = displayLabels.map((label, labelIndex) => {
          const rawLabel = rawLabels[labelIndex];
          const matchingStat = stats.find((stat) => stat[primaryIndex] === rawLabel);
          return matchingStat ? matchingStat[valueFieldIndex] : 0;
        });

        const { grouping, aggregator } = parseGroupAggregator(fields[valueFieldIndex]);
        const datasetLabel = buildGroupWithAggregatorLabel(grouping, aggregator);
        datasets.push(buildDataset(datasetLabel, data));
      }
      break;
    }
    case GROUP_BY_AGGREGATOR: {
      // Если группировка по одному агрегатору
      if (groupByAggregatorIndexes.length === 1) {
        const groupFieldIndex = groupByAggregatorIndexes[0];
        const data = displayLabels.map((label, labelIndex) => {
          const rawLabel = rawLabels[labelIndex];
          const matchingStat = stats.find((stat) => stat[groupFieldIndex] === rawLabel);
          return matchingStat ? matchingStat[valueFieldIndex] : 0;
        });

        const { grouping, aggregator } = parseGroupAggregator(fields[valueFieldIndex]);
        const datasetLabel = buildGroupWithAggregatorLabel(grouping, aggregator);

        datasets.push(buildDataset(datasetLabel, data));
        // Если группировка по двум агрегаторам
      } else if (groupByAggregatorIndexes.length === 2) {
        const [firstGroupIndex, secondGroupIndex] = groupByAggregatorIndexes;
        const secondaryGroups = [...new Set(stats.map((stat) => stat[secondGroupIndex]))];

        secondaryGroups.forEach((secondaryGroup) => {
          const data = displayLabels.map((label, labelIndex) => {
            const rawLabel = rawLabels[labelIndex];

            const matchingStat = stats.find(
              (stat) => stat[firstGroupIndex] === rawLabel
                && stat[secondGroupIndex] === secondaryGroup,
            );
            return matchingStat ? matchingStat[valueFieldIndex] : 0;
          });

          const groupLabel = fields[secondGroupIndex].split(',')[0].trim();
          const datasetLabel = buildGroupWithValueLabel(groupLabel, secondaryGroup);

          datasets.push(buildDataset(datasetLabel, data));
        });
      }
      break;
    }
    case 'default': {
      const data = stats.map((stat) => stat[0]);
      const { xAxisLabel } = getXAxisLabels(chartData);
      datasets.push(buildDataset(xAxisLabel, data));
      break;
    }
    default:
      break;
  }

  return datasets;
};

const processDataForChart = (chartData) => {
  if (!chartData) return null;

  const { labels, xAxisLabel } = getXAxisLabels(chartData);
  const datasets = generateDatasets(chartData, labels);

  return { labels: labels.displayLabels, datasets, xAxisLabel };
};

const useChart = (chartData, chartType = 'bar') => {
  const ChartComponent = useMemo(() => chartComponents[chartType] || Bar, [chartType]);

  const data = useMemo(() => processDataForChart(chartData), [chartData]);
  const options = useMemo(() => getOptionsForChart(chartType, data?.xAxisLabel), [data, chartType]);

  return <ChartComponent data={data} key={chartType} options={options} />;
};

export default useChart;
