import { createSlice } from '@reduxjs/toolkit';
import { apiContainer } from '@vlabs/api-bindings';
import { init as initLunaStreams } from '@vlabs/fsd-kit/entities/sources/luna-streams/store/streams';
import { bytesConverter } from '@vlabs/shared/utils';

import { fetchCurrentUser } from '@vlabs/pages/auth/store';
import { fetchDepartments } from '@vlabs/pages/departments/store';
import { connectAsyncEvents } from '@vlabs/pages/events/latest-events/store';
import { fetchHandlerList } from '@vlabs/pages/handlers/store';
import { fetchLists } from '@vlabs/pages/lists/store';
import { init as initVLAccess } from '@vlabs/pages/sources/vl-access/store';

import i18n from 'app/translations/i18n';

import packageInfo from '../../../package.json';
import { initialState } from './initialState';

const { version: clementineUIVersion } = packageInfo;

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateService(state, { payload: { service, ...attributes } }) {
      if (state.services[service] === undefined) state.services[service] = { name: service };
      state.services[service] = Object.assign(state.services[service], attributes);
    },
    updateState(state, { payload }) {
      state.state = payload;
    },
    setLPVersion(state, { payload: { major, minor } }) {
      state.lpVersion.major = major;
      state.lpVersion.minor = minor;
    },
    setConfig(state, { payload }) {
      state.config = {
        ...initialState.config,
        ...payload,
      };
      if (typeof payload.maxImageSize === 'string') {
        state.config.maxImageSize = bytesConverter(payload.maxImageSize);
      }
      if (payload.logoUrl) {
        document.documentElement.style.setProperty('--logo-url', `url(${payload.logoUrl})`);
        document.documentElement.style.setProperty('--dark-logo-url', `url(${payload?.darkLogoUrl || payload.logoUrl})`);
      }
    },
    // FIXME: DEPRECATED
    setLicense(state, { payload }) {
      state.license = payload;
    },
    setFeatures(state, { payload }) {
      state.features = payload;
    },
    setPlugins(state, { payload }) {
      state.plugins = payload.plugins;
    },
  },
});

export const { updateService, updateState, setLPVersion } = appSlice.actions;

export const fetchConfig = (dispatch) => {
  const configUrl = document.querySelector('link[rel="config"]').href;
  if (configUrl) {
    fetch(configUrl)
      .then((resp) => resp.json())
      .then((config) => dispatch(appSlice.actions.setConfig(config)));
  }
};

export const initApp = async (dispatch) => {
  const services = [];
  const sideeffects = [];

  async function loadService(service, { client, onSuccess }) {
    const title = i18n.t(`info:table.services.${service}`);

    try {
      dispatch(updateService({ service, title, state: 'loading' }));
      await client.healthcheck();
      if (client.init) await client.init();
      dispatch(updateService({ service, state: 'loaded' }));
      let version = '-';
      let versionNum = {};
      if (client.version) {
        version = await client.version();
        versionNum = version;
        if (typeof version === 'object') {
          if (version['LUNA PLATFORM']) {
            version = version['LUNA PLATFORM'];
            versionNum = { ...version };
          }
          if (version.major || version.minor || version.patch) {
            version = `${version.major}.${version.minor}.${version.patch}`;
          } else version = '-';
        }
      }

      let uiDocsURL;
      if (client.uiDocsURL) uiDocsURL = client.uiDocsURL;

      dispatch(updateService({ service, version, versionNum, uiDocsURL }));
      if (onSuccess) sideeffects.push(onSuccess());
    } catch (error) {
      console.error(`Failed to load service ${title}`, error);
      dispatch(updateService({ service, state: 'failed' }));
    }
  }

  dispatch(updateService({
    service: 'clementineUI',
    title: i18n.t('info:table.services.clementineUI'),
    version: clementineUIVersion,
    state: 'loaded',
  }));

  services.push(loadService('clementineAuth', {
    client: apiContainer.clementineAuthClient,
    onSuccess: () => dispatch(fetchCurrentUser),
  }));
  if (document.querySelector('link[rel="config"]').href) {
    dispatch(fetchConfig);
  }
  services.push(loadService('lunaStreams', {
    client: apiContainer.lunaStreamsClient,
    onSuccess: () => {
      dispatch(initLunaStreams);
    },
  }));
  services.push(loadService('vlAccess', {
    client: apiContainer.vlAccessClient,
    onSuccess: () => {
      dispatch(initVLAccess);
    },
  }));
  services.push(loadService('clementineDepartments', {
    client: apiContainer.clementineDepartmentsClient,
    onSuccess: () => dispatch(fetchDepartments),
  }));
  services.push(loadService('lunaPlatform', {
    client: apiContainer.lunaClient,
    onSuccess: () => {
      dispatch(fetchLists);
      dispatch(fetchHandlerList);
      apiContainer.lunaClient.features.get()
        .then(({ data }) => dispatch(appSlice.actions.setFeatures(data)));
      apiContainer.lunaClient.plugins.get()
        .then(({ data }) => dispatch(appSlice.actions.setPlugins(data)));
    },
  }));
  services.push(loadService('vlNotifier', {
    client: apiContainer.notifierClient,
  }));
  services.push(loadService('vlTimetracker', {
    client: apiContainer.timeTrackerClient,
  }));
  services.push(loadService('vaReporter', {
    client: apiContainer.vaReporterClient,
  }));

  await Promise.all(services);
  await Promise.all(sideeffects);

  dispatch(updateState('loaded'));
  dispatch(connectAsyncEvents());
};

export default appSlice.reducer;
