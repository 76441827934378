import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const ChevronDoubleUpIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="ChevronDoubleUpIcon" ref={iconRef} viewBox="0 0 24 24" {...props}>
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth} d="m17 11-5-5-5 5m10 7-5-5-5 5" />
    </IconInner>
  );
};

export const ChevronDoubleUpIcon = memo(ChevronDoubleUpIconInner);
