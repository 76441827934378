import React from 'react';

import validate from '@vlabs/shared/validators';
import { Control, Fold, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export function AutorestartSubForm() {
  const { t } = useTranslation();
  const { register, control, watch, formState: { errors } } = useFormContext();
  return (
    <Fold
      control={control}
      isOpen={watch('autorestart.restart')}
      name="autorestart.restart"
      switch
      testId="autorestart-fold"
      title={t('lunaStreamsStreams:форма.autorestart.restart')}
    >
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.autorestart.attempt count')}>
        <Control.Input
          {...register('autorestart.attempt_count', {
            min: validate.gte(1),
            max: validate.lte(32767),
            validate: validate.integer(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.autorestart.delay')}>
        <Control.Input
          {...register('autorestart.delay', {
            min: validate.gte(1),
            max: validate.lte(2147483647),
            validate: validate.integer(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
    </Fold>
  );
}
