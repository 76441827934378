import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import './LanguageSelector.sass';
import { Button } from '../controls/button/Button';

export const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const changeLanguage = useCallback((lng) => {
    history.push({
      ...history.location,
      search: `?lng=${lng}`,
    });
    history.go();
  }, [history]);

  return (
    <div>
      <Button
        kind="secondary"
        onClick={() => changeLanguage(i18n.language === 'en' ? 'ru' : 'en')}
        variant="flat"
      >
        {t('uikit:languageSelector.lang.ru')}
        <span className="LanguageSelector__Divider" />
        {t('uikit:languageSelector.lang.en')}
      </Button>
    </div>
  );
};
