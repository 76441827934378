import React, { useState } from 'react';

import { CrossIcon } from '@vlabs/icons';

import { Button } from '../components/controls/button/Button';
import { Modal } from '../components/modal/Modal';

export const useModal = ({ appElement, ...props } = {}) => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  return {
    isOpen,
    close,
    open,
    wrap: (component) => {
      return (
        <Modal
          appElement={appElement || document.getElementById('root')}
          isOpen={isOpen}
          onRequestClose={close}
          {...props}
        >
          <Button
            className="Modal__CloseButton"
            icon={<CrossIcon />}
            kind="secondary"
            onClick={() => close()}
            variant="flat"
          />
          {component}
        </Modal>
      );
    },
  };
};
