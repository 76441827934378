import PropTypes from 'prop-types';

const ButtonToggle = ({
  children,
}) => children;

ButtonToggle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  title: PropTypes.string,
  value: PropTypes.string,
};

ButtonToggle.defaultProps = {
  children: undefined,
  className: undefined,
  disabled: undefined,
  icon: undefined,
  title: undefined,
  value: undefined,
};

export {
  ButtonToggle,
};
