import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const FaceRecognitionIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="FaceRecognitionIcon" ref={iconRef} viewBox="0 0 50 50" {...props}>
      <path stroke="currentColor" strokeMiterlimit="10" strokeWidth={strokeWidth} d="M21 40h8m-7-6c.64.64 1.51 1 2.41 1h1.17c.91 0 1.77-.36 2.41-1M22 24l-1.28-.64c-.47-.24-1-.36-1.53-.36h-2.78c-.91 0-1.77.36-2.41 1m7 3h-5m12-3 1.28-.64c.47-.24 1-.36 1.53-.36h2.78c.91 0 1.77.36 2.41 1m-7 3h5m-1 17c-1.69 2.21-5.33 4-8 4s-6.31-1.79-8-4m24-28c-.51-3.01-1.92-6.32-4.27-9-4.39-5.01-9.4-5-11.73-5s-7.35-.01-11.73 5C10.92 9.68 9.51 12.99 9 16" /><path stroke="currentColor" strokeMiterlimit="10" strokeWidth={strokeWidth} d="M20.31 7.97c.09 3.14 6.77 6.09 11.7 8.31" /><path stroke="currentColor" strokeMiterlimit="10" strokeWidth={strokeWidth} d="M22.39 10.97c-1.35.49-5.01 2.46-7.39 4.54M8 26v-7h7M8 34v7h7m27-15v-7h-7m7 15v7h-7" />
    </IconInner>
  );
};

export const FaceRecognitionIcon = memo(FaceRecognitionIconInner);
