import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const ArrowStraightUpRightIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="ArrowStraightUpRightIcon" ref={iconRef} viewBox="0 0 24 24" {...props}>
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth} d="M7 17 17 7M7 7h10v10" />
    </IconInner>
  );
};

export const ArrowStraightUpRightIcon = memo(ArrowStraightUpRightIconInner);
