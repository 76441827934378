import React from 'react';

import validate from '@vlabs/shared/validators';
import { Control, Fold, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export function FilteringPolicySubForm() {
  const { t } = useTranslation();
  const { register, control, formState: { errors } } = useFormContext();

  return (
    <Fold isOpen title={t('lunaStreamsStreams:форма.filtering policy.заголовок')}>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.filtering policy.min_score')}>
        <Control.Input
          {...register('policies.filtering.min_score', {
            min: validate.gte(0),
            max: validate.lte(1),
            validate: validate.number(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.filtering policy.detection_yaw_threshold')}>
        <Control.Input
          {...register('policies.filtering.detection_yaw_threshold', {
            min: validate.gte(0),
            max: validate.lte(180),
            validate: validate.number(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.filtering policy.detection_pitch_threshold')}>
        <Control.Input
          {...register('policies.filtering.detection_pitch_threshold', {
            min: validate.gte(0),
            max: validate.lte(180),
            validate: validate.number(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.filtering policy.detection_roll_threshold')}>
        <Control.Input
          {...register('policies.filtering.detection_roll_threshold', {
            min: validate.gte(0),
            max: validate.lte(180),
            validate: validate.number(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.filtering policy.yaw_number')}>
        <Control.Input
          {...register('policies.filtering.yaw_number', {
            min: validate.gte(0),
            max: validate.lte(2147483647),
            validate: validate.integer(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.filtering policy.yaw_collection_mode')}>
        <Control.Switch control={control} name="policies.filtering.yaw_collection_mode" />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.filtering policy.mouth_occlusion_threshold')}>
        <Control.Input
          {...register('policies.filtering.mouth_occlusion_threshold', {
            min: validate.gte(0),
            max: validate.lte(1),
            validate: validate.number(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.filtering policy.min_body_size_threshold')}>
        <Control.Input
          {...register('policies.filtering.min_body_size_threshold', {
            min: validate.gte(0),
            max: validate.lte(2147483647),
            validate: validate.number(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
    </Fold>
  );
}
