/* eslint-disable no-restricted-globals */
import React from 'react';

import { LIVENESS_MODE_OPTIONS } from '@vlabs/api-bindings/src/luna-streams/constants';
import validate from '@vlabs/shared/validators';
import { Control, Fold, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const livenessWeightRegex = '(0|1|(?:0\\.[0-9]+))';
const livenessWeightsRegex = new RegExp(`^\\s*${livenessWeightRegex}\\s*,\\s*${livenessWeightRegex}\\s*,\\s*${livenessWeightRegex}\\s*$`);

export function LivenessPolicySubForm() {
  const { t } = useTranslation();
  const { register, control, formState: { errors } } = useFormContext();
  return (
    <Fold isOpen title={t('lunaStreamsStreams:форма.liveness policy.заголовок')}>
      <SettingsItemWrapper
        title={t('lunaStreamsStreams:форма.liveness policy.use_mask_liveness_filtration')}
      >
        <Control.Switch
          control={control}
          name="policies.liveness.use_mask_liveness_filtration"
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper
        title={t('lunaStreamsStreams:форма.liveness policy.use_flying_faces_liveness_filtration')}
      >
        <Control.Switch
          control={control}
          name="policies.liveness.use_flying_faces_liveness_filtration"
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper
        title={t('lunaStreamsStreams:форма.liveness policy.liveness_mode')}
      >
        <Control.Select
          control={control}
          name="policies.liveness.liveness_mode"
          options={LIVENESS_MODE_OPTIONS.raw}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper
        title={t('lunaStreamsStreams:форма.liveness policy.number_of_liveness_checks')}
      >
        <Control.Input
          {...register('policies.liveness.number_of_liveness_checks', {
            min: validate.gte(0),
            max: validate.lte(2147483647),
            validate: validate.integer(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper
        title={t('lunaStreamsStreams:форма.liveness policy.liveness_threshold')}
      >
        <Control.Input
          {...register('policies.liveness.liveness_threshold', {
            min: validate.gte(0),
            max: validate.lte(1),
            validate: validate.number(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper
        title={t('lunaStreamsStreams:форма.liveness policy.livenesses_weights.название')}
      >
        <Control.Input
          {...register('policies.liveness.livenesses_weights', {
            validate: validate.regex(livenessWeightsRegex, t('lunaStreamsStreams:форма.liveness policy.livenesses_weights.валидация')),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper
        title={t('lunaStreamsStreams:форма.liveness policy.mask_backgrounds_count')}
      >
        <Control.Input
          {...register('policies.liveness.mask_backgrounds_count', {
            min: validate.gte(0),
            max: validate.lte(2147483647),
            validate: validate.integer(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
    </Fold>
  );
}
