import React, { useState, useEffect } from 'react';

import { CameraOffIcon } from '@vlabs/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import st from './PreviewCell.module.sass';

const STREAM_REFRESH_TIMEOUT_MS = 10000;

function PreviewCell({ value }) {
  const { t } = useTranslation();
  const [isPreviewExists, setIsPreviewExists] = useState(!!value?.uiUrl);
  const [previewUrl, setPreviewUrl] = useState(value?.uiUrl);

  useEffect(() => {
    if (!value?.uiUrl || !isPreviewExists) return undefined;

    let time = 0;
    let timeout;
    let img;

    const controller = new AbortController();
    const { signal } = controller;

    const loadImage = async () => {
      const newUrl = `${value?.uiUrl}?t=${time}`;

      img = new Image();
      img.src = newUrl;

      img.onload = () => {
        if (signal.aborted) return;
        setPreviewUrl(newUrl);
        time += 1;
        timeout = setTimeout(loadImage, STREAM_REFRESH_TIMEOUT_MS);
      };
      img.onerror = () => {
        if (signal.aborted) return;

        timeout = setTimeout(loadImage, STREAM_REFRESH_TIMEOUT_MS);
      };
    };

    loadImage();

    return () => {
      controller.abort();
      clearTimeout(timeout);
      img.src = '';
    };
  }, [value, isPreviewExists]);

  if (!isPreviewExists) {
    return (
      <div
        className={cn(st.Preview, st.Preview_noPhoto)}
        data-testid="streamPreviewNoPhoto"
      >
        <CameraOffIcon />
        {t('превью.отсутствует')}
      </div>
    );
  }

  return (
    <img
      alt={t('превью.alt')}
      className={st.Preview}
      data-testid="streamPreview"
      onError={() => { setIsPreviewExists(false); }}
      src={previewUrl}
    />
  );
}

PreviewCell.propTypes = {
  value: PropTypes.shape({
    uiUrl: PropTypes.string,
  }),
};

PreviewCell.defaultProps = {
  value: undefined,
};

export { PreviewCell };
