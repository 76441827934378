import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '../../controls/button/Button';
import { Input } from '../../controls/input/Input';
import { Divider } from '../../divider/Divider';
import { regexp } from '../../utils/regexps';

import './LoginForm.sass';

const LoginForm = ({
  login,
  password,
  onSubmit,
  onForgotPassword,
  error,
  loginPlaceholder,
  useEmailAsLogin,
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      login,
      password,
    },
  });

  const $loginPlaceholder = loginPlaceholder || (
    useEmailAsLogin
      ? t('uikit:формы.поля.email')
      : t('uikit:формы.поля.логин')
  );
  const loginValidation = {
    required: t('uikit:валидация.required'),
  };

  if (useEmailAsLogin) {
    loginValidation.pattern = {
      value: regexp.email,
      message: t('uikit:валидация.email'),
    };
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error && (
        <div className="LoginForm__ErrorMessage Subtitle-2">
          {error}
        </div>
      )}

      <Input
        className="LoginForm__Input"
        data-testid="loginInput"
        errors={errors}
        placeholder={$loginPlaceholder}
        {...register('login', loginValidation)}
      />

      <Divider small />

      <Input
        data-testid="passwordInput"
        errors={errors}
        placeholder={t('uikit:формы.поля.пароль')}
        type="password"
        {...register('password', {
          required: t('uikit:валидация.required'),
        })}
      />

      <Divider small />

      <Button
        data-testid="submitButton"
        fullWidth
        type="submit"
      >
        {t('uikit:формы.действия.вход')}
      </Button>

      {onForgotPassword && (
        <div className="ForgotPassword">
          <Button
            kind="secondary"
            onClick={onForgotPassword}
            variant="flat"
          >
            {t('uikit:формы.действия.переход к восстановлению пароля')}
          </Button>
        </div>
      )}
    </form>
  );
};

LoginForm.propTypes = {
  login: PropTypes.string,
  password: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onForgotPassword: PropTypes.func,
  error: PropTypes.string,
  loginPlaceholder: PropTypes.string,
  useEmailAsLogin: PropTypes.bool,
};

LoginForm.defaultProps = {
  login: '',
  password: '',
  onForgotPassword: undefined,
  error: '',
  loginPlaceholder: '',
  useEmailAsLogin: undefined,
};

export { LoginForm };
