import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { ChevronDownIcon } from '@vlabs/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { Avatar } from '../avatar/Avatar';
import { AccountMenu } from './account-menu/AccountMenu';

import './Account.sass';

const Account = ({
  className,
  avatarUri,
  children,
  name,
  role,
  variant,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuItems = useMemo(() => React.Children.toArray(children), [children]);
  const hasMenuItems = useMemo(() => !!menuItems?.length, [menuItems]);

  const toggleMenu = useCallback((e) => {
    e.stopPropagation();
    setIsMenuOpen(hasMenuItems && !isMenuOpen);
  }, [hasMenuItems, isMenuOpen]);

  const closeMenu = () => { setIsMenuOpen(false); };

  useEffect(() => {
    document.removeEventListener('click', closeMenu);
    document.addEventListener('click', closeMenu);
    return () => { document.removeEventListener('click', closeMenu); };
  }, [isMenuOpen]);

  return (
    <div
      className={cn(
        className,
        'Account',
        `Account_${variant}`,
        {
          Account_noMenu: !hasMenuItems,
          InteractionWrapper: hasMenuItems,
          Account_isOpened: isMenuOpen,
        },
      )}
      data-testid="account"
    >
      <button
        className="Account__MenuToggler"
        data-testid="account.menuToggler"
        onClick={toggleMenu}
        type="button"
      >
        {(name || role) && (
          <div className="AccountInfo">
            {name && <span className="AccountInfo__Name">{name}</span>}
            {role && <span className="AccountInfo__Role">{role}</span>}
          </div>
        )}

        <Avatar
          className="Account__Avatar"
          photo={avatarUri}
          variant={variant}
        />

        <div className="Account__MenuTogglerIcon">
          <ChevronDownIcon />
        </div>
      </button>

      {menuItems && (
        <AccountMenu isOpened={isMenuOpen}>
          {menuItems}
        </AccountMenu>
      )}

      <div className="InteractionOverlay" />
    </div>
  );
};

Account.propTypes = {
  avatarUri: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
  role: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['round', 'square']),
};

Account.defaultProps = {
  avatarUri: undefined,
  children: undefined,
  name: undefined,
  role: undefined,
  className: undefined,
  variant: 'round',
};

export { Account };
