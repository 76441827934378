import React from 'react';

import PropTypes from 'prop-types';

import { BreadcrumbArrayPropType } from '../breadcrumbs/Breadcrumb';
import { Breadcrumbs } from '../breadcrumbs/Breadcrumbs';
import { BackButton } from '../controls/back-button/BackButton';
import { Margin } from '../margin/Margin';

import './PageHeader.sass';

const PageHeader = ({
  title,
  breadcrumbs,
  actions,
  status,
  tabs,
  onBackButtonClick,
}) => {
  if (!title && !breadcrumbs && !actions && !onBackButtonClick) return null;

  return (
    <>
      {breadcrumbs && (
        <Margin bottom>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Margin>
      )}

      <div className="PageHeaderWrapper">
        <div className="PageHeader">
          {onBackButtonClick && (
            <Margin right>
              <BackButton onClick={onBackButtonClick} />
            </Margin>
          )}
          <h1 className="PageHeaderTitle">
            {title}
          </h1>
          <div className="PageHeaderStatus">
            <Margin left>
              {status}
            </Margin>
          </div>
          <div className="PageHeaderTabs">
            <Margin left>
              {tabs}
            </Margin>
          </div>
        </div>

        <div className="PageHeaderActions">
          {actions}
        </div>
      </div>
    </>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  breadcrumbs: BreadcrumbArrayPropType,
  actions: PropTypes.node,
  status: PropTypes.node,
  tabs: PropTypes.node,
  onBackButtonClick: PropTypes.func,
};

PageHeader.defaultProps = {
  title: undefined,
  actions: undefined,
  onBackButtonClick: undefined,
  status: undefined,
  tabs: undefined,
  breadcrumbs: undefined,
};

export { PageHeader };
