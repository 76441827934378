import WSClient from '../WSClient';

export class LunaWSClient extends WSClient {
  constructor(baseURL, messageMapper = (data) => data, qsStringifyOptions = {}) {
    super(baseURL, qsStringifyOptions);
    this.messageMapper = messageMapper;
  }

  set onmessage(func) {
    this.socket.onmessage = (message) => func(this.messageMapper(JSON.parse(message.data)));
  }
}
