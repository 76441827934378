import React, { useEffect, useMemo } from 'react';

import { lunaStreamsConstants } from '@vlabs/api-bindings/src/luna-streams';
import validate from '@vlabs/shared/validators';
import { Control, Fold, SettingsItemWrapper } from '@vlabs/uikit';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export function DataSubForm() {
  const { t } = useTranslation();
  const {
    watch, getValues, reset, setValue,
    register, control, formState: { errors },
  } = useFormContext();

  const dataType = watch('data.type')?.value;

  useEffect(() => {
    if (!['tcp', 'udp'].includes(dataType)) {
      setValue('data.endless', undefined);
    }
  }, [dataType, setValue]);

  const isImageDataType = dataType === 'images';

  useEffect(() => {
    const sub = watch((_, { name }) => {
      if (name === 'data.type' && !isImageDataType) {
        const newValues = getValues();
        newValues.data.mask = '';

        reset(newValues);
      }
    });

    return () => sub.unsubscribe();
    // eslint-disable-next-line
  }, [watch]);

  const videoInfo = watch('video_info');
  const sourceDimensions = useMemo(() => {
    if (videoInfo?.width !== undefined && videoInfo?.height !== undefined) {
      return {
        width: videoInfo.width,
        height: videoInfo.height,
      };
    }

    return undefined;
  }, [videoInfo]);

  const regionValidator = (value) => {
    if (value === undefined) return true;

    const arrValue = [value.x, value.y, value.width, value.height];
    if (arrValue.every((v) => v === undefined)) return t('валидация.roi.abs');
    if (value.mode === 'abs') {
      const numberValidationResult = arrValue
        .map((v) => validate.integer(t('валидация.roi.abs'))(v))
        .reduce((p, v) => (v !== true ? v : p), true);
      if (numberValidationResult === true) {
        const rangeXParams = { left: 0, right: 65536, context: 'both' };
        const rangeYParams = { left: 0, right: 65536, context: 'both' };
        const rangeWidthParams = { left: 0, right: 65536, context: 'both' };
        const rangeHeightParams = { left: 0, right: 65536, context: 'both' };
        const rangeXValidation = validate.range(rangeXParams, t('lunaStreamsStreams:форма.data.ROI.CoordinatesInput.validation', { coord: '1 (x)', ...rangeXParams }))(value.x);
        if (rangeXValidation !== true) return rangeXValidation;
        const rangeYValidation = validate.range(rangeYParams, t('lunaStreamsStreams:форма.data.ROI.CoordinatesInput.validation', { coord: '2 (y)', ...rangeYParams }))(value.y);
        if (rangeYValidation !== true) return rangeYValidation;
        const rangeWidthValidation = validate.range(rangeWidthParams, t('lunaStreamsStreams:форма.data.ROI.CoordinatesInput.validation', { coord: '3 (width)', ...rangeWidthParams }))(value.width);
        if (rangeWidthValidation !== true) return rangeWidthValidation;
        const rangeHeightValidation = validate.range(rangeHeightParams, t('lunaStreamsStreams:форма.data.ROI.CoordinatesInput.validation', { coord: '4 (height)', ...rangeHeightParams }))(value.height);
        if (rangeHeightValidation !== true) return rangeHeightValidation;
      }
      return numberValidationResult;
    }
    if (value.mode === 'percent') {
      const numberValidationResult = arrValue
        .map((v) => validate.number(t('валидация.roi.percent'))(v))
        .reduce((p, v) => (v !== true ? v : p), true);
      if (numberValidationResult === true) {
        const rangeXParams = { left: 0, right: 100, context: 'both' };
        const rangeYParams = { left: 0, right: 100, context: 'both' };
        const rangeWidthParams = { left: 0.00001, right: 100, context: 'both' };
        const rangeHeightParams = { left: 0.00001, right: 100, context: 'both' };
        const rangeXValidation = validate.range(rangeXParams, t('lunaStreamsStreams:форма.data.ROI.CoordinatesInput.validation', { coord: '1 (x)', ...rangeXParams }))(value.x);
        if (rangeXValidation !== true) return rangeXValidation;
        const rangeYValidation = validate.range(rangeYParams, t('lunaStreamsStreams:форма.data.ROI.CoordinatesInput.validation', { coord: '2 (y)', ...rangeYParams }))(value.y);
        if (rangeYValidation !== true) return rangeYValidation;
        const rangeWidthValidation = validate.range(rangeWidthParams, t('lunaStreamsStreams:форма.data.ROI.CoordinatesInput.validation', { coord: '3 (width)', ...rangeWidthParams }))(value.width);
        if (rangeWidthValidation !== true) return rangeWidthValidation;
        const rangeHeightValidation = validate.range(rangeHeightParams, t('lunaStreamsStreams:форма.data.ROI.CoordinatesInput.validation', { coord: '4 (height)', ...rangeHeightParams }))(value.height);
        if (rangeHeightValidation !== true) return rangeHeightValidation;
      }
      return numberValidationResult;
    }
    return true;
  };

  return (
    <Fold isOpen title={t('lunaStreamsStreams:форма.data.заголовок')}>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.data.type')}>
        <Control.Select
          control={control}
          name="data.type"
          options={lunaStreamsConstants.STREAM_TYPE_OPTIONS.raw}
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.data.reference')}>
        <Control.Input
          errors={errors}
          {...register('data.reference', {
            required: validate.required(),
            maxLength: validate.maxLength(512),
          })}
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.data.ROI.label')}>
        <Controller
          control={control}
          name="data.roi"
          render={({ field: { value, onChange } }) => {
            return (
              <Control.CoordinatesInput
                errors={errors}
                name="data.roi"
                onChange={onChange}
                previewImage={watch('preview.last_frame.uiUrl')}
                sourceDimensions={sourceDimensions}
                texts={{
                  title: t('lunaStreamsStreams:форма.data.ROI.CoordinatesInput.Modal.title'),
                }}
                value={value}
              />
            );
          }}
          rules={{ validate: regionValidator }}
        />

      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.data.DROI.label')}>
        <Controller
          control={control}
          name="data.droi"
          render={({ field: { value, onChange } }) => {
            return (
              <Control.CoordinatesInput
                errors={errors}
                name="data.droi"
                onChange={onChange}
                previewImage={watch('preview.last_frame.uiUrl')}
                sourceDimensions={sourceDimensions}
                texts={{
                  title: t('lunaStreamsStreams:форма.data.ROI.CoordinatesInput.Modal.title'),
                }}
                value={value}
              />
            );
          }}
          rules={{ validate: regionValidator }}
        />

      </SettingsItemWrapper>

      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.data.rotation')}>
        <Control.Select
          control={control}
          name="data.rotation"
          options={lunaStreamsConstants.ROTATION_OPTIONS.raw}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.data.preferred_program_stream_frame_width')}>
        <Control.Input
          {...register('data.preferred_program_stream_frame_width', {
            min: validate.gte(0),
            max: validate.lte(32767),
            validate: validate.integer(),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>

      {isImageDataType && (
        <SettingsItemWrapper
          title={t('lunaStreamsStreams:форма.data.mask')}
        >
          <Control.Input
            errors={errors}
            {...register('data.mask', {
              required: validate.required(),
              maxLength: validate.maxLength(128),
            })}
          />
        </SettingsItemWrapper>
      )}

      {(dataType === 'tcp' || dataType === 'udp') && (
        <SettingsItemWrapper
          data-tooltip-content={t('lunaStreamsStreams:форма.data.endless.подсказка')}
          id="data.endless"
          title={t('lunaStreamsStreams:форма.data.endless.название')}
        >
          <Control.Switch
            control={control}
            name="data.endless"
          />
        </SettingsItemWrapper>
      )}
    </Fold>
  );
}
