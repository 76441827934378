import React from 'react';

import { AccountMenuLink, Tooltip } from '@vlabs/uikit';
import cn from 'classnames';
import i18n from 'i18next';
import './AccountMenuLinkWithPermissions.sass';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { viewerCan } from '@vlabs/pages/auth/selectors';

const AccountMenuLinkWithPermissionsComponent = ({
  children,
  permissions,
  can,
  ...props
}) => {
  const { rules = [], method = 'oneof' } = permissions;
  const hasPermission = can(rules, { method });

  return (
    <Tooltip
      data-tooltip-content={!hasPermission ? i18n.t('кнопка.недостаточно прав.title') : null}
    >
      <AccountMenuLink
        className={cn('AccountMenuLinkWithPermissions', {
          AccountMenuLink_disabled: !hasPermission,
        })}
        {...props}
      >
        {children}
      </AccountMenuLink>
    </Tooltip>
  );
};

AccountMenuLinkWithPermissionsComponent.propTypes = {
  children: PropTypes.node,
  permissions: PropTypes.shape({
    rules: PropTypes.arrayOf(PropTypes.string).isRequired,
    method: PropTypes.oneOf(['oneof', 'allof']),
  }),
  can: PropTypes.func.isRequired,
};

AccountMenuLinkWithPermissionsComponent.defaultProps = {
  children: undefined,
  permissions: {},
};

export const AccountMenuLinkWithPermissions = connect(
  (state) => ({
    can: viewerCan(state),
  }),
  () => ({}),
)(AccountMenuLinkWithPermissionsComponent);
