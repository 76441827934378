import React from 'react';

import { PageWithSidebar } from '@vlabs/shared/components/page-with-sidebar';
import { routes } from '@vlabs/shared/navigation/routes';
import { Page, Table, Panel, Control } from '@vlabs/uikit';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import { selectLatestEvents, selectLatestEventsFilters, selectSound, selectIsTopMatchHiding } from '@vlabs/pages/events/latest-events/selectors';
import { updateFilters, setParams, setIsTopMatchHiding } from '@vlabs/pages/events/latest-events/store';

import { useEventTableColumns } from '../eventTableColumns';
import FilterForm from './components/FilterForm';
import './LatestEventListPage.sass';

function LatestEventListPage({
  filters,
  setFilters,
  eventsData,
  sound,
  setIsSoundOn,
  setSoundThreshold,
  isTopMatchHiding,
  toggleTopMatchVisibility,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const formMethods = useForm({ defaultValues: filters });
  const { watch, handleSubmit } = formMethods;
  const filterFormFields = watch();
  const count = Object.values(filterFormFields).filter((value) => value?.length || value?.label?.length).length;

  const onEventCardClick = ({ event_id: eventId }) => {
    const eventLink = generatePath(routes.events.eventDetails, { eventId });
    history.push(eventLink);
  };

  const onTopMatchCardClick = ({ face_id, event_id }) => {
    if (face_id) {
      history.push(generatePath(routes.facePage, { faceId: face_id }));
    }
    if (event_id) {
      history.push(generatePath(routes.events.eventDetails, { eventId: event_id }));
    }
  };

  const renderCustomTopPanel = () => {
    return (
      <Control.Switch
        label={t('events:отображать наибольшее совпадение')}
        onChange={toggleTopMatchVisibility}
        value={!isTopMatchHiding}
      />
    );
  };
  const columns = useEventTableColumns(onTopMatchCardClick, onEventCardClick, isTopMatchHiding);

  const context = {
    ...formMethods,
    setFilters,
    sound,
    setIsSoundOn,
    setSoundThreshold,
  };

  return (
    <FormProvider {...context}>
      <form onSubmit={handleSubmit(setFilters)}>
        <PageWithSidebar
          filterCount={count}
          rigthSidebar={<FilterForm />}
        >
          <Page className="EventsTable__Page">
            <div className={cn('TableWrapper', 'Table_custom')}>
              <Panel className={cn('TableWrapper', 'Table_custom')}>
                <Table
                  columns={columns}
                  CustomTopPanel={renderCustomTopPanel}
                  data={eventsData.data}
                  pageIndex={eventsData.pageIndex}
                  pageSize={eventsData.pageSize}
                  pageSizeOptions={eventsData.pageSizeOptions}
                  rowProps={(row) => ({ 'data-event-id': row.original.event_id })}
                />
              </Panel>
              <div className="LatestEventListPage__ArchiveButton">
                <Control.Button
                  data-testid="eventsPage.goToArchiveButton"
                  onClick={() => history.push(generatePath(routes.events.archive))}
                  variant="outlined"
                >
                  {t('events:посмотреть')}
                </Control.Button>
              </div>
            </div>
          </Page>
        </PageWithSidebar>
      </form>
    </FormProvider>
  );
}

LatestEventListPage.propTypes = {
  eventsData: PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.any)]),
    pageSize: PropTypes.number,
    pageIndex: PropTypes.number,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  filters: PropTypes.objectOf(PropTypes.any),
  setFilters: PropTypes.func,
  sound: PropTypes.objectOf(PropTypes.any),
  setIsSoundOn: PropTypes.func,
  setSoundThreshold: PropTypes.func,
  isTopMatchHiding: PropTypes.bool,
  toggleTopMatchVisibility: PropTypes.func,
};
LatestEventListPage.defaultProps = {
  eventsData: {},
  filters: {},
  setFilters: undefined,
  sound: {},
  setIsSoundOn: undefined,
  setSoundThreshold: undefined,
  isTopMatchHiding: false,
  toggleTopMatchVisibility: undefined,
};

export default connect(
  (state) => ({
    eventsData: selectLatestEvents(state),
    filters: selectLatestEventsFilters(state),
    sound: selectSound(state),
    isTopMatchHiding: selectIsTopMatchHiding(state),
  }),
  (dispatch) => ({
    setIsSoundOn: (isSoundOn) => dispatch(setParams({ isSoundOn })),
    setSoundThreshold: (soundThreshold) => dispatch(setParams({ soundThreshold })),
    setFilters: (filters) => dispatch(updateFilters({ filters })),
    toggleTopMatchVisibility: () => dispatch(setIsTopMatchHiding()),
  }),
)(LatestEventListPage);
