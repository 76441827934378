import React, { useCallback } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import ListCreateForm from '@vlabs/pages/lists/forms/ListCreateForm';
import { ButtonWithPermissions } from '@vlabs/shared/components/button-with-permissions/ButtonWithPermissions';
import { permissions } from '@vlabs/shared/config';
import { useModal } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { fetchLists as fetchListsAction } from '../../store';

function CreateListWidget({ afterSubmit, updateListsStore }) {
  const { t } = useTranslation();
  const createListModal = useModal({ size: 'small' });

  const onSubmit = useCallback(async (value) => {
    if (!value) return;
    await apiContainer.lunaClient.lists.create(value);
    if (afterSubmit) afterSubmit();
    updateListsStore();
    createListModal.close();
    toast.success(t('lists:подтверждение.создание.успех', { list: value.user_data }));
  }, [afterSubmit, createListModal, t, updateListsStore]);

  return (
    <>
      {createListModal.wrap(
        <ListCreateForm
          onSubmit={onSubmit}
        />)}
      <ButtonWithPermissions
        data-testid="table.addButton"
        onClick={createListModal.open}
        permissions={{ rules: [permissions.list.creation] }}
      >
        {t('lists:widgets.create list widget.button.create button.label')}
      </ButtonWithPermissions>
    </>

  );
}

CreateListWidget.propTypes = {
  afterSubmit: PropTypes.func,
  updateListsStore: PropTypes.func.isRequired,
};

CreateListWidget.defaultProps = {
  afterSubmit: undefined,
};

const mapDispatchToProps = (dispatch) => ({
  updateListsStore: () => fetchListsAction(dispatch),
});

export default connect(
  null, mapDispatchToProps,
)(CreateListWidget);
