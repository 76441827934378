import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const ChevronDoubleLeftIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="ChevronDoubleLeftIcon" ref={iconRef} viewBox="0 0 24 24" {...props}>
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth} d="m11 17-5-5 5-5m7 10-5-5 5-5" />
    </IconInner>
  );
};

export const ChevronDoubleLeftIcon = memo(ChevronDoubleLeftIconInner);
