import { createSelector } from '@reduxjs/toolkit';

import { SLICE_NAME } from './constants';

// streams

export const selectSlice = ({ [SLICE_NAME]: streams }) => streams;
export const selectStreams = createSelector(
  selectSlice,
  ({ streams }) => streams,
);
export const selectState = createSelector(
  selectSlice,
  ({ state }) => state,
);
export const selectPageData = createSelector(
  selectSlice,
  (slice) => slice.pageData,
);
export const selectPageParameters = createSelector(
  selectSlice,
  (slice) => ({
    pageSizeOptions: slice.pageSizeOptions,
    pageSize: slice.pageSize,
    pageIndex: slice.pageIndex,
  }),
);
export const selectStreamOptions = createSelector(
  selectSlice,
  (slice) => (slice ? slice.streamOptions : []),
);
export const selectStreamsByGroupName = createSelector(
  selectStreams,
  (_, group_name) => group_name,
  (streams, group_name) => streams.filter(({ groups }) => groups.map(({ value }) => value).includes(group_name)),
);
export const selectStreamOptionsByGroupName = createSelector(
  selectStreamOptions,
  (_, group_name) => group_name,
  (streamOptions, group_name) => streamOptions.filter(({ groups }) => groups.includes(group_name)),
);

// groups

export const selectGroups = createSelector(
  selectSlice,
  ({ groups }) => groups,
);

export const selectGroupsPageData = createSelector(
  selectSlice,
  ({ groupsPageData }) => groupsPageData,
);

export const selectGroupsPageParameters = createSelector(
  selectSlice,
  (slice) => ({
    pageSizeOptions: slice.groupsPageSizeOptions,
    pageSize: slice.groupsPageSize,
    pageIndex: slice.groupsPageIndex,
  }),
);

export const selectGroupFilters = createSelector(
  selectSlice,
  ({ groupsFilters }) => groupsFilters,
);

export const selectGroupOptions = createSelector(
  selectSlice,
  ({ groupOptions }) => groupOptions,
);

export const selectGroupOptionsByAccountId = createSelector(
  selectGroupOptions,
  (_, accountId) => accountId,
  (options, accountId) => options.filter((option) => option.accountId === accountId),
);
