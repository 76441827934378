import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const FilesIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="FilesIcon" ref={iconRef} viewBox="0 0 42 42" {...props}>
      <path stroke="currentColor" strokeMiterlimit="10" strokeWidth={strokeWidth} d="M30.424 38.64H11.576V12.385h14.75l4.098 4.102V38.64Z" /><path stroke="currentColor" strokeMiterlimit="10" strokeWidth={strokeWidth} d="M28.785 17.308h-3.278v-3.282M9.937 33.717H4.2V7.462h13.112" /><path stroke="currentColor" strokeMiterlimit="10" strokeWidth={strokeWidth} d="M18.951 10.744V3.36h14.751L37.8 7.462v22.153h-5.737" /><path stroke="currentColor" strokeMiterlimit="10" strokeWidth={strokeWidth} d="M36.16 8.283h-3.277V5m-18.03 16.41h4.918m-4.917 3.281h4.917m-4.917 6.564h4.917m-4.917 3.282h4.917m-4.917-6.564h4.917m2.458-6.564h4.917m-4.917 3.282h4.917m-4.917 6.564h4.917m-4.917 3.282h4.917m-4.917-6.564h4.917" />
    </IconInner>
  );
};

export const FilesIcon = memo(FilesIconInner);
