import React, { useCallback } from 'react';

import { LockedIcon } from '@vlabs/icons';
import { Can } from '@vlabs/shared/components/can/Can';
import { permissions } from '@vlabs/shared/config';
import { calcTagType } from '@vlabs/shared/utils';
import cn from 'classnames';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import { EventTopMatchCell } from './EventTopMatchCell';
import { FaceTopMatchCell } from './FaceTopMatchCell';
import './TopMatchCell.sass';

const BlockWithNoPermission = () => {
  return (
    <div className="BlockWithNoPermission">
      <span>
        <LockedIcon className="StaticIcon" />
      </span>
      <span>{i18n.t('faces:недостаточно прав для просмотра лиц')}</span>
    </div>
  );
};

const TopMatchCellComponent = ({
  topMatch,
  onTopMatchCardClick,
  can,
}) => {
  const tagColor = calcTagType(topMatch?.similarity);

  const clickHandler = useCallback((e) => {
    if (!onTopMatchCardClick) return;
    if (e.code === 'Enter' || e.code === undefined) {
      onTopMatchCardClick(e);
    }
  }, [onTopMatchCardClick]);

  if (!topMatch) return null;
  const noPermissionToShowFace = topMatch.face_id && !can([permissions.face.view]);

  return (
    <div
      className={cn('TopMatch_wrapper', {
        [`TopMatchCard_color_${tagColor}`]: !noPermissionToShowFace,
      },
      )}
      data-testid="topMatchCell"
    >
      {topMatch?.face_id
        && (
          <Can
            no={<BlockWithNoPermission />}
            permissions={{ rules: [permissions.face.view] }}
            yes={<FaceTopMatchCell onClick={clickHandler} topMatch={topMatch} />}
          />
        )}
      {topMatch?.event_id && <EventTopMatchCell onClick={onTopMatchCardClick} topMatch={topMatch} />}
    </div>
  );
};

TopMatchCellComponent.propTypes = {
  topMatch: PropTypes.objectOf(PropTypes.any),
  onTopMatchCardClick: PropTypes.func,
  can: PropTypes.func.isRequired,
};

TopMatchCellComponent.defaultProps = {
  topMatch: undefined,
  onTopMatchCardClick: undefined,
};

export const TopMatchCell = connect((state) => ({
  can: viewerCan(state),
}))(TopMatchCellComponent);
