import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './Brand.sass';

const Brand = ({ className, variant, ...otherProps }) => {
  return (
    <Link
      className={cn(className, 'Brand', `Brand-${variant}`)}
      to="/"
      {...otherProps}
    />
  );
};

Brand.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'monochrome', 'monochromeOnlyName']),
};

Brand.defaultProps = {
  className: undefined,
  variant: 'default',
};

export { Brand };
