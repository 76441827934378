import React, { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import './Tag.sass';
import { Tooltip } from '../tooltip/Tooltip';

const Tag = ({
  value,
  kind,
  shape,
  className,
  'data-testid': testid,
  size,
  icon,
}) => {
  const valueRef = useRef();
  const [isEllipsis, setIsEllipsis] = useState(false);

  useEffect(() => {
    if (!valueRef.current) return;
    setIsEllipsis(valueRef.current.offsetWidth < valueRef.current.scrollWidth);
  }, [value]);

  const Element = isEllipsis ? Tooltip : 'div';

  return (
    <Element
      className={cn(
        className,
        'Tag',
        `Tag__size_${size}`,
        `Tag__kind_${kind}`,
        `Tag__shape_${shape}`,
        { Tag__withIcon: icon },
      )}
      data-testid={testid}
      data-tip={value}
      data-tooltip-delay-show={300}
    >
      <div className="Tag_content" ref={valueRef}>
        {value}
      </div>
      <div className="Tag_icon">
        {icon}
      </div>

    </Element>
  );
};

Tag.propTypes = {
  value: PropTypes.string.isRequired,
  kind: PropTypes.oneOf([
    'primary',
    'positive',
    'attention',
    'negative',
    'secondary',
  ]),
  shape: PropTypes.oneOf(['square', 'round']),
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  size: PropTypes.oneOf(['medium', 'large']),
  icon: PropTypes.node,
};

Tag.defaultProps = {
  kind: 'primary',
  className: undefined,
  shape: 'round',
  'data-testid': undefined,
  size: 'medium',
  icon: undefined,
};

export { Tag };
