import React from 'react';

import { selectStreamOptions } from '@vlabs/fsd-kit/entities/sources/luna-streams/store/streams';
import { permissions } from '@vlabs/shared/config';
import { routes } from '@vlabs/shared/navigation/routes';
import { Control } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import st from './StreamLink.module.sass';

const StreamLink = ({
  streamId,
  availableStreams,
  can,
}) => {
  const stream = availableStreams?.find((availableStream) => availableStream.streamId === streamId);

  if (!stream) return streamId;

  return (
    <div className={st.CamLinks}>
      <Control.Link
        data-testid={`CamLink-${stream.streamId}`}
        disabled={!can([permissions.source.view])}
        target="_blank"
        to={generatePath(routes.sources.lunaStreams.streams.view, { streamId: stream?.streamId })}
      >
        {stream.label}
      </Control.Link>
    </div>
  );
};

StreamLink.propTypes = {
  availableStreams: PropTypes.arrayOf(PropTypes.any),
  can: PropTypes.func.isRequired,
  streamId: PropTypes.string,
};

StreamLink.defaultProps = {
  availableStreams: [],
  streamId: undefined,
};

export default connect(
  (state) => ({
    availableStreams: selectStreamOptions(state),
    can: viewerCan(state),
  }),
)(StreamLink);
