import React from 'react';

import { EditIcon } from '@vlabs/icons';
import validate from '@vlabs/shared/validators';
import { Control, useModal, Divider } from '@vlabs/uikit';
import { useWatch, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import st from './GeolocationSubForm.module.sass';

const CoordinateDisplay = ({ label, value, delta }) => {
  const parsedValue = parseFloat(value);
  const parsedDelta = delta !== undefined && delta !== null ? parseFloat(delta) : null;

  return (
    <div className={st.CoordinateDisplay}>
      <span className={st.CoordinateDisplayLabel}>
        {label}
        :
      </span>
      <span className={st.CoordinateDisplayValue}>
        {Number.isInteger(parsedValue) ? parsedValue : parsedValue.toFixed(4)}
        °
        {!!parsedDelta && (
          <span>
            {' \u00B1 '}
            {Number.isInteger(parsedDelta) ? parsedDelta : parsedDelta.toFixed(2)}
            °
          </span>
        )}
      </span>
    </div>
  );
};

const Modal = ({ initialValues, onSubmit }) => {
  const { t } = useTranslation();
  const { handleSubmit: originalHandlerSubmit,
    register, formState: { errors } } = useForm({ defaultValues: initialValues });

  const longitude = useWatch('origin_longitude');
  const longitudelDelta = useWatch('longitude_delta');
  const latitude = useWatch('origin_latitude');
  const latitudeDelta = useWatch('latitude_delta');

  const handleSubmit = (func) => (e) => {
    if (e?.preventDefault) e.preventDefault();
    if (e?.stopPropagation) e.stopPropagation();
    originalHandlerSubmit(func)(e);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <h5>{t('statistic:form.geolocation.modal.title')}</h5>
        <Divider small />
        <div className={st.FormRow}>
          <div className={st.InputContainer}>
            <Control.Input
              label={t('долгота (-180...180)')}
              {...register('origin_longitude', {
                required: validate.required(),
                min: validate.gte(-180),
                max: validate.lte(180),
                validate: {
                  number: validate.number(),
                  validateLatitude: (value) => {
                    if (!value && latitude) {
                      return validate.required();
                    }
                    return true;
                  },
                  validateDelta: (value) => {
                    if (!value && longitudelDelta) {
                      return validate.required();
                    }
                    return true;
                  },
                },
              })}
              errors={errors}
            />
          </div>
          <Divider small />
          <div className={st.InputContainer}>
            <Control.Input
              id="longitude_delta"
              label={t('погрешность (0...90)')}
              {...register('longitude_delta', {
                min: validate.gte(0),
                max: validate.lte(90),
                validate: validate.number(),
              })}
              errors={errors}
            />
          </div>
        </div>
        <div className={st.FormRow}>
          <div className={st.InputContainer}>
            <Control.Input
              id="origin_latitude"
              label={t('широта (-90...90)')}
              {...register('origin_latitude', {
                min: validate.gte(-90),
                max: validate.lte(90),
                required: validate.required(),
                validate: {
                  number: validate.number(),
                  validateLongitude: (value) => {
                    if (!value && longitude) {
                      return validate.required();
                    }
                    return true;
                  },
                  validateDelta: (value) => {
                    if (!value && latitudeDelta) {
                      return validate.required();
                    }
                    return true;
                  },
                },
              })}
              errors={errors}
            />
          </div>
          <Divider small />
          <div className={st.InputContainer}>
            <Control.Input
              id="latitude_delta"
              label={t('погрешность (0...90)')}
              {...register('latitude_delta', {
                min: validate.gte(0),
                max: validate.lte(90),
                validate: validate.number(),
              })}
              errors={errors}
            />
          </div>
        </div>
        <Divider small />

        <Control.Button fullWidth type="submit">
          {t('форма.submit')}
        </Control.Button>
      </div>
    </form>
  );
};

export const GeolocationSubForm = ({ disabled, initialValues, onSubmit }) => {
  const { t } = useTranslation();
  const modal = useModal();

  const handleSave = (value) => {
    onSubmit(value);
    modal.close();
  };

  return (
    <div>
      {modal.wrap(<Modal initialValues={initialValues} onSubmit={handleSave} />)}
      {!initialValues && (
        <Control.Button
          disabled={disabled}
          fullWidth
          onClick={modal.open}
          variant="outlined"
        >
          Задать область
        </Control.Button>
      )}
      {initialValues && initialValues?.origin_longitude && (
        <div className={st.CoordinateRoot}>
          <div>
            <CoordinateDisplay delta={initialValues?.longitude_delta} label={t('statistic:form.geolocation.modal.longitude.label')} value={initialValues?.origin_longitude} />
            <CoordinateDisplay delta={initialValues?.latitude_delta} label={t('statistic:form.geolocation.modal.latitude.label')} value={initialValues?.origin_latitude} />
          </div>

          <Control.RoundButton
            icon={<EditIcon />}
            kind="secondary"
            onClick={modal.open}
            // FIXME: нужно пофиксиь копки, чтобы убрать этот стиль
            style={{ gap: 0 }}
          />
        </div>
      )}
    </div>
  );
};
