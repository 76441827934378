import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const ZapIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="ZapIcon" ref={iconRef} viewBox="0 0 24 24" {...props}>
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth} d="M13 2 3 14h9l-1 8 10-12h-9l1-8Z" />
    </IconInner>
  );
};

export const ZapIcon = memo(ZapIconInner);
