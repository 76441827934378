import React from 'react';

import validate from '@vlabs/shared/validators';
import { Control, Fold, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export function LocationSubForm() {
  const { t } = useTranslation();
  const { watch, register, formState: { errors } } = useFormContext();

  const longitude = watch('location.geo_position.longitude');
  const latitude = watch('location.geo_position.latitude');

  return (
    <Fold isOpen title={t('lunaStreamsStreams:форма.location.заголовок')}>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.location.city')}>
        <Control.Input
          {...register('location.city', {
            maxLength: validate.maxLength(36),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.location.area')}>
        <Control.Input
          {...register('location.area', {
            maxLength: validate.maxLength(36),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.location.district')}>
        <Control.Input
          {...register('location.district', {
            maxLength: validate.maxLength(36),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.location.street')}>
        <Control.Input
          {...register('location.street', {
            maxLength: validate.maxLength(36),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.location.house_number')}>
        <Control.Input
          {...register('location.house_number', {
            maxLength: validate.maxLength(36),
          })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.location.longitude')}>
        <Control.Input
          {...register('location.geo_position.longitude', {
            min: validate.gte(-180),
            max: validate.lte(180),
            validate: {
              number: validate.number(),
              latitudeIsRequired: (value) => {
                if (!value && latitude) {
                  return validate.required();
                }
                return true;
              },
            } })}
          errors={errors}
        />
      </SettingsItemWrapper>
      <SettingsItemWrapper title={t('lunaStreamsStreams:форма.location.latitude')}>
        <Control.Input
          {...register('location.geo_position.latitude', {
            min: validate.gte(-90),
            max: validate.lte(90),
            validate: {
              number: validate.number(),
              longitudeIsRequired: (value) => {
                if (!value && longitude) {
                  return validate.required();
                }
                return true;
              },
            } })}
          errors={errors}
        />
      </SettingsItemWrapper>
    </Fold>
  );
}
