import React from 'react';

import {
  LoginPage,
  ResetPasswordPage,
} from '@vlabs/pages/auth';
import { ThemeProvider } from '@vlabs/shared/context/ThemeContext';
import { ScrollToTop } from '@vlabs/shared/utils';
import { Layout } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { selectIsAuthenticated } from '@vlabs/pages/auth/selectors';

import Navigation from './Navigation';
import Routes from './router';

function App({
  isAuthenticated,
}) {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Layout.Base>
          <ScrollToTop />

          {!isAuthenticated && (
            <Switch>
              <Route component={ResetPasswordPage} path="/auth/reset/:token" />
              <Route component={LoginPage} path="*" />
            </Switch>
          )}

          {isAuthenticated && (
            <>
              <Navigation />
              <Layout.Content>
                <Routes />
              </Layout.Content>
            </>
          )}
        </Layout.Base>
      </BrowserRouter>
    </ThemeProvider>
  );
}

App.propTypes = {
  isAuthenticated: PropTypes.bool,
};

App.defaultProps = {
  isAuthenticated: false,
};

export default connect((state) => ({
  isAuthenticated: selectIsAuthenticated(state),
}))(App);
