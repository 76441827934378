import { CleanSchema, get } from '../../../json-transform';

export const getGeneralWsEventsQS = new CleanSchema({
  'event.people_count__gte:integer': get('event.people_count__gte:integer').asInt().ifNaN(undefined),
  'event.stream_id__in:string': get('event.stream_id__in:string').asArrayOfOptionValues().ifEmpty(undefined),
  'event.event.stream_id__in:string': get('event.event.stream_id__in:string').asArrayOfOptionValues().ifEmpty(undefined),
  'event.event.fight_score__gte:numeric': get('event.event.fight_score__gte:numeric').asFloat().toNormalizedPercent().ifNaN(undefined),
  'event.event.weapon_score__gte:numeric': get('event.event.weapon_score__gte:numeric').asFloat().toNormalizedPercent().ifNaN(undefined),
  'event.event.fire_score__gte:numeric': get('event.event.fire_score__gte:numeric').asFloat().toNormalizedPercent().ifNaN(undefined),
});
