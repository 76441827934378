import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const TagIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="TagIcon" ref={iconRef} viewBox="0 0 24 24" {...props}>
      <path d="M7 7H7.01M21.0099 13.8299L13.8399 20.9999C13.6542 21.1859 13.4336 21.3334 13.1908 21.434C12.948 21.5347 12.6878 21.5865 12.4249 21.5865C12.1621 21.5865 11.9018 21.5347 11.659 21.434C11.4162 21.3334 11.1957 21.1859 11.0099 20.9999L3.00653 13.0058C2.63096 12.6307 2.41992 12.1216 2.41992 11.5908V4.41992C2.41992 3.31535 3.31535 2.41992 4.41992 2.41992H11.5915C12.1219 2.41992 12.6306 2.63064 13.0057 3.00571L21.0099 11.0099C21.3824 11.3846 21.5915 11.8916 21.5915 12.4199C21.5915 12.9483 21.3824 13.4552 21.0099 13.8299ZM6.99834 6.5C6.72311 6.5 6.5 6.72386 6.5 7C6.5 7.27614 6.72311 7.5 6.99834 7.5H7.00166C7.27689 7.5 7.5 7.27614 7.5 7C7.5 6.72386 7.27689 6.5 7.00166 6.5H6.99834Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    </IconInner>
  );
};

export const TagIcon = memo(TagIconInner);
