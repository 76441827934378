import FileSaver from 'file-saver';

export const downloadImage = (imgURL) => {
  const filename = `${imgURL.split('/').slice(-1)[0]}.jpg`;

  fetch(imgURL)
    .then((resp) => resp.blob())
    .then((blob) => {
      FileSaver.saveAs(blob, filename);
    });
};
