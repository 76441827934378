import { optionsProxy } from '@vlabs/uikit';
import i18next from 'i18next';

export const chartTypeOptions = optionsProxy.create([
  { label: i18next.t('statistic:options.chart type.age distribution'), value: 'ageDistribution' },
  { label: i18next.t('statistic:options.chart type.gender ratio'), value: 'genderRatio' },
  { label: i18next.t('statistic:options.chart type.attendance by gender'), value: 'attendanceByGender' },
  { label: i18next.t('statistic:options.chart type.gender distribution'), value: 'genderDistribution' },
  { label: i18next.t('statistic:options.chart type.emotion distribution'), value: 'emotionDistribution' },
  { label: i18next.t('statistic:options.chart type.average age by gender'), value: 'averageAgeByGender' },
]);

export const diagramTypeOptions = optionsProxy.create([
  { label: i18next.t('statistic:options.diagram type.bar'), value: 'bar' },
  { label: i18next.t('statistic:options.diagram type.horizontal bar'), value: 'horizontalBar' },
  { label: i18next.t('statistic:options.diagram type.area'), value: 'area' },
  { label: i18next.t('statistic:options.diagram type.step area'), value: 'stepArea' },
  { label: i18next.t('statistic:options.diagram type.stack'), value: 'stack' },
  { label: i18next.t('statistic:options.diagram type.line'), value: 'line' },
  { label: i18next.t('statistic:options.diagram type.pie'), value: 'pie' },
  { label: i18next.t('statistic:options.diagram type.doughnut'), value: 'doughnut' },
  { label: i18next.t('statistic:options.diagram type.radar'), value: 'radar' },
]);

export const aggregatorOptions = optionsProxy.create([
  { label: i18next.t('statistic:options.aggregator.count'), value: 'count' },
  { label: i18next.t('statistic:options.aggregator.max'), value: 'max' },
  { label: i18next.t('statistic:options.aggregator.min'), value: 'min' },
  { label: i18next.t('statistic:options.aggregator.avg'), value: 'avg' },
]);

export const timeGroupingTypeOptions = optionsProxy.create([
  { label: i18next.t('statistic:options.time grouping.interval'), value: 'interval' },
  { label: i18next.t('statistic:options.time grouping.frequency'), value: 'frequency' },
]);

export const timeDimensionOptions = optionsProxy.create([
  { label: i18next.t('statistic:options.time dimension.minute'), value: 'm' },
  { label: i18next.t('statistic:options.time dimension.hour'), value: 'h' },
  { label: i18next.t('statistic:options.time dimension.day'), value: 'd' },
  { label: i18next.t('statistic:options.time dimension.week'), value: 'w' },
  { label: i18next.t('statistic:options.time dimension.month'), value: 'M' },
  { label: i18next.t('statistic:options.time dimension.year'), value: 'y' },
]);

export const frequencyOptions = optionsProxy.create([
  { label: i18next.t('statistic:options.frequency.month of year'), value: 'monthOfYear' },
  { label: i18next.t('statistic:options.frequency.week of year'), value: 'weekOfYear' },
  { label: i18next.t('statistic:options.frequency.day of year'), value: 'dayOfYear' },
  { label: i18next.t('statistic:options.frequency.day of month'), value: 'dayOfMonth' },
  { label: i18next.t('statistic:options.frequency.day of week'), value: 'dayOfWeek' },
  { label: i18next.t('statistic:options.frequency.hour of day'), value: 'hourOfDay' },
  { label: i18next.t('statistic:options.frequency.minute of hour'), value: 'minuteOfHour' },
]);

export const filterFunctionOptions = optionsProxy.create([
  { label: i18next.t('statistic:options.filter function.like'), value: 'like' },
  { label: i18next.t('statistic:options.filter function.nlike'), value: 'nlike' },
  { label: i18next.t('statistic:options.filter function.in'), value: 'in' },
  { label: i18next.t('statistic:options.filter function.nin'), value: 'nin' },
  { label: i18next.t('statistic:options.filter function.eq'), value: 'eq' },
  { label: i18next.t('statistic:options.filter function.neq'), value: 'neq' },
  { label: i18next.t('statistic:options.filter function.gt'), value: 'gt' },
  { label: i18next.t('statistic:options.filter function.gte'), value: 'gte' },
  { label: i18next.t('statistic:options.filter function.lt'), value: 'lt' },
  { label: i18next.t('statistic:options.filter function.lte'), value: 'lte' },
  { label: i18next.t('statistic:options.filter function.covered'), value: 'covered' },
]);

export const filterFieldOptions = optionsProxy.create([
  { label: i18next.t('statistic:options.filter field.tags'), value: 'tags' },
  { label: i18next.t('statistic:options.filter field.source'), value: 'source' },
  { label: i18next.t('statistic:options.filter field.stream id'), value: 'stream_id' },
  { label: i18next.t('statistic:options.filter field.user data'), value: 'user_data' },
  { label: i18next.t('statistic:options.filter field.event id'), value: 'event_id' },
  { label: i18next.t('statistic:options.filter field.external id'), value: 'external_id' },
  { label: i18next.t('statistic:options.filter field.handler id'), value: 'handler_id' },
  { label: i18next.t('statistic:options.filter field.top similar object id'), value: 'top_similar_object_id' },
  { label: i18next.t('statistic:options.filter field.top similar external id'), value: 'top_similar_external_id' },
  { label: i18next.t('statistic:options.filter field.top matching candidates label'), value: 'top_matching_candidates_label' },
  { label: i18next.t('statistic:options.filter field.top similar object similarity'), value: 'top_similar_object_similarity' },
  { label: i18next.t('statistic:options.filter field.face id'), value: 'face_id' },
  { label: i18next.t('statistic:options.filter field.gender'), value: 'gender' },
  { label: i18next.t('statistic:options.filter field.age'), value: 'age' },
  { label: i18next.t('statistic:options.filter field.emotion'), value: 'emotion' },
  { label: i18next.t('statistic:options.filter field.mask'), value: 'mask' },
  { label: i18next.t('statistic:options.filter field.liveness'), value: 'liveness' },
  { label: i18next.t('statistic:options.filter field.deepfake'), value: 'deepfake' },
  { label: i18next.t('statistic:options.filter field.city'), value: 'city' },
  { label: i18next.t('statistic:options.filter field.area'), value: 'area' },
  { label: i18next.t('statistic:options.filter field.district'), value: 'district' },
  { label: i18next.t('statistic:options.filter field.street'), value: 'street' },
  { label: i18next.t('statistic:options.filter field.house number'), value: 'house_number' },
  { label: i18next.t('statistic:options.filter field.geo position'), value: 'geo_position' },
  { label: i18next.t('statistic:options.filter field.track id'), value: 'track_id' },
  { label: i18next.t('statistic:options.filter field.apparent age'), value: 'apparent_age' },
  { label: i18next.t('statistic:options.filter field.apparent gender'), value: 'apparent_gender' },
  { label: i18next.t('statistic:options.filter field.sleeve length'), value: 'sleeve_length' },
  { label: i18next.t('statistic:options.filter field.headwear state'), value: 'headwear_state' },
  { label: i18next.t('statistic:options.filter field.headwear apparent color'), value: 'headwear_apparent_color' },
  { label: i18next.t('statistic:options.filter field.upper clothing colors'), value: 'upper_clothing_colors' },
  { label: i18next.t('statistic:options.filter field.lower garment colors'), value: 'lower_garment_colors' },
  { label: i18next.t('statistic:options.filter field.lower garment type'), value: 'lower_garment_type' },
  { label: i18next.t('statistic:options.filter field.shoes apparent color'), value: 'shoes_apparent_color' },
  { label: i18next.t('statistic:options.filter field.backpack state'), value: 'backpack_state' },
  { label: i18next.t('statistic:options.filter field.meta'), value: 'meta' },
]);

export const targetOptions = optionsProxy.create([
  ...filterFieldOptions.raw,
  { label: i18next.t('statistic:options.filter field.create time'), value: 'create_time' },
  { label: i18next.t('statistic:options.filter field.end time'), value: 'end_time' },
]);

const withoutGroupBy = [
  'top_similar_object_similarity',
  'create_time',
  'end_time',
  'tags',
  'geo_position',
  'upper_clothing_colors',
  'lower_garment_colors',
  'meta',
];

export const targetForGroupByAggregatorOptions = optionsProxy.create(
  targetOptions.raw.filter((option) => !withoutGroupBy.includes(option.value)),
);
