import React, { forwardRef, useRef, useState } from 'react';

import { CrossIcon, EyeIcon, EyeOffIcon } from '@vlabs/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { mergeRefs } from '../../utils/mergeRefs';
import { RoundButton } from '../button/RoundButton';
import { ErrorMessage } from '../error-message/ErrorMessage';
import { getError, warnLabelWithoutId } from '../utils';

import './Input.sass';

const Input = forwardRef(({
  id,
  errors,
  hasError,
  errorMessage,
  icon,
  inputStyle,
  isClearable,
  onClear,
  disabled,
  className,
  onChange,
  onChangeValue,
  name,
  label,
  type,
  value,
  ...props
}, externalRef) => {
  const internalRef = useRef(null);
  const [isEmpty, setIsEmpty] = useState(!value);
  const [isHidden, setIsHidden] = useState(true);

  const internalType = isHidden ? 'password' : 'text';
  const isMasked = type === 'password';
  const error = getError(errors, name);

  const $onClear = () => {
    if (internalRef.current) {
      internalRef.current.value = '';
    }
    if (onClear) onClear();
    setIsEmpty(true);
  };

  const $onChange = (e) => {
    e.stopPropagation();
    if (onChange) onChange(e);
    if (onChangeValue) onChangeValue(e.target.value);
    setIsEmpty(!e.target.value);
  };

  const $onHideToggle = () => {
    setIsHidden(!isHidden);
  };

  if (label && !id) {
    warnLabelWithoutId(label, name);
  }

  const ToggleIcon = isHidden ? EyeIcon : EyeOffIcon;

  return (
    <>
      {label && (
        <label
          className="Input__Label"
          htmlFor={id}
        >
          {label}
        </label>
      )}

      <div
        className={cn(
          className,
          'InteractionWrapper',
          {
            InputField: true,
            InputField_disabled: disabled,
            InputField_error: hasError || error,
            InputField_withIcon: icon,
            [`InputField_style-${inputStyle}`]: inputStyle,
          },
        )}
        data-testid="inputRoot"
      >
        {/* <div className="InputField__Overlay InteractionOverlay" /> */}

        {icon && (
          <div className="InputField__Icon">
            {icon}
          </div>
        )}

        <input
          className={cn('InputField__Input', isMasked && 'InputField__Input_withButton')}
          data-testid="input"
          disabled={disabled}
          id={id}
          name={name}
          onChange={$onChange}
          ref={mergeRefs(internalRef, externalRef)}
          type={isMasked ? internalType : type}
          value={value}
          {...props}
        />

        {isClearable && !disabled && !isEmpty && !isMasked && (
          <RoundButton
            className="InputField__Clear"
            data-testid="clearButton"
            icon={<CrossIcon />}
            kind="secondary"
            onClick={$onClear}
            variant="flat"
          />
        )}

        {isMasked && (
          <RoundButton
            className="InputField__Hide"
            data-testid="hideButton"
            disabled={disabled}
            icon={<ToggleIcon />}
            kind="secondary"
            onClick={$onHideToggle}
            variant="invisible"
          />
        )}

        <div className="InteractionOverlay" />
      </div>

      <ErrorMessage error={errorMessage ? { message: errorMessage } : error} />
    </>
  );
});

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  errors: PropTypes.object,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  icon: PropTypes.node,
  inputStyle: PropTypes.oneOf(['default', 'implicit']),
  disabled: PropTypes.bool,
  onClear: PropTypes.func,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeValue: PropTypes.func,
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'email']),
  value: PropTypes.string,
};

Input.defaultProps = {
  id: undefined,
  name: undefined,
  className: undefined,
  errors: undefined,
  hasError: undefined,
  errorMessage: undefined,
  icon: undefined,
  inputStyle: 'default',
  disabled: false,
  onClear: undefined,
  isClearable: false,
  onChange: undefined,
  onChangeValue: undefined,
  label: undefined,
  type: 'text',
  value: undefined,
};

Input.displayName = 'Input';

export { Input };
