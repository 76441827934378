import React, { forwardRef } from 'react';

const IconInner = forwardRef(({
  name,
  viewBox,
  ...restProps
}, iconRef) => {
  const [, , width, height] = (viewBox || '0 0 24 24').split(' ');

  return (
    <svg
      aria-label={name}
      data-testid={name}
      fill="none"
      height={height}
      ref={iconRef}
      viewBox={viewBox}
      width={width}
      {...restProps}
    />
  );
});

IconInner.displayName = 'Icon';

export { IconInner };
