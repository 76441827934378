import React, { useState, useEffect, useMemo } from 'react';

import { Can } from '@vlabs/shared/components/can/Can';
import { EmptyImage } from '@vlabs/shared/components/empty-image';
import { SampleWithoutPermission } from '@vlabs/shared/components/sample/SampleWithoutPermission';
import { permissions } from '@vlabs/shared/config';
import Loader from '@vlabs/shared/legacy-components/loader/Loader';
import cn from 'classnames';
import PropTypes from 'prop-types';

import st from './BaseSample.module.sass';

export const BaseSample = ({
  imageUrl,
  dataTip,
  className,
  emptyClassName,
  type,
  permissions: defaultPermissions,
  'data-testid': testId,
}) => {
  const { rules, method = 'oneof' } = defaultPermissions || {};

  const [state, setState] = useState({ isEmpty: true, isLoading: true });
  const permissionByType = useMemo(() => {
    const permissionMap = {
      face: [permissions.faceSample.view],
      body: [permissions.bodySample.view],
    };

    return permissionMap[type] || [];
  }, [type]);

  useEffect(() => {
    if (imageUrl) setState((prev) => ({ ...prev, isEmpty: false }));
  }, [imageUrl]);

  return (
    <Can
      no={<SampleWithoutPermission className={className} type={type} />}
      permissions={{ rules: rules || permissionByType, method }}
      yes={(
        <div className={cn(st.BaseSample, st[`BaseSample_${type}`], className)}>
          {state.isEmpty && (
          <EmptyImage
            className={cn(st.BaseSample__EmptyImage, emptyClassName)}
            dataTip={dataTip}
          />
          )}
          {!state.isEmpty && (
            <>
              <img
                alt={testId}
                className={cn(st.BaseSample__Image, st[`BaseSample__Image_${type}`])}
                data-testid={testId}
                onError={() => setState((prev) => ({ ...prev, isEmpty: true, isLoading: false }))}
                onLoad={() => setState((prev) => ({ ...prev, isLoading: false }))}
                src={imageUrl}
              />
              {state.isLoading && (
                <div className={cn(st.BaseSample__Loader, st[`BaseSample__Loader_${type}`], className)}>
                  <Loader />
                </div>
              )}
            </>
          )}
        </div>
      )}
    />
  );
};

BaseSample.propTypes = {
  imageUrl: PropTypes.string,
  className: PropTypes.string,
  emptyClassName: PropTypes.string,
  type: PropTypes.oneOf(['face', 'body']),
  dataTip: PropTypes.string,
  'data-testid': PropTypes.string,
  permissions: PropTypes.shape({
    rules: PropTypes.arrayOf(PropTypes.string),
    method: PropTypes.oneOf(['oneof', 'allof']),
  }),
};

BaseSample.defaultProps = {
  imageUrl: undefined,
  className: undefined,
  emptyClassName: undefined,
  type: undefined,
  dataTip: undefined,
  'data-testid': undefined,
  permissions: {},
};
