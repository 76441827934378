import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const EyeIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="EyeIcon" ref={iconRef} viewBox="0 0 24 24" {...props}>
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth} d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8Z" /><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth} d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
    </IconInner>
  );
};

export const EyeIcon = memo(EyeIconInner);
