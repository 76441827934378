import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const ArrowStraightUpDownIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="ArrowStraightUpDownIcon" ref={iconRef} viewBox="0 0 24 24" {...props}>
      <path d="M4 10L8 6M8 6L12 10M8 6L8 18M12 14L16 18M16 18L20 14M16 18L16 6" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    </IconInner>
  );
};

export const ArrowStraightUpDownIcon = memo(ArrowStraightUpDownIconInner);
