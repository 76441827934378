import {
  AGE_OPTIONS,
  EMOTION_OPTIONS,
  ETHNIC_OPTIONS,
  EVENT_OPTIONS,
  GENDER_OPTIONS,
  MASK_OPTIONS,
  MULTIFACE_POLICY_OPTIONS,
  DESCRIPTOR_TYPE_OPTIONS,
  DEEPFAKE_STATE_OPTIONS_STRICT,
  DEEPFAKE_MODE_OPTIONS,
} from '../../../../constants';
import { Schema, get } from '../../../../json-transform';
import {
  LIVENESS_OPTIONS,
  EVENT_ENUMS,
} from '../../../constants';
import {
  FACE_QUALITY_EYE,
  FACE_QUALITY_EYEBROWS_STATE,
  FACE_QUALITY_FACE_COLOR_TYPES,
  FACE_QUALITY_GLASSES,
  FACE_QUALITY_HEADWEAR_TYPE,
  FACE_QUALITY_IMAGE_FORMAT,
  FACE_QUALITY_SMILE_PROPERTIES,
  FACE_QUALITY_YES_NO,
  FACE_QUALITY_SHOULDERS_POSITION,
  POLICIES,
} from '../../constants';

const ageOptionCast = ({ age__lt: lt, age__gte: gte }) => AGE_OPTIONS[`${gte}_${lt}`];
const apparentAgeOptionCast = ({ apparent_age__lt: lt, apparent_age__gte: gte }) => AGE_OPTIONS[`${gte}_${lt}`];
const filterSchema = (prefix) => new Schema({
  age: get(`${prefix}`).as(ageOptionCast),
  ethnicities: get(`${prefix}.ethnicities`, []).asArrayOfOptions(ETHNIC_OPTIONS),
  gender: get(`${prefix}.gender`).asOption(GENDER_OPTIONS),
  liveness: get(`${prefix}.liveness`, []).asArrayOfOptions(LIVENESS_OPTIONS),
  deepfake: get(`${prefix}.deepfake`, []).asArrayOfOptions(DEEPFAKE_STATE_OPTIONS_STRICT),
  match: get(`${prefix}.match`, []).asArrayOf(new Schema({
    label: get('label').asOption(),
    similarityGte: get('similarity__gte').asFloat().ifNaN(undefined),
    similarityLte: get('similarity__lte').asFloat().ifNaN(undefined),
  })),
});

const eventMatchPolicySchema = new Schema({
  label: get('label').asString(),
  filters: {
    gender: get('filters.gender').asOption(GENDER_OPTIONS),
    ethnicities: get('filters.ethnicities', []).asArrayOfOptions(ETHNIC_OPTIONS),
    age: get('filters').as(ageOptionCast),
    liveness: get('filters.liveness', []).asArrayOfOptions(LIVENESS_OPTIONS),
  },
  descriptorType: get('descriptor.descriptor_type').asOption(DESCRIPTOR_TYPE_OPTIONS),
  limit: get('limit').asInt(),
  threshold: get('threshold').asFloat(),
  origin: get('candidates.origin').asOption(EVENT_OPTIONS),
  eventIds: get('candidates.event_ids', []),
  accountId: get('candidates.account_id').asString(),
  eventIdGte: get('candidates.event_id__gte').asString(),
  eventIdLt: get('candidates.event_id__lt').asString(),
  handlerIds: get('candidates.handler_ids', []),
  externalIds: get('candidates.external_ids', []),
  topMatchingCandidatesLabel: get('candidates.top_matching_candidates_label').asString(),
  topSimilarObjectIds: get('candidates.top_similar_object_ids', []),
  topSimilarObjectSimilarityGte: get('candidates.top_similar_object_similarity__gte').asFloat(),
  topSimilarObjectSimilarityLt: get('candidates.top_similar_object_similarity__lt').asFloat(),
  age: get('candidates').as(ageOptionCast),
  apparent_age: get('candidates').as(apparentAgeOptionCast),
  apparent_gender: get('candidates.apparent_gender', []).asArrayOfOptions(EVENT_ENUMS.BODY_BASIC_ATTRIBUTES.APPARENT_GENDER),
  upper_clothing_colors: get('candidates.upper_clothing_colors', []).asArrayOfOptions(EVENT_ENUMS.UPPER_BODY.UPPER_CLOSING_COLOR),
  headwear_states: get('candidates.headwear_states', []).asArrayOfOptions(EVENT_ENUMS.UPPER_BODY.HEADWEAR),
  headwear_apparent_colors: get('candidates.headwear_apparent_colors', []).asArrayOfOptions(EVENT_ENUMS.UPPER_BODY.HEADWEAR_COLORS),
  sleeve_lengths: get('candidates.sleeve_lengths', []).asArrayOfOptions(EVENT_ENUMS.UPPER_BODY.SLEEVE),
  backpack_states: get('candidates.backpack_states', []).asArrayOfOptions(EVENT_ENUMS.ACCESSORIES.BACKPACK),
  lower_garment_colors: get('candidates.lower_garment_colors', []).asArrayOfOptions(EVENT_ENUMS.LOWER_BODY.LOWER_GARMENT.COLORS),
  lower_garment_types: get('candidates.lower_garment_types', []).asArrayOfOptions(EVENT_ENUMS.LOWER_BODY.LOWER_GARMENT.TYPE),
  shoes_apparent_colors: get('candidates.shoes_apparent_colors', []).asArrayOfOptions(EVENT_ENUMS.LOWER_BODY.SHOES.APPARENT_COLOR),
  gender: get('candidates.gender').asOption(GENDER_OPTIONS),
  emotions: get('candidates.emotions', []).asArrayOfOptions(EMOTION_OPTIONS),
  liveness: get('candidates.liveness', []).asArrayOfOptions(LIVENESS_OPTIONS),
  masks: get('candidates.masks', []).asArrayOfOptions(MASK_OPTIONS),
  ethnicities: get('candidates.ethnic_groups', []).asArrayOfOptions(ETHNIC_OPTIONS),
  faceIds: get('candidates.face_ids', []),
  userData: get('candidates.user_data').asString(),
  sources: get('candidates.sources', []),
  tags: get('candidates.tags', []),
  cities: get('candidates.cities', []),
  areas: get('candidates.areas', []),
  districts: get('candidates.districts', []),
  streets: get('candidates.streets', []),
  houseNumbers: get('candidates.house_numbers', []),
  originLongitude: get('candidates.geo_position.origin_longitude').asFloat(),
  originLatitude: get('candidates.geo_position.origin_latitude').asFloat(),
  longitudeDelta: get('candidates.geo_position.longitude_delta').asFloat(),
  latitudeDelta: get('candidates.geo_position.latitude_delta').asFloat(),
  trackIds: get('candidates.track_ids', []),
  createTimeGte: get('candidates.create_time__gte').asDate(),
  createTimeLt: get('candidates.create_time__lt').asDate(),
});

const faceMatchPolicySchema = new Schema({
  label: get('label').asString(),
  filters: {
    gender: get('filters.gender').asOption(GENDER_OPTIONS),
    ethnicities: get('filters.ethnicities', []).asArrayOfOptions(ETHNIC_OPTIONS),
    age: get('filters').as(ageOptionCast),
    liveness: get('filters.liveness', []).asArrayOfOptions(LIVENESS_OPTIONS),
  },
  descriptorType: get('descriptor.descriptor_type').asOption(DESCRIPTOR_TYPE_OPTIONS),
  limit: get('limit').asInt(),
  threshold: get('threshold').asFloat(),
  origin: get('candidates.origin').asOption(EVENT_OPTIONS),
  faceIds: get('candidates.face_ids', []),
  accountId: get('candidates.account_id').asString(),
  externalIds: get('candidates.external_ids', []),
  userData: get('candidates.user_data').asString(),
  faceIdGte: get('candidates.face_id__gte').asString(),
  faceIdLt: get('candidates.face_id__lt').asString(),
  listId: get('candidates.list_id').asString(),
  createTimeGte: get('candidates.create_time__gte').asDate(),
  createTimeLt: get('candidates.create_time__lt').asDate(),
});

const faceQuality = new Schema({
  imageFormat: {
    isEnabled: get('policies.detect_policy.face_quality.checks.image_format.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.image_format.threshold', []).asArrayOfOptions(FACE_QUALITY_IMAGE_FORMAT),
  },
  illuminationQuality: {
    isEnabled: get('policies.detect_policy.face_quality.checks.illumination_quality.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.illumination_quality.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.illumination_quality.threshold.max').asFloat(),
    },
  },
  specularityQuality: {
    isEnabled: get('policies.detect_policy.face_quality.checks.specularity_quality.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.specularity_quality.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.specularity_quality.threshold.max').asFloat(),
    },
  },
  blurrinessQuality: {
    isEnabled: get('policies.detect_policy.face_quality.checks.blurriness_quality.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.blurriness_quality.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.blurriness_quality.threshold.max').asFloat(),
    },
  },
  darkQuality: {
    isEnabled: get('policies.detect_policy.face_quality.checks.dark_quality.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.dark_quality.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.dark_quality.threshold.max').asFloat(),
    },
  },
  lightQuality: {
    isEnabled: get('policies.detect_policy.face_quality.checks.light_quality.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.light_quality.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.light_quality.threshold.max').asFloat(),
    },
  },
  headYaw: {
    isEnabled: get('policies.detect_policy.face_quality.checks.head_yaw.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.head_yaw.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.head_yaw.threshold.max').asFloat(),
    },
  },
  headPitch: {
    isEnabled: get('policies.detect_policy.face_quality.checks.head_pitch.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.head_pitch.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.head_pitch.threshold.max').asFloat(),
    },
  },
  headRoll: {
    isEnabled: get('policies.detect_policy.face_quality.checks.head_roll.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.head_roll.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.head_roll.threshold.max').asFloat(),
    },
  },
  gazeYaw: {
    isEnabled: get('policies.detect_policy.face_quality.checks.gaze_yaw.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.gaze_yaw.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.gaze_yaw.threshold.max').asFloat(),
    },
  },
  gazePitch: {
    isEnabled: get('policies.detect_policy.face_quality.checks.gaze_pitch.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.gaze_pitch.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.gaze_pitch.threshold.max').asFloat(),
    },
  },
  mouthSmiling: {
    isEnabled: get('policies.detect_policy.face_quality.checks.mouth_smiling.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.mouth_smiling.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.mouth_smiling.threshold.max').asFloat(),
    },
  },
  mouthOccluded: {
    isEnabled: get('policies.detect_policy.face_quality.checks.mouth_occluded.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.mouth_occluded.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.mouth_occluded.threshold.max').asFloat(),
    },
  },
  mouthOpen: {
    isEnabled: get('policies.detect_policy.face_quality.checks.mouth_open.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.mouth_open.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.mouth_open.threshold.max').asFloat(),
    },
  },
  glasses: {
    isEnabled: get('policies.detect_policy.face_quality.checks.glasses.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.glasses.threshold', []).asArrayOfOptions(FACE_QUALITY_GLASSES),
  },
  leftEye: {
    isEnabled: get('policies.detect_policy.face_quality.checks.left_eye.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.left_eye.threshold', []).asArrayOfOptions(FACE_QUALITY_EYE),
  },
  rightEye: {
    isEnabled: get('policies.detect_policy.face_quality.checks.right_eye.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.right_eye.threshold', []).asArrayOfOptions(FACE_QUALITY_EYE),
  },
  headHorizontalCenter: {
    isEnabled: get('policies.detect_policy.face_quality.checks.head_horizontal_center.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.head_horizontal_center.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.head_horizontal_center.threshold.max').asFloat(),
    },
  },
  headVerticalCenter: {
    isEnabled: get('policies.detect_policy.face_quality.checks.head_vertical_center.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.head_vertical_center.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.head_vertical_center.threshold.max').asFloat(),
    },
  },
  headWidth: {
    isEnabled: get('policies.detect_policy.face_quality.checks.head_width.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.head_width.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.head_width.threshold.max').asFloat(),
    },
  },
  headHeight: {
    isEnabled: get('policies.detect_policy.face_quality.checks.head_height.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.head_height.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.head_height.threshold.max').asFloat(),
    },
  },
  eyeDistance: {
    isEnabled: get('policies.detect_policy.face_quality.checks.eye_distance.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.eye_distance.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.eye_distance.threshold.max').asFloat(),
    },
  },
  imageWidth: {
    isEnabled: get('policies.detect_policy.face_quality.checks.image_width.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.image_width.threshold.min').asInt(),
      max: get('policies.detect_policy.face_quality.checks.image_width.threshold.max').asInt(),
    },
  },
  imageHeight: {
    isEnabled: get('policies.detect_policy.face_quality.checks.image_height.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.image_height.threshold.min').asInt(),
      max: get('policies.detect_policy.face_quality.checks.image_height.threshold.max').asInt(),
    },
  },
  aspectRatio: {
    isEnabled: get('policies.detect_policy.face_quality.checks.aspect_ratio.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.aspect_ratio.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.aspect_ratio.threshold.max').asFloat(),
    },
  },
  faceWidth: {
    isEnabled: get('policies.detect_policy.face_quality.checks.face_width.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.face_width.threshold.min').asInt(),
      max: get('policies.detect_policy.face_quality.checks.face_width.threshold.max').asInt(),
    },
  },
  faceHeight: {
    isEnabled: get('policies.detect_policy.face_quality.checks.face_height.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.face_height.threshold.min').asInt(),
      max: get('policies.detect_policy.face_quality.checks.face_height.threshold.max').asInt(),
    },
  },
  indentLeft: {
    isEnabled: get('policies.detect_policy.face_quality.checks.indent_left.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.indent_left.threshold.min').asInt(),
      max: get('policies.detect_policy.face_quality.checks.indent_left.threshold.max').asInt(),
    },
  },
  indentRight: {
    isEnabled: get('policies.detect_policy.face_quality.checks.indent_right.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.indent_right.threshold.min').asInt(),
      max: get('policies.detect_policy.face_quality.checks.indent_right.threshold.max').asInt(),
    },
  },
  indentUpper: {
    isEnabled: get('policies.detect_policy.face_quality.checks.indent_upper.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.indent_upper.threshold.min').asInt(),
      max: get('policies.detect_policy.face_quality.checks.indent_upper.threshold.max').asInt(),
    },
  },
  indentLower: {
    isEnabled: get('policies.detect_policy.face_quality.checks.indent_lower.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.indent_lower.threshold.min').asInt(),
      max: get('policies.detect_policy.face_quality.checks.indent_lower.threshold.max').asInt(),
    },
  },
  imageSize: {
    isEnabled: get('policies.detect_policy.face_quality.checks.image_size.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.image_size.threshold.min').asInt(),
      max: get('policies.detect_policy.face_quality.checks.image_size.threshold.max').asInt(),
    },
  },
  eyebrowsState: {
    isEnabled: get('policies.detect_policy.face_quality.checks.eyebrows_state.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.eyebrows_state.threshold', []).asArrayOfOptions(FACE_QUALITY_EYEBROWS_STATE),
  },
  headwearType: {
    isEnabled: get('policies.detect_policy.face_quality.checks.headwear_type.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.headwear_type.threshold', []).asArrayOfOptions(FACE_QUALITY_HEADWEAR_TYPE),
  },
  smileProperties: {
    isEnabled: get('policies.detect_policy.face_quality.checks.smile_properties.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.smile_properties.threshold', []).asArrayOfOptions(FACE_QUALITY_SMILE_PROPERTIES),
  },
  faceColorType: {
    isEnabled: get('policies.detect_policy.face_quality.checks.face_color_type.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.face_color_type.threshold', []).asArrayOfOptions(FACE_QUALITY_FACE_COLOR_TYPES),
  },
  naturalLight: {
    isEnabled: get('policies.detect_policy.face_quality.checks.natural_light.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.natural_light.threshold').asOption(FACE_QUALITY_YES_NO),
  },
  redEyes: {
    isEnabled: get('policies.detect_policy.face_quality.checks.red_eyes.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.red_eyes.threshold').asOption(FACE_QUALITY_YES_NO),
  },
  radialDistortion: {
    isEnabled: get('policies.detect_policy.face_quality.checks.radial_distortion.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.radial_distortion.threshold').asOption(FACE_QUALITY_YES_NO),
  },
  illuminationUniformity: {
    isEnabled: get('policies.detect_policy.face_quality.checks.illumination_uniformity.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.illumination_uniformity.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.illumination_uniformity.threshold.max').asFloat(),
    },
  },
  dynamicRange: {
    isEnabled: get('policies.detect_policy.face_quality.checks.dynamic_range.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.dynamic_range.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.dynamic_range.threshold.max').asFloat(),
    },
  },
  backgroundUniformity: {
    isEnabled: get('policies.detect_policy.face_quality.checks.background_uniformity.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.background_uniformity.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.background_uniformity.threshold.max').asFloat(),
    },
  },
  backgroundLightness: {
    isEnabled: get('policies.detect_policy.face_quality.checks.background_lightness.estimate').asBoolean(),
    threshold: {
      min: get('policies.detect_policy.face_quality.checks.background_lightness.threshold.min').asFloat(),
      max: get('policies.detect_policy.face_quality.checks.background_lightness.threshold.max').asFloat(),
    },
  },
  shoulders_position: {
    isEnabled: get('policies.detect_policy.face_quality.checks.shoulders_position.estimate').asBoolean(),
    threshold: get('policies.detect_policy.face_quality.checks.shoulders_position.threshold', []).asArrayOfOptions(FACE_QUALITY_SHOULDERS_POSITION),
  },
});

const bodyAttributesSchema = new Schema({
  estimate_basic_attributes: get('estimate_basic_attributes', false).asBoolean(),
  estimate_upper_body: get('estimate_upper_body', false).asBoolean(),
  estimate_lower_body: get('estimate_lower_body', false).asBoolean(),
  estimate_accessories: get('estimate_accessories', false).asBoolean(),
});

export const read = new Schema({
  accountId: get('account_id').asString(),
  handlerId: get('handler_id').asString(),
  description: get('description').asString(),
  isDynamic: get('is_dynamic').asBoolean(),
  createTime: get('create_time').asDate(),
  lastUpdateTime: get('last_update_time').asDate(),
  estimate: {
    headPose: get('policies.detect_policy.estimate_head_pose').asBoolean(),
    emotion: get('policies.detect_policy.estimate_emotions').asBoolean(),
    mask: get('policies.detect_policy.estimate_mask').asBoolean(),
    quality: get('policies.detect_policy.estimate_quality').asBoolean(),
    gaze: get('policies.detect_policy.estimate_gaze').asBoolean(),
    glasses: get('policies.detect_policy.estimate_glasses', false).asBoolean(),
    eyes: get('policies.detect_policy.estimate_eyes_attributes').asBoolean(),
    mouth: get('policies.detect_policy.estimate_mouth_attributes').asBoolean(),
    landmarks68: get('policies.detect_policy.detect_landmarks68').asBoolean(),
    face: get('policies.detect_policy.detect_face').asBoolean(),
    body: get('policies.detect_policy.detect_body').asBoolean(),
    faceQuality: get('policies.detect_policy.face_quality.estimate').asBoolean(),
    body_attributes: get('policies.detect_policy.body_attributes').asSchema(bodyAttributesSchema),
  },
  extract: {
    basicAttributes: get('policies.extract_policy.extract_basic_attributes').asBoolean(),
    faceDescriptor: get('policies.extract_policy.extract_face_descriptor').asBoolean(),
    bodyDescriptor: get('policies.extract_policy.extract_body_descriptor').asBoolean(),
    exif: get('policies.detect_policy.extract_exif').asBoolean(),
  },
  filters: {
    multifacePolicy: get('policies.detect_policy.multiface_policy').asOption(MULTIFACE_POLICY_OPTIONS),
    faceDescriptorScoreThreshold: get('policies.extract_policy.fd_score_threshold').asFloat().ifNaN(undefined),
    masks: get('policies.detect_policy.mask_states', []).ifNull([]).asArrayOfOptions(MASK_OPTIONS),
    pitchThreshold: get('policies.detect_policy.pitch_threshold').asFloat().ifNaN(undefined),
    rollThreshold: get('policies.detect_policy.roll_threshold').asFloat().ifNaN(undefined),
    yawThreshold: get('policies.detect_policy.yaw_threshold').asFloat().ifNaN(undefined),
    liveness_states: get('policies.detect_policy.liveness_states', []).ifNull([]).asArrayOfOptions(LIVENESS_OPTIONS),
    deepfake_states: get('policies.detect_policy.deepfake_states', []).ifNull([]).asArrayOfOptions(DEEPFAKE_STATE_OPTIONS_STRICT),
    faceQuality: get('policies.detect_policy.face_quality.filter').asBoolean(),
  },
  matchPolicy: get('policies.match_policy', []).ifNull([]).asArrayOf((item) => {
    switch (get('candidates.origin')(item)) {
      case 'faces': return faceMatchPolicySchema(item);
      case 'events': return eventMatchPolicySchema(item);
      default: return item;
    }
  }),
  faceSamplePolicy: {
    isEnabled: get('policies.storage_policy.face_sample_policy.store_sample').asBoolean(),
    filters: filterSchema('policies.storage_policy.face_sample_policy.filters'),
    ttl: get('policies.storage_policy.face_sample_policy.ttl').asOption(POLICIES.TTL),
  },
  attributesPolicy: {
    isEnabled: get('policies.storage_policy.attribute_policy.store_attribute').asBoolean(),
    ttl: get('policies.storage_policy.attribute_policy.ttl').asInt(),
    filters: filterSchema('policies.storage_policy.attribute_policy.filters'),
  },
  facePolicy: {
    isEnabled: get('policies.storage_policy.face_policy.store_face').asBoolean(),
    useSampleAsAvatar: get('policies.storage_policy.face_policy.set_sample_as_avatar').asBoolean(),
    filters: filterSchema('policies.storage_policy.face_policy.filters'),
  },
  estimate_deepfake: {
    estimate: get('policies.detect_policy.estimate_deepfake.estimate', false).asBoolean(),
    real_threshold: get('policies.detect_policy.estimate_deepfake.real_threshold').asFloat(),
    mode: get('policies.detect_policy.estimate_deepfake.mode').asOption(DEEPFAKE_MODE_OPTIONS),
  },
  estimate_liveness: {
    estimate: get('policies.detect_policy.estimate_liveness.estimate').asBoolean(),
    liveness_threshold: get('policies.detect_policy.estimate_liveness.liveness_threshold').asFloat().ifNaN(undefined),
  },
  estimate_people_count: {
    estimate: get('policies.detect_policy.estimate_people_count.estimate').asBoolean(),
    people_coordinates: get('policies.detect_policy.estimate_people_count.people_coordinates').asBoolean(),
  },
  faceQuality,
  bodySamplePolicy: {
    isEnabled: get('policies.storage_policy.body_sample_policy.store_sample').asBoolean(),
    filters: filterSchema('policies.storage_policy.body_sample_policy.filters'),
    ttl: get('policies.storage_policy.face_sample_policy.ttl').asOption(POLICIES.TTL),
  },
  imageOriginPolicy: {
    isEnabled: get('policies.storage_policy.image_origin_policy.store_image').asBoolean(),
    useExternalReference: get('policies.storage_policy.image_origin_policy.use_external_references').asBoolean(),
    filters: filterSchema('policies.storage_policy.image_origin_policy.filters'),
    ttl: get('policies.storage_policy.face_sample_policy.ttl').asOption(POLICIES.TTL),

  },
  faceToListPolicy: {
    isEnabled: get('policies.storage_policy.face_policy.link_to_lists_policy').as((v) => v?.length > 0),
    lists: get('policies.storage_policy.face_policy.link_to_lists_policy', []).asArrayOf(new Schema({
      listId: get('list_id').asString(),
      filters: filterSchema('filters'),
    })),
  },
  eventPolicy: {
    isEnabled: get('policies.storage_policy.event_policy.store_event').asBoolean(),
    waitSaving: get('policies.storage_policy.event_policy.wait_saving').asBoolean(),
    filters: filterSchema('policies.storage_policy.event_policy.filters'),
  },
  notificationPolicy: {
    isEnabled: get('policies.storage_policy.notification_policy.send_notification').asBoolean(),
    filters: filterSchema('policies.storage_policy.notification_policy.filters'),
  },
  tagPolicy: get('policies.conditional_tags_policy', []).asArrayOf(new Schema({
    tag: get('tag').asString(),
    filters: filterSchema('filters'),
  })),
  callbacksPolicy: get('policies.storage_policy.callbacks', []).asArrayOf(new Schema({
    type: get('type').asOption(POLICIES.CALLBACK.type),
    authorization: {
      type: get('authorization.type').asOption(POLICIES.CALLBACK.authorization.type),
      login: get('authorization.login').asString(),
      password: get('authorization.password').asString(),
    },
    url: get('url').asString(),
    params: {
      timeout: get('params.timeout').asInt(),
      content_type: get('params.content_type').asOption(POLICIES.CALLBACK.params.content_type),
      headers: get('params.headers', {}).as((headersObj) => {
        if (Object.keys(headersObj) === 0) return '';
        return Object.entries(headersObj).map((entry) => entry.join(': ')).join('\n');
      }),
    },
    filters: filterSchema('filters'),
    enable: get('enable').asBoolean(),
  })),
});
