import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const SquareCheckIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="SquareCheckIcon" ref={iconRef} viewBox="0 0 24 24" {...props}>
      <path d="M7.49976 12.8L10.129 15.4292C10.1681 15.4684 10.2317 15.4682 10.2707 15.4289L16.7 8.9502M5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    </IconInner>
  );
};

export const SquareCheckIcon = memo(SquareCheckIconInner);
