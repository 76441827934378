import { selectSimilarityThresholds } from '@vlabs/shared/selectors/appSelectors';

import { getState } from '@vlabs/shared/redux/store';

export const calcTagType = (similarity) => {
  const { low, medium } = selectSimilarityThresholds(getState());
  let tagType = 'positive';

  if (similarity < medium) tagType = 'attention';
  if (similarity < low) tagType = 'negative';
  return tagType;
};
