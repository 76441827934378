import React, { useState, useCallback, useEffect, useRef } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import {
  StepFilters,
  StepFaceQuality,
  StepDeepfake,
  StepLiveness,
} from '@vlabs/pages/handlers/preconfigured-scenarios/other/steps/index';
import { useSteppedForm } from '@vlabs/shared/hooks';
import { Stepper, Page, Modal } from '@vlabs/uikit';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import st from './CreateVerifier.module.sass';
import { defaultVerifier } from './defaultVerifier';
import { CreatedVerifierForm } from './forms/index';
import {
  StepNameOfVerifier,
  StepSelectionOfAttribute,
  StepSaveFaceAndAttributes,
} from './steps/index';

const steps = [
  StepNameOfVerifier,
  StepSelectionOfAttribute,
  StepLiveness,
  StepDeepfake,
  StepFaceQuality,
  StepFilters,
  StepSaveFaceAndAttributes,
];

function CreateVerifier() {
  const { t } = useTranslation();
  const history = useHistory();

  const [createdModalIsOpen, setCreatedModalIsOpen] = useState(false);
  const [createdVerifierId, setCreatedVerifierId] = useState();
  const [createdVerifierName, setCreatedVerifierName] = useState();

  const onCloseCreatedModal = () => {
    setCreatedModalIsOpen(false);
    history.goBack();
  };

  const form = useForm({
    defaultValues: defaultVerifier,
    mode: 'onChange',
  });
  const { reset, handleSubmit } = form;
  const prevForm = useRef(defaultVerifier);
  useEffect(() => {
    prevForm.current = defaultVerifier; reset(defaultVerifier);
  }, [reset, prevForm]);

  const onSubmit = useCallback(async (values) => {
    const { verifier_id: verifierId } = await apiContainer.lunaClient.verifiers.create(values);
    return verifierId;
  }, []);

  const onSaveVerifier = useCallback(async (values) => {
    setCreatedVerifierId(await onSubmit(values));
    setCreatedVerifierName(values?.description);
    setCreatedModalIsOpen(true);
  }, [onSubmit, setCreatedModalIsOpen]);

  const canGoNextStep = useCallback(() => {
    return !(document.querySelector('.ReactModal__Content--after-open'));
  }, []);

  const stepper = useSteppedForm({
    form,
    canGoNextStep,
    steps,
    onSubmit: onSaveVerifier,
    buttonsClass: st.StepperButtons,
  });

  const context = {
    ...form,
  };

  return (
    <FormProvider {...context}>
      <form onSubmit={handleSubmit(onSaveVerifier)}>
        <Modal
          appElement={document.getElementById('root')}
          data-testid="modal.saveVerifier"
          isOpen={createdModalIsOpen}
          onRequestClose={onCloseCreatedModal}
          size="small"
        >
          <CreatedVerifierForm
            verifierId={createdVerifierId}
            verifierName={createdVerifierName}
          />
        </Modal>
        <Page title={t('verifiers:form.createFormTitle')}>
          <Stepper {...stepper} className={st.StepperWrapper}>
            {steps.map((Step) => <Step key={Step.name} />)}
          </Stepper>
        </Page>
      </form>
    </FormProvider>

  );
}

export default CreateVerifier;
