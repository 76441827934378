import React from 'react';

import { EmptyImage } from '@vlabs/shared/components/empty-image';
import cn from 'classnames';

import './ImageCell.sass';

export const ImageCell = (imageUrl) => (
  <div className="ImageCell__Wrapper">
    {imageUrl && (
      <img
        alt="reference face"
        className="ImageCell"
        key={imageUrl}
        src={imageUrl}
      />
    )}

    {!imageUrl && (
      <EmptyImage className={cn(['ImageCell', 'ImageCell__NoPhoto'])} />
    )}
  </div>
);
