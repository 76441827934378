import React from 'react';

import Loader from '@vlabs/shared/legacy-components/loader/Loader';

import st from './ChartPanel.module.sass';
import useChart from './useChart';

const ChartPanel = ({ chartData, chartType }) => {
  const Chart = useChart(chartData, chartType);

  return (
    <>
      {!chartData && (
        <div className={st.Loader}>
          <Loader />
        </div>
      )}
      {chartData && (
        <div className={st.Chart}>
          {Chart}
        </div>
      )}
    </>
  );
};

export default ChartPanel;
