import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const DollarSignIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="DollarSignIcon" ref={iconRef} viewBox="0 0 24 24" {...props}>
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth} d="M12 1v22m5-18H9.5a3.5 3.5 0 1 0 0 7h5a3.5 3.5 0 1 1 0 7H6" />
    </IconInner>
  );
};

export const DollarSignIcon = memo(DollarSignIconInner);
