/* eslint-disable no-underscore-dangle, no-use-before-define */
import { fabric } from 'fabric';

import { getCssVar } from '../../helpers';
import { drawVertex, drawPolygon } from '../utils/canvas-drawing';

const colorPrimary = getCssVar('--color-primary');
const inProgressPolygonColor = 'deepskyblue';

export const enablePolygonDrawing = ({
  canvas,
  onChange,
  polygonFill,
  originSize,
  initialPolygonPoints,
  editable,
}) => {
  let firstPoint = null;
  let polygon = null;
  let polygonIsFinished = false;
  // eslint-disable-next-line no-param-reassign
  canvas.__eventListeners = {};

  const selectPolygon = () => {
    if (polygon && editable) {
      canvas.setActiveObject(polygon);
    }
  };

  const buildPolygon = (options) => {
    return drawPolygon({
      id: 'droi',
      strokeWidth: 0,
      fill: polygonFill,
      selectable: editable,
      hasControls: editable,
      hoverCursor: editable ? 'move' : 'default',
      ...options,
    });
  };

  const onControlMove = (event, transform, x, y) => {
    const { target: obj } = transform;
    const currentControl = obj.controls[obj.__corner];
    const mouseLocalPosition = obj.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
    const polygonBaseSize = getPolygonSize();
    const size = obj._getTransformedDimensions(0, 0);
    const finalPointPosition = {
      x: (mouseLocalPosition.x * polygonBaseSize.x) / size.x + obj.pathOffset.x,
      y: (mouseLocalPosition.y * polygonBaseSize.y) / size.y + obj.pathOffset.y,
    };
    obj.points[currentControl.pointIndex] = finalPointPosition;
    obj._setPositionDimensions({});
    return true;
  };

  const anchorWrapper = (anchorIndex, fn) => {
    return (eventData, transform, x, y) => {
      const obj = transform.target;

      const absolutePoint = fabric.util.transformPoint(
        {
          x: obj.points[anchorIndex].x - obj.pathOffset.x,
          y: obj.points[anchorIndex].y - obj.pathOffset.y,
        },
        obj.calcTransformMatrix(),
      );

      const actionPerformed = fn(eventData, transform, x, y);
      const polygonBaseSize = getPolygonSize();
      obj._setPositionDimensions({});
      const newX = (obj.points[anchorIndex].x - obj.pathOffset.x) / polygonBaseSize.x;
      const newY = (obj.points[anchorIndex].y - obj.pathOffset.y) / polygonBaseSize.y;
      obj.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
      return actionPerformed;
    };
  };

  const calcObjectPointPosition = (obj, pointIndex) => {
    return fabric.util.transformPoint(
      {
        x: obj.points[pointIndex].x - obj.pathOffset.x,
        y: obj.points[pointIndex].y - obj.pathOffset.y,
      },
      obj.calcTransformMatrix(),
    );
  };

  const updatePolygonControls = () => {
    if (!polygon) return;

    selectPolygon();

    const lastControl = polygon.points.length - 2;

    polygon.controls = polygon.points.reduce((acc, point, index) => {
      // Не рисуем контрол для фантомной вершины
      if (!polygonIsFinished && index === polygon.points.length - 1) return acc;

      acc[`p${index}`] = new fabric.Control({
        positionHandler: (_, __, obj) => calcObjectPointPosition(obj, index),
        actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, onControlMove),
        actionName: 'modifyPolygon',
        pointIndex: index,
        render: (ctx, x, y) => {
          const vertex = buildVertex({ x, y });
          if (!polygonIsFinished && index === 0) vertex.fill = colorPrimary;
          vertex.render(ctx);
        },
      });
      return acc;
    }, {});
  };

  const addPolygonEventListeners = () => {
    if (!polygon) return;

    polygon.on('modified', () => {
      $onChange();
    });
  };

  if (initialPolygonPoints) {
    polygonIsFinished = true;
    polygon = buildPolygon({
      points: [...initialPolygonPoints],
    });

    addPolygonEventListeners();
    updatePolygonControls();

    canvas.add(polygon);
  }

  // Замыкание полигона
  const endPolygonDrawing = () => {
    if (!polygon) return;
    polygonIsFinished = true;

    // удаляем фантомную вершину
    polygon.points.pop();
    polygon.set({ fill: polygonFill });

    if (firstPoint) {
      canvas.remove(firstPoint);
      firstPoint = null;
    }

    canvas.renderAll();

    // Нарисовать можно только 1 полигон, отключаем обработчики
    canvas.off('mouse:down', onMouseDown);
    canvas.off('mouse:move', onMouseMove);
  };

  const buildVertex = ({ x, y }) => {
    const id = `vertex_${Math.random()}`;
    const pointOptions = {
      id,
      left: x,
      top: y,
    };
    return drawVertex(pointOptions);
  };

  const addPointToPolygon = ({ pointer }) => {
    if (!polygon) return;

    polygon.points.push(pointer);
    updatePolygonControls();
    polygon._setPositionDimensions({});
    canvas.renderAll();
  };

  const getPolygonSize = () => {
    const stroke = new fabric.Point(
      polygon.strokeUniform ? 1 / polygon.scaleX : 1,
      polygon.strokeUniform ? 1 / polygon.scaleY : 1,
    ).multiply(polygon.strokeWidth);
    return new fabric.Point(polygon.width + stroke.x, polygon.height + stroke.y);
  };

  const addPolygon = ({ pointer }) => {
    if (!firstPoint) return;

    polygon = buildPolygon({
      fill: inProgressPolygonColor,
      points: [
        { x: firstPoint.left, y: firstPoint.top },
        pointer,
        pointer, // дополнительная фантомная вершина чтобы рисовать превью
      ],
    });

    addPolygonEventListeners();
    updatePolygonControls();

    if (editable) {
      canvas.remove(firstPoint);
      firstPoint = null;
    }

    canvas.add(polygon);
    polygon.sendToBack();
  };

  const addFirstPoint = ({ pointer }) => {
    firstPoint = buildVertex(pointer);
    firstPoint.fill = colorPrimary;
    canvas.add(firstPoint);
  };

  const $onChange = () => {
    if (!onChange || !polygonIsFinished) return;

    const newPoints = polygon.points
      .map((_, i) => calcObjectPointPosition(polygon, i))
      .map(({ x, y }) => {
        const zoomFactorX = originSize.rawWidth / (canvas.width * canvas.getZoom());
        const zoomFactorY = originSize.rawHeight / (canvas.height * canvas.getZoom());
        return ({
          x: x * zoomFactorX || 0,
          y: y * zoomFactorY || 0,
        });
      });

    onChange(newPoints);
  };

  const isPointInPolygon = (coords) => {
    if (!polygon) return false;

    const lineIntersection = fabric.Intersection.intersectLinePolygon(
      new fabric.Point(-10, -10),
      new fabric.Point(coords),
      polygon.points,
    );

    return lineIntersection.points.length % 2 === 1;
  };

  const onMouseDown = (event) => {
    if (!polygon) {
      if (!firstPoint) {
        addFirstPoint(event);
      } else {
        addPolygon(event);
      }
      return;
    }

    const clickedOnVertex = !!event.transform?.corner;
    const clickedOnFinishPoint = event.transform?.corner === 'p0' || event.target === firstPoint;
    const clickedOnPolygon = isPointInPolygon(event.pointer, polygon);

    // Контролы вершин немного больше размером чем полигон,
    // так что проверяем клики по ним отдельно;
    const clickedOnVoid = !event.target || (!clickedOnPolygon && !clickedOnVertex && !clickedOnFinishPoint);

    if (clickedOnVoid) {
      addPointToPolygon(event);
      return;
    }

    // 3 минимум, + фантомная вершина
    if (
      polygon.points.length > 3
      && !polygonIsFinished
      && clickedOnFinishPoint
    ) {
      endPolygonDrawing(event);
      $onChange();
    }
  };

  const onMouseMove = ({ pointer }) => {
    let needsRerender = false;

    if (polygon) {
      polygon.points[polygon.points.length - 1] = pointer;
      needsRerender = true;
    }

    if (needsRerender) {
      canvas.renderAll();
    }
  };

  const onAfterRender = () => {
    // Если есть полигон — блокируем рисование новых
    if (canvas.getObjects().find(({ id }) => id === 'droi')) {
      canvas.off('mouse:down', onMouseDown);
      canvas.off('mouse:move', onMouseMove);
      canvas.off('after:render', onAfterRender);
    }
  };

  if (!initialPolygonPoints) {
    canvas.on('mouse:down', onMouseDown);
    canvas.on('mouse:move', onMouseMove);
    canvas.on('after:render', onAfterRender);
  }
  canvas.on('selection:cleared', selectPolygon);
};
