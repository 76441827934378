import React from 'react';

import { ZapIcon } from '@vlabs/icons';
import { Control } from '@vlabs/uikit';
import { useTranslation } from 'react-i18next';

export const AlarmButton = (props) => {
  const { t } = useTranslation();
  return (
    <Control.Button {...props} icon={<ZapIcon />} kind="alarm" variant="filled">{t('uikit:control.button.alarmButton.label')}</Control.Button>
  );
};
