import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import './Link.sass';

const Link = ({
  children,
  className,
  disabled,
  as,
  icon,
  title,
  ...restProps
}) => {
  const Component = as || RouterLink;

  if (disabled) {
    return (
      <div
        className={cn(
          className,
          'Link',
          'Link_disabled',
          { Link_withIcon: !!icon },
        )}
        title={title}
      >
        {children}
        {icon && <span className="Link__Icon">{icon}</span>}
      </div>
    );
  }

  return (
    <Component
      className={cn(
        className,
        'Link',
        { Link_withIcon: !!icon },
      )}
      {...restProps}
    >
      {children}
      {icon && <span className="Link__Icon">{icon}</span>}
    </Component>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  as: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  icon: PropTypes.node,
};

Link.defaultProps = {
  children: undefined,
  className: undefined,
  title: undefined,
  disabled: undefined,
  as: undefined,
  icon: undefined,
};

export { Link };
