import { Schema, get, CleanSchema } from '../../../../json-transform';

const optionValue = (option) => option?.value;
const filterSchema = (prefix) => ({
  age__gte: get(`${prefix}.age.value[0]`).asInt(),
  age__lt: get(`${prefix}.age.value[1]`).asInt(),
  ethnicities: get(`${prefix}.ethnicities`).asArrayOf(optionValue).ifEmpty(undefined),
  gender: get(`${prefix}.gender`).as(optionValue).asInt(),
  liveness: get(`${prefix}.liveness`).asArrayOf(optionValue).ifEmpty(undefined),
  deepfake: get(`${prefix}.deepfake`).asArrayOf(optionValue).ifEmpty(undefined),
  match: get(`${prefix}.match`).asArrayOf(new Schema({
    label: get('label.value'),
    similarity__lte: get('similarityLte').asFloat().ifNaN(undefined),
    similarity__gte: get('similarityGte').asFloat().ifNaN(undefined),
  })).ifEmpty(undefined),
});

const faceMatchPolicySchema = new CleanSchema({
  label: get('label'),
  limit: get('limit').asInt().ifNaN(undefined),
  threshold: get('threshold').asFloat().ifNaN(undefined),
  filters: filterSchema('filters'),
  candidates: {
    origin: get('origin.value'),
    face_ids: get('faceIds').asArrayFromString().ifEmpty(undefined),
    account_id: get('accountId'),
    external_ids: get('externalIds').asArrayFromString().ifEmpty(undefined),
    user_data: get('userData').ifEmptyString(undefined),
    face_id__gte: get('faceIdGte'),
    face_id__lt: get('faceIdLt'),
    list_id: get('list.value'),
    create_time__gte: get('createTimeGte[0]').asISOString(),
    create_time__lt: get('createTimeLt[0]').asISOString(),
  },
});

const eventMatchPolicySchema = new CleanSchema({
  label: get('label'),
  limit: get('limit').asInt().ifNaN(undefined),
  threshold: get('threshold').asFloat().ifNaN(undefined),
  descriptor: {
    descriptor_type: get('descriptorType').as(optionValue).ifEmpty(undefined),
  },
  filters: filterSchema('filters'),
  candidates: {
    origin: get('origin.value').asString(),
    event_ids: get('eventIds').asArrayFromString().ifEmpty(undefined),
    account_id: get('accountId'),
    event_id__gte: get('eventIdGte'),
    event_id__lt: get('eventIdLt'),
    handler_ids: get('handlers').asArrayOf(optionValue).ifEmpty(undefined),
    external_ids: get('externalIds').asArrayFromString().ifEmpty(undefined),
    top_matching_candidates_label: get('topMatchingCandidatesLabel'),
    top_similar_object_ids: get('topSimilarObjectIds').asArrayFromString().ifEmpty(undefined),
    top_similar_object_similarity__gte: get('topSimilarObjectSimilarityGte').asFloat().ifNaN(undefined),
    top_similar_object_similarity__lt: get('topSimilarObjectSimilarityLt').asFloat().ifNaN(undefined),
    age__gte: get('age.value[0]').asInt().ifNaN(undefined),
    age__lt: get('age.value[1]').asInt().ifNaN(undefined),
    gender: get('gender.value').asInt().ifNaN(undefined),
    apparent_gender: get('apparent_gender').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    apparent_age__gte: get('apparent_age.value[0]').asInt().ifNaN(undefined),
    apparent_age__lt: get('apparent_age.value[1]').asInt().ifNaN(undefined),
    upper_clothing_colors: get('upper_clothing_colors').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    headwear_states: get('headwear_states').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    headwear_apparent_colors: get('headwear_apparent_colors').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    sleeve_lengths: get('sleeve_lengths').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    backpack_states: get('backpack_states').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    lower_garment_colors: get('lower_garment_colors').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    lower_garment_types: get('lower_garment_types').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    shoes_apparent_colors: get('shoes_apparent_colors').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    emotions: get('emotions').asArrayOf(optionValue).asArrayWithOptionalNull().asArrayWithOptionalNull()
      .ifEmpty(undefined),
    liveness: get('liveness').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    masks: get('masks').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    ethnic_groups: get('ethnicities').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    face_ids: get('faceIds').asArrayFromString().ifEmpty(undefined),
    user_data: get('userData').ifEmptyString(undefined),
    sources: get('sources').asArrayOf(optionValue).asArrayWithOptionalNull().ifEmpty(undefined),
    tags: get('tags').asArrayFromString().ifEmpty(undefined),
    cities: get('cities').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
    areas: get('areas').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
    districts: get('districts').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
    streets: get('streets').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
    house_numbers: get('houseNumbers').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
    track_ids: get('trackIds').asArrayFromString().asArrayWithOptionalNull().ifEmpty(undefined),
    create_time__gte: get('createTimeGte[0]').asISOString(),
    create_time__lt: get('createTimeLt[0]').asISOString(),
    geo_position: {
      origin_longitude: get('originLongitude').asFloat().ifNaN(undefined),
      origin_latitude: get('originLatitude').asFloat().ifNaN(undefined),
      longitude_delta: get('longitudeDelta').asFloat().ifNaN(undefined),
      latitude_delta: get('latitudeDelta').asFloat().ifNaN(undefined),
    },
  },
});

const bodyAttributesSchema = new CleanSchema({
  estimate_basic_attributes: get('estimate_basic_attributes').asInt().ifNaN(undefined),
  estimate_upper_body: get('estimate_upper_body').asInt().ifNaN(undefined),
  estimate_lower_body: get('estimate_lower_body').asInt().ifNaN(undefined),
  estimate_accessories: get('estimate_accessories').asInt().ifNaN(undefined),
});

const callbackSchema = new CleanSchema({
  type: get('type').asOptionValue(),
  authorization: {
    type: get('authorization.type').asOptionValue(),
    login: get('authorization.login').asString().ifEmptyString(undefined),
    password: get('authorization.password').asString().ifEmptyString(undefined),
  },
  url: get('url').asString(),
  params: {
    timeout: get('params.timeout').asInt().ifNaN(undefined),
    content_type: get('params.content_type').asOptionValue(),
    headers: get('params.headers', '').as((headers) => {
      const result = {};
      headers.split('\n').forEach((headerLine) => {
        const [name, value] = headerLine.split(': ');
        result[name] = value;
      });
      return result;
    }).ifEmpty(undefined),
  },
  filters: filterSchema('filters'),
  enable: get('enable', true).asInt(),
});

const dynamicSchema = new CleanSchema({
  description: get('description'),
  is_dynamic: get('isDynamic').asBoolean(),
});

const staticSchema = new CleanSchema({
  description: get('description'),
  is_dynamic: get('isDynamic').asBoolean(),
  policies: {
    detect_policy: {
      multiface_policy: get('filters.multifacePolicy.value').asInt().ifNaN(undefined),
      estimate_people_count: {
        estimate: get('estimate_people_count.estimate').asInt().ifNaN(undefined),
        people_coordinates: get('estimate_people_count.people_coordinates').asInt().ifNaN(undefined),
      },
      estimate_head_pose: get('estimate.headPose').asInt().ifNaN(undefined),
      estimate_emotions: get('estimate.emotion').asInt().ifNaN(undefined),
      estimate_mask: get('estimate.mask').asInt().ifNaN(undefined),
      estimate_quality: get('estimate.quality').asInt().ifNaN(undefined),
      estimate_gaze: get('estimate.gaze').asInt().ifNaN(undefined),
      estimate_glasses: get('estimate.glasses').asInt().ifNaN(undefined),
      estimate_eyes_attributes: get('estimate.eyes').asInt().ifNaN(undefined),
      estimate_mouth_attributes: get('estimate.mouth').asInt().ifNaN(undefined),
      detect_landmarks68: get('estimate.landmarks68').asInt().ifNaN(undefined),
      extract_exif: get('extract.exif').asInt().ifNaN(undefined),
      detect_face: get('estimate.face').asInt().ifNaN(undefined),
      detect_body: get('estimate.body').asInt().ifNaN(undefined),
      pitch_threshold: get('filters.pitchThreshold').asFloat().ifNaN(undefined),
      roll_threshold: get('filters.rollThreshold').asFloat().ifNaN(undefined),
      yaw_threshold: get('filters.yawThreshold').asFloat().ifNaN(undefined),
      mask_states: get('filters.masks').asArrayOf(optionValue).ifEmpty(undefined),
      estimate_liveness: {
        estimate: get('estimate_liveness.estimate').asInt().ifNaN(undefined),
        liveness_threshold: get('estimate_liveness.liveness_threshold').asFloat().ifNaN(undefined),
      },
      liveness_states: get('filters.liveness_states').asArrayOf(optionValue).ifEmpty(undefined),
      estimate_deepfake: {
        estimate: get('estimate_deepfake.estimate').asInt().ifNaN(undefined),
        real_threshold: get('estimate_deepfake.real_threshold').asFloat().ifNaN(undefined),
        mode: get('estimate_deepfake.mode').asOptionValue().ifNaN(undefined),
      },
      deepfake_states: get('filters.deepfake_states').asArrayOfOptionValues().ifEmpty(undefined),
      face_quality: {
        estimate: get('estimate.faceQuality').asInt(),
        filter: get('filters.faceQuality').asInt(),
        checks: {
          image_format: {
            estimate: get('faceQuality.imageFormat.isEnabled').asInt(),
            threshold: get('faceQuality.imageFormat.threshold').asArrayOf(optionValue).ifEmpty(undefined),
          },
          illumination_quality: {
            estimate: get('faceQuality.illuminationQuality.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.illuminationQuality.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.illuminationQuality.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          specularity_quality: {
            estimate: get('faceQuality.specularityQuality.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.specularityQuality.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.specularityQuality.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          blurriness_quality: {
            estimate: get('faceQuality.blurrinessQuality.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.blurrinessQuality.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.blurrinessQuality.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          dark_quality: {
            estimate: get('faceQuality.darkQuality.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.darkQuality.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.darkQuality.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          light_quality: {
            estimate: get('faceQuality.lightQuality.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.lightQuality.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.lightQuality.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          head_yaw: {
            estimate: get('faceQuality.headYaw.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.headYaw.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.headYaw.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          head_pitch: {
            estimate: get('faceQuality.headPitch.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.headPitch.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.headPitch.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          head_roll: {
            estimate: get('faceQuality.headRoll.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.headRoll.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.headRoll.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          gaze_yaw: {
            estimate: get('faceQuality.gazeYaw.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.gazeYaw.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.gazeYaw.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          gaze_pitch: {
            estimate: get('faceQuality.gazePitch.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.gazePitch.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.gazePitch.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          mouth_smiling: {
            estimate: get('faceQuality.mouthSmiling.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.mouthSmiling.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.mouthSmiling.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          mouth_occluded: {
            estimate: get('faceQuality.mouthOccluded.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.mouthOccluded.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.mouthOccluded.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          mouth_open: {
            estimate: get('faceQuality.mouthOpen.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.mouthOpen.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.mouthOpen.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          glasses: {
            estimate: get('faceQuality.glasses.isEnabled').asInt(),
            threshold: get('faceQuality.glasses.threshold').asArrayOf(optionValue).ifEmpty(undefined),
          },
          left_eye: {
            estimate: get('faceQuality.leftEye.isEnabled').asInt(),
            threshold: get('faceQuality.leftEye.threshold').asArrayOf(optionValue).ifEmpty(undefined),
          },
          right_eye: {
            estimate: get('faceQuality.rightEye.isEnabled').asInt(),
            threshold: get('faceQuality.rightEye.threshold').asArrayOf(optionValue).ifEmpty(undefined),
          },
          head_horizontal_center: {
            estimate: get('faceQuality.headHorizontalCenter.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.headHorizontalCenter.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.headHorizontalCenter.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          head_vertical_center: {
            estimate: get('faceQuality.headVerticalCenter.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.headVerticalCenter.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.headVerticalCenter.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          head_width: {
            estimate: get('faceQuality.headWidth.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.headWidth.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.headWidth.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          head_height: {
            estimate: get('faceQuality.headHeight.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.headHeight.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.headHeight.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          eye_distance: {
            estimate: get('faceQuality.eyeDistance.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.eyeDistance.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.eyeDistance.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          image_width: {
            estimate: get('faceQuality.imageWidth.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.imageWidth.threshold.min').asInt().ifNaN(undefined),
              max: get('faceQuality.imageWidth.threshold.max').asInt().ifNaN(undefined),
            },
          },
          image_height: {
            estimate: get('faceQuality.imageHeight.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.imageHeight.threshold.min').asInt().ifNaN(undefined),
              max: get('faceQuality.imageHeight.threshold.max').asInt().ifNaN(undefined),
            },
          },
          aspect_ratio: {
            estimate: get('faceQuality.aspectRatio.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.aspectRatio.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.aspectRatio.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          face_width: {
            estimate: get('faceQuality.faceWidth.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.faceWidth.threshold.min').asInt().ifNaN(undefined),
              max: get('faceQuality.faceWidth.threshold.max').asInt().ifNaN(undefined),
            },
          },
          face_height: {
            estimate: get('faceQuality.faceHeight.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.faceHeight.threshold.min').asInt().ifNaN(undefined),
              max: get('faceQuality.faceHeight.threshold.max').asInt().ifNaN(undefined),
            },
          },
          indent_left: {
            estimate: get('faceQuality.indentLeft.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.indentLeft.threshold.min').asInt().ifNaN(undefined),
              max: get('faceQuality.indentLeft.threshold.max').asInt().ifNaN(undefined),
            },
          },
          indent_right: {
            estimate: get('faceQuality.indentRight.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.indentRight.threshold.min').asInt().ifNaN(undefined),
              max: get('faceQuality.indentRight.threshold.max').asInt().ifNaN(undefined),
            },
          },
          indent_upper: {
            estimate: get('faceQuality.indentUpper.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.indentUpper.threshold.min').asInt().ifNaN(undefined),
              max: get('faceQuality.indentUpper.threshold.max').asInt().ifNaN(undefined),
            },
          },
          indent_lower: {
            estimate: get('faceQuality.indentLower.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.indentLower.threshold.min').asInt().ifNaN(undefined),
              max: get('faceQuality.indentLower.threshold.max').asInt().ifNaN(undefined),
            },
          },
          image_size: {
            estimate: get('faceQuality.imageSize.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.imageSize.threshold.min').asInt().ifNaN(undefined),
              max: get('faceQuality.imageSize.threshold.max').asInt().ifNaN(undefined),
            },
          },
          eyebrows_state: {
            estimate: get('faceQuality.eyebrowsState.isEnabled').asInt(),
            threshold: get('faceQuality.eyebrowsState.threshold').asArrayOf(optionValue).ifEmpty(undefined),
          },
          headwear_type: {
            estimate: get('faceQuality.headwearType.isEnabled').asInt(),
            threshold: get('faceQuality.headwearType.threshold').asArrayOf(optionValue).ifEmpty(undefined),
          },
          smile_properties: {
            estimate: get('faceQuality.smileProperties.isEnabled').asInt(),
            threshold: get('faceQuality.smileProperties.threshold').asArrayOf(optionValue).ifEmpty(undefined),
          },
          face_color_type: {
            estimate: get('faceQuality.faceColorType.isEnabled').asInt(),
            threshold: get('faceQuality.faceColorType.threshold').asArrayOf(optionValue).ifEmpty(undefined),
          },
          natural_light: {
            estimate: get('faceQuality.naturalLight.isEnabled').asInt(),
            threshold: get('faceQuality.naturalLight.threshold').as(optionValue),
          },
          red_eyes: {
            estimate: get('faceQuality.redEyes.isEnabled').asInt(),
            threshold: get('faceQuality.redEyes.threshold').as(optionValue),
          },
          radial_distortion: {
            estimate: get('faceQuality.radialDistortion.isEnabled').asInt(),
            threshold: get('faceQuality.radialDistortion.threshold').as(optionValue),
          },
          illumination_uniformity: {
            estimate: get('faceQuality.illuminationUniformity.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.illuminationUniformity.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.illuminationUniformity.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          dynamic_range: {
            estimate: get('faceQuality.dynamicRange.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.dynamicRange.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.dynamicRange.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          background_uniformity: {
            estimate: get('faceQuality.backgroundUniformity.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.backgroundUniformity.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.backgroundUniformity.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          background_lightness: {
            estimate: get('faceQuality.backgroundLightness.isEnabled').asInt(),
            threshold: {
              min: get('faceQuality.backgroundLightness.threshold.min').asFloat().ifNaN(undefined),
              max: get('faceQuality.backgroundLightness.threshold.max').asFloat().ifNaN(undefined),
            },
          },
          shoulders_position: {
            estimate: get('faceQuality.shoulders_position.isEnabled').asInt(),
            threshold: get('faceQuality.shoulders_position.threshold').asArrayOf(optionValue).ifEmpty(undefined),
          },
        },
      },
      body_attributes: get('estimate.body_attributes').asSchema(bodyAttributesSchema).ifEmpty(undefined),
    },
    extract_policy: {
      extract_basic_attributes: get('extract.basicAttributes').asInt().ifNaN(undefined),
      fd_score_threshold: get('filters.faceDescriptorScoreThreshold').asFloat().ifNaN(undefined),
      extract_face_descriptor: get('extract.faceDescriptor').asInt().ifNaN(undefined),
      extract_body_descriptor: get('extract.bodyDescriptor').asInt().ifNaN(undefined),
    },
    match_policy: get('matchPolicy', []).asArrayOf((item) => {
      switch (get('origin.value')(item)) {
        case 'faces': return faceMatchPolicySchema(item);
        case 'events': return eventMatchPolicySchema(item);
        default: return item;
      }
    }).ifEmpty(undefined),
    conditional_tags_policy: get('tagPolicy', []).asArrayOf(new Schema({
      tag: get('tag'),
      filters: filterSchema('filters'),
    })).ifEmpty(undefined),
    storage_policy: {
      face_sample_policy: {
        filters: filterSchema('faceSamplePolicy.filters'),
        store_sample: get('faceSamplePolicy.isEnabled').asInt(),
        ttl: get('faceSamplePolicy.ttl').asOptionValue(),
      },
      body_sample_policy: {
        filters: filterSchema('bodySamplePolicy.filters'),
        store_sample: get('bodySamplePolicy.isEnabled').asInt(),
        ttl: get('bodySamplePolicy.ttl').asOptionValue(),
      },
      image_origin_policy: {
        filters: filterSchema('imageOriginPolicy.filters'),
        store_image: get('imageOriginPolicy.isEnabled').asInt(),
        use_external_references: get('imageOriginPolicy.useExternalReference').asInt(),
        ttl: get('imageOriginPolicy.ttl').asOptionValue(),
      },
      attribute_policy: {
        filters: filterSchema('attributesPolicy.filters'),
        store_attribute: get('attributesPolicy.isEnabled').asInt(),
        ttl: get('attributesPolicy.ttl').asInt().ifNaN(undefined),
      },
      face_policy: {
        filters: filterSchema('facePolicy.filters'),
        store_face: get('facePolicy.isEnabled').asInt(),
        set_sample_as_avatar: get('facePolicy.useSampleAsAvatar').asInt(),
        link_to_lists_policy: get('faceToListPolicy.lists').asArrayOf(new Schema({
          list_id: get('list.value'),
          filters: filterSchema('filters'),
        })).ifEmpty(undefined),
      },
      event_policy: {
        filters: filterSchema('eventPolicy.filters'),
        store_event: get('eventPolicy.isEnabled').asInt(),
        wait_saving: get('eventPolicy.waitSaving').asInt(),
      },
      notification_policy: {
        filters: filterSchema('notificationPolicy.filters'),
        send_notification: get('notificationPolicy.isEnabled').asInt(),
      },
      callbacks: get('callbacksPolicy', []).asArrayOf(callbackSchema).ifEmpty(undefined),
    },
  },
});

export const create = (obj) => {
  if (obj.isDynamic) return dynamicSchema(obj);
  return staticSchema(obj);
};
