import React, { useEffect, useRef } from 'react';

import { GENDER_OPTIONS, MASK_OPTIONS, DEEPFAKE_STATE_OPTIONS, EMOTION_OPTIONS } from '@vlabs/api-bindings/src/constants';
import { LIVENESS_OPTIONS, EVENT_ENUMS } from '@vlabs/api-bindings/src/luna-client/constants';
import { CrossIcon, PlusIcon } from '@vlabs/icons';
import { START_OF_DAY, END_OF_DAY } from '@vlabs/shared/config';
import validate from '@vlabs/shared/validators';
import { Control, SettingsItemWrapper } from '@vlabs/uikit';
import { useFormContext, useWatch, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import nextId from 'react-id-generator';
import { connect } from 'react-redux';

import { selectSourceOptionsByAccount } from '@vlabs/pages/sources/selectors';

import { filterFieldOptions, filterFunctionOptions } from '../constants';
import st from './ClusteringForm.module.sass';
import { GeolocationSubForm } from './GeolocationSubForm';

const functionsSets = {
  likeNlike: ['like', 'nlike'],
  inNin: ['in', 'nin'],
  eqNeq: ['eq', 'neq'],
  gtGteLtLte: ['gt', 'gte', 'lt', 'lte'],
  covered: ['covered'],
};

const commonFunctions = {
  inNinEqNeq: [...functionsSets.inNin, ...functionsSets.eqNeq],
  inNinEqNeqGtGteLtLte: [...functionsSets.inNin, ...functionsSets.eqNeq, ...functionsSets.gtGteLtLte],
};

const fieldFunctionConfig = {
  tags: { availableFunctions: functionsSets.likeNlike, format: 'input' },
  source: { availableFunctions: commonFunctions.inNinEqNeq, format: 'select' },
  stream_id: { availableFunctions: commonFunctions.inNinEqNeq, format: 'input', validateRule: validate.uuid },
  user_data: { availableFunctions: commonFunctions.inNinEqNeq, format: 'input' },
  event_id: { availableFunctions: commonFunctions.inNinEqNeqGtGteLtLte, format: 'input', validateRule: validate.uuid },
  external_id: { availableFunctions: commonFunctions.inNinEqNeqGtGteLtLte, format: 'input' },
  handler_id: { availableFunctions: commonFunctions.inNinEqNeqGtGteLtLte, format: 'input', validateRule: validate.uuid },
  top_similar_object_id: { availableFunctions: commonFunctions.inNinEqNeqGtGteLtLte, format: 'input', validateRule: validate.uuid },
  top_similar_external_id: { availableFunctions: commonFunctions.inNinEqNeqGtGteLtLte, format: 'input', validateRule: validate.uuid },
  top_matching_candidates_label: { availableFunctions: commonFunctions.inNinEqNeq, format: 'input' },
  top_similar_object_similarity: { availableFunctions: functionsSets.gtGteLtLte, format: 'input', validateRule: validate.number },
  face_id: { availableFunctions: commonFunctions.inNinEqNeqGtGteLtLte, format: 'input', validateRule: validate.uuid },
  gender: { availableFunctions: commonFunctions.inNinEqNeqGtGteLtLte, format: 'select' },
  age: { availableFunctions: commonFunctions.inNinEqNeqGtGteLtLte, format: 'input', validateRule: validate.integer },
  emotion: { availableFunctions: commonFunctions.inNinEqNeqGtGteLtLte, format: 'select' },
  mask: { availableFunctions: commonFunctions.inNinEqNeqGtGteLtLte, format: 'select' },
  liveness: { availableFunctions: commonFunctions.inNinEqNeqGtGteLtLte, format: 'select' },
  deepfake: { availableFunctions: commonFunctions.inNinEqNeqGtGteLtLte, format: 'select' },
  city: { availableFunctions: commonFunctions.inNinEqNeq, format: 'input' },
  area: { availableFunctions: commonFunctions.inNinEqNeq, format: 'input' },
  district: { availableFunctions: commonFunctions.inNinEqNeq, format: 'input' },
  street: { availableFunctions: commonFunctions.inNinEqNeq, format: 'input' },
  house_number: { availableFunctions: commonFunctions.inNinEqNeq, format: 'input' },
  geo_position: { availableFunctions: functionsSets.covered, format: 'geolocation' },
  track_id: { availableFunctions: commonFunctions.inNinEqNeq, format: 'input', validateRule: validate.multipleUuid },
  apparent_age: { availableFunctions: commonFunctions.inNinEqNeqGtGteLtLte, format: 'input', validateRule: validate.integer },
  apparent_gender: { availableFunctions: commonFunctions.inNinEqNeqGtGteLtLte, format: 'select' },
  sleeve_length: { availableFunctions: commonFunctions.inNinEqNeq, format: 'select' },
  headwear_state: { availableFunctions: commonFunctions.inNinEqNeqGtGteLtLte, format: 'select' },
  upper_clothing_colors: { availableFunctions: [...functionsSets.inNin], format: 'select' },
  backpack_state: { availableFunctions: commonFunctions.inNinEqNeqGtGteLtLte, format: 'select' },
  lower_garment_colors: { availableFunctions: [...functionsSets.inNin], format: 'select' },
  headwear_apparent_color: { availableFunctions: commonFunctions.inNinEqNeq, format: 'select' },
  lower_garment_type: { availableFunctions: commonFunctions.inNinEqNeq, format: 'select' },
  shoes_apparent_color: { availableFunctions: commonFunctions.inNinEqNeq, format: 'select' },
  meta: { availableFunctions: [...functionsSets.likeNlike, ...commonFunctions.inNinEqNeqGtGteLtLte], format: 'input' },
};

const getAvailableFunctions = (field) => {
  const availableFunctions = fieldFunctionConfig[field]?.availableFunctions || [];

  return filterFunctionOptions.raw.filter((option) => availableFunctions.includes(option.value));
};

const FiltersFormComponent = ({ camOptions }) => {
  const { t } = useTranslation();
  const { control, setValue, clearErrors, register, formState: { errors } } = useFormContext();

  const { fields, append, remove } = useFieldArray({ control, name: 'filters' });
  const filters = useWatch({ control, name: 'filters' }) || [];
  const previousFilterColumnsRef = useRef([]);

  const optionsForSelect = {
    source: camOptions,
    gender: GENDER_OPTIONS.raw,
    emotion: EMOTION_OPTIONS.raw,
    mask: MASK_OPTIONS.raw,
    liveness: LIVENESS_OPTIONS.raw,
    deepfake: DEEPFAKE_STATE_OPTIONS.raw,
    apparent_gender: EVENT_ENUMS.BODY_BASIC_ATTRIBUTES.APPARENT_GENDER.raw,
    sleeve_length: EVENT_ENUMS.UPPER_BODY.SLEEVE.raw,
    headwear_state: EVENT_ENUMS.UPPER_BODY.HEADWEAR.raw,
    upper_clothing_colors: EVENT_ENUMS.UPPER_BODY.UPPER_CLOSING_COLOR.raw,
    backpack_state: EVENT_ENUMS.ACCESSORIES.BACKPACK.raw,
    lower_garment_colors: EVENT_ENUMS.LOWER_BODY.LOWER_GARMENT.COLORS.raw,
    headwear_apparent_color: EVENT_ENUMS.UPPER_BODY.HEADWEAR_COLORS.raw,
    lower_garment_type: EVENT_ENUMS.LOWER_BODY.LOWER_GARMENT.TYPE.raw,
    shoes_apparent_color: EVENT_ENUMS.LOWER_BODY.SHOES.APPARENT_COLOR.raw,
  };

  useEffect(() => {
    filters?.forEach((filter) => {
      const { id, column } = filter;
      const previousColumnValue = previousFilterColumnsRef.current?.[id]?.column?.value;
      const columnValue = column?.value;

      if (previousColumnValue === columnValue) return;
      const index = filters.findIndex((f) => f.id === id);

      const availableFunctions = getAvailableFunctions(columnValue);
      const operator = availableFunctions.length === 1 ? availableFunctions[0] : null;

      setValue(`filters.${index}.operator`, operator);
      setValue(`filters.${index}.value`, null);

      clearErrors('filters');
      previousFilterColumnsRef.current[id] = filter;
    });
  }, [filters, setValue]);

  return (
    <div>
      <div className={st.Title}>{t('statistic:form.filters.period.label')}</div>
      <SettingsItemWrapper
        contentClassName={st.SettingsItem}
        title={t('statistic:form.filters.period.from')}
      >
        <Control.DateInput
          control={control}
          enableTime
          name="period.gt"
          options={START_OF_DAY}
        />
      </SettingsItemWrapper>

      <SettingsItemWrapper
        contentClassName={st.SettingsItem}
        title={t('statistic:form.filters.period.to')}
      >
        <Control.DateInput
          control={control}
          enableTime
          name="period.lt"
          options={END_OF_DAY}
        />
      </SettingsItemWrapper>

      <div className={st.Title}>{t('statistic:form.filters.data.label')}</div>
      {fields.map((field, i) => {
        const { column, operator, value } = filters[i] || {};
        const columnValue = column?.value;
        const valueConfig = fieldFunctionConfig[columnValue];

        const handleGeolocationChange = (newValue) => {
          setValue(`filters.${i}.value`, newValue);
          clearErrors(`filters.${i}.value`);
        };

        return (
          <div key={field.id}>
            <SettingsItemWrapper
              contentClassName={st.SettingsItem}
              title={t('statistic:form.filters.field.label')}
            >
              <Control.Select
                control={control}
                errors={errors}
                name={`filters.${i}.column`}
                options={filterFieldOptions.raw}
                rules={{ required: validate.required() }}
              />
            </SettingsItemWrapper>
            <SettingsItemWrapper
              contentClassName={st.SettingsItem}
              data-tooltip-content={!columnValue && t('statistic:form.filters.function.tooltip')}
              disabled={!columnValue}
              title={t('statistic:form.filters.function.label')}
            >
              <Control.Select
                control={control}
                disabled={!columnValue}
                errors={errors}
                name={`filters.${i}.operator`}
                options={getAvailableFunctions(columnValue)}
                rules={{ required: validate.required() }}
              />
            </SettingsItemWrapper>
            <SettingsItemWrapper
              contentClassName={st.SettingsItem}
              data-tooltip-content={!operator && t('statistic:form.filters.value.tooltip')}
              disabled={!operator}
              title={t('statistic:form.filters.value.label')}
            >
              {valueConfig?.format === 'select' && (
                <Control.Select
                  control={control}
                  disabled={!operator}
                  errors={errors}
                  name={`filters.${i}.value`}
                  options={optionsForSelect?.[columnValue]}
                  rules={{ required: validate.required() }}
                />
              )}
              {valueConfig?.format === 'geolocation' && (
                <>
                  <GeolocationSubForm disabled={!operator} initialValues={value} onSubmit={handleGeolocationChange} />
                  <Control.Checkbox
                    className={st.Hidden}
                    errors={errors}
                    id={`filters.${i}.value`}
                    {...register(`filters.${i}.value`, {
                      required: validate.required(),
                    })}
                  />
                </>
              )}
              {(!valueConfig?.format || valueConfig?.format === 'input') && (
                <Control.Input
                  {...register(`filters.${i}.value`, {
                    required: validate.required(),
                    validate: valueConfig?.validateRule && valueConfig?.validateRule(),
                  })}
                  disabled={!operator}
                  errors={errors}
                />
              )}
            </SettingsItemWrapper>
            <div className={st.DeleteButton}>
              <Control.Button
                icon={<CrossIcon />}
                kind="negative"
                onClick={() => remove(i)}
                variant="flat"
              >
                {t('statistic:btn.remove')}
              </Control.Button>
            </div>
          </div>
        );
      })}
      <Control.Button
        icon={<PlusIcon />}
        onClick={() => append({ id: nextId(), column: '', value: '', operator: '' })}
        variant="flat"
      >
        {t('statistic:btn.add field')}
      </Control.Button>
    </div>
  );
};

export const FiltersForm = connect((state) => ({
  camOptions: selectSourceOptionsByAccount(state),
}))(FiltersFormComponent);
