import React, { useState, useEffect, useCallback, useRef } from 'react';

import { defaultHandler } from '@vlabs/pages/handlers/defaultHandler';
import { CreatedHandlerForm } from '@vlabs/pages/handlers/preconfigured-scenarios/index';
import {
  StepCommon,
  StepFaceEstimations,
  StepBodyEstimations,
  StepFilters,
  StepMatchPolicy,
  StepStoragePolicies,
  StepTagPolicy,
  StepFaceQuality,
  StepDeepfake,
  StepLiveness,
  StepPeopleCount,
  StepCallbacksPolicy,
} from '@vlabs/pages/handlers/preconfigured-scenarios/other/steps';
import { useSteppedForm } from '@vlabs/shared/hooks';
import { selectListOptions } from '@vlabs/shared/selectors/listSelectors';
import { Page, Stepper, Modal } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectHandlerOptions } from '@vlabs/pages/handlers/selectors';
import { createHandler } from '@vlabs/pages/handlers/store';

import st from './OtherScenarioStepper.module.sass';

const steps = [
  StepCommon,
  StepFaceEstimations,
  StepLiveness,
  StepDeepfake,
  StepFaceQuality,
  StepBodyEstimations,
  StepPeopleCount,
  StepFilters,
  StepMatchPolicy,
  StepStoragePolicies,
  StepTagPolicy,
  StepCallbacksPolicy,
];

function OtherScenarioStepper({
  onSubmit,
  handlerOptions,
  listOptions,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const [createdModalIsOpen, setCreatedModalIsOpen] = useState(false);
  const [createdHandlerId, setCreatedHandlerId] = useState();
  const [createdHandlerName, setCreatedHandlerName] = useState();
  const form = useForm({
    defaultValues: defaultHandler,
    mode: 'onChange',
  });
  const { reset, handleSubmit } = form;
  const prevForm = useRef(defaultHandler);
  useEffect(() => { prevForm.current = defaultHandler; reset(defaultHandler); }, [reset, prevForm]);

  const onSaveHandler = useCallback(async (values) => {
    setCreatedHandlerId(await onSubmit(values));
    setCreatedHandlerName(values?.description);
    setCreatedModalIsOpen(true);
  }, [onSubmit, setCreatedModalIsOpen]);

  const canGoNextStep = useCallback(() => !(document.querySelector('.ReactModal__Content--after-open')), []);
  const stepper = useSteppedForm({
    form,
    canGoNextStep,
    steps,
    onSubmit: onSaveHandler,
    buttonsClass: st.StepperButtons,
  });

  const onCloseCreatedModal = () => {
    setCreatedModalIsOpen(false);
    history.goBack();
  };

  const context = {
    ...form,
    listOptions,
    handlerOptions,
  };

  return (
    <Page title={t('handlers:заголовок.создание')}>
      <FormProvider {...context}>
        <form onSubmit={handleSubmit(onSaveHandler)}>
          <Modal
            appElement={document.getElementById('root')}
            data-testid="modal.saveOtherHandler"
            isOpen={createdModalIsOpen}
            onRequestClose={onCloseCreatedModal}
            size="small"
          >
            <CreatedHandlerForm
              handlerId={createdHandlerId}
              handlerName={createdHandlerName}
            />
          </Modal>
          <Stepper {...stepper} className={st.StepperWrapper}>
            {steps.map((Step) => <Step key={Step.name} />)}
          </Stepper>
        </form>
      </FormProvider>
    </Page>
  );
}

OtherScenarioStepper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handlerOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  listOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  values: PropTypes.objectOf(PropTypes.any),
};

OtherScenarioStepper.defaultProps = {
  handlerOptions: [],
  listOptions: [],
  values: undefined,
};

export default connect((state) => ({
  handlerOptions: selectHandlerOptions(state),
  listOptions: selectListOptions(state),
}),
(dispatch) => ({
  onSubmit: (data) => dispatch(createHandler(data)),
}))(OtherScenarioStepper);
