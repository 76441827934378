import { createSlice } from '@reduxjs/toolkit';

import { SLICE_NAME } from './constants';

const initialState = {
  pageData: {
    data: [],
  },
  pageSize: 25,
  pageSizeOptions: [10, 25, 50],
  state: 'notAsked',
};

const store = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setPageData(state, { payload }) {
      state.pageData = payload;
      state.state = 'loaded';
    },
    setPageSize(state, { payload }) {
      state.pageSize = payload;
    },
  },
});

export default store.reducer;

export const { setPageData, setPageSize } = store.actions;
