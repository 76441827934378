import React, { Children, useRef, useEffect, useState } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from '../controls/button/Button';
import { Group } from '../group/Group';
import { STEP_STATUSES } from './constants';
import { StepperHeadItem } from './StepperHeadItem';

import './Stepper.sass';

const Stepper = ({
  children,
  className,
  activeStep: inActiveStep,
  controls,
  isError,
}) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(inActiveStep);

  const animateLineDirection = useRef('none');

  const childrenArray = Children.toArray(children);
  const isLastStep = activeStep === childrenArray.length - 1;
  const isFirstStep = activeStep === 0;

  const nextStep = () => {
    animateLineDirection.current = 'forward';
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const prevStep = () => {
    animateLineDirection.current = 'backward';
    setActiveStep((prevActiveStep) => Math.max(0, prevActiveStep - 1));
  };

  const goToStep = (stepNum) => {
    let currentStep = 0;
    if (stepNum <= childrenArray.length && stepNum >= 0) {
      currentStep = stepNum;
    }

    setActiveStep(currentStep);
  };

  useEffect(() => {
    goToStep(inActiveStep);
  }, [inActiveStep]);

  const context = { isLastStep, isFirstStep, activeStep, nextStep, prevStep, goToStep };

  const getStepState = (index, active, error) => {
    if (
      index < active
    ) {
      return STEP_STATUSES.finished;
    }

    if (index > active) {
      return STEP_STATUSES.unstarted;
    }

    if (index === active && error) {
      return STEP_STATUSES.error;
    }

    return STEP_STATUSES.started;
  };

  return (
    <div className="Stepper">
      <div className="StepperHead">
        {childrenArray.map((child, index) => (
          <React.Fragment key={`stepperHead${index + 1}`}>
            <div
              className={cn({
                StepperHead__Item: true,
                StepperHead__Item_inactive: index > activeStep,
                StepperHead__Item_lastStep: index === childrenArray.length - 1,
              })}
            >
              <StepperHeadItem state={getStepState(index, activeStep, isError)} />

              <div className="StepperHead__ItemName Small-1">
                {child.props?.stepName || t('uikit:stepper.шаг', { count: index + 1 })}
              </div>
            </div>

            {index < childrenArray.length - 1 && (
              <div className={cn({
                StepperHead__Divider: true,
                StepperHead__Divider_filled: index < activeStep,
                StepperHead__Divider_animate_forward: index === activeStep - 1 && animateLineDirection.current === 'forward',
                StepperHead__Divider_animate_backward: index === activeStep && animateLineDirection.current === 'backward',

              })}
              />
            )}
          </React.Fragment>
        ))}
      </div>

      <div className={className}>
        {childrenArray.map((step, index) => (
          <div
            className={cn({
              Stepper__Content: true,
              Stepper__Content_active: activeStep === index,
            })}
            data-index={index}
            data-testid={`stepContent${index + 1}`}
            key={`stepContent${index + 1}`}
          >
            {step}
          </div>
        ))}

        {controls && controls(context)}
        {!controls && (
          <Group className="Stepper__ButtonContainer">
            <Button
              disabled={isFirstStep}
              onClick={prevStep}
              variant="outlined"
            >
              {t('uikit:stepper.действия.переход на шаг назад')}
            </Button>

            <Button
              disabled={isLastStep}
              onClick={nextStep}
              variant="outlined"
            >
              {t('uikit:stepper.действия.переход на шаг вперед')}
            </Button>
          </Group>
        )}
      </div>
    </div>
  );
};

Stepper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  activeStep: PropTypes.number,
  controls: PropTypes.func,
  isError: PropTypes.bool,
};

Stepper.defaultProps = {
  className: undefined,
  activeStep: 0,
  controls: undefined,
  isError: false, // Will be used in future versions, sets step error icon.
};

export { Stepper };
