import React from 'react';

import { CircleEmptyIcon, CircleCheckFilledIcon, CircleCrossFilledIcon } from '@vlabs/icons';
import cn from 'classnames';

import { STEP_STATUSES } from './constants';

const StepperHeadItem = ({ state }) => {
  return (

    <div className="StepperHead__Icon">

      <CircleEmptyIcon
        className={cn('StepperHead__Icon StepperHead__Icon_unstarted',
          { visible: state === STEP_STATUSES.unstarted || state === STEP_STATUSES.disabled },
        )}
        viewBox="1 1 22 22"
      />
      <div
        className={cn('StepperHead__Icon StepperHead__Icon_started',
          { visible: state === STEP_STATUSES.started },
        )}

      />
      <CircleCheckFilledIcon
        className={cn('StepperHead__Icon StepperHead__Icon_finished',
          { visible: state === STEP_STATUSES.finished },
        )}
        viewBox="1 1 22 22"
      />
      <CircleCrossFilledIcon
        className={cn('StepperHead__Icon StepperHead__Icon_error',
          { visible: state === STEP_STATUSES.error },
        )}
        viewBox="1 1 22 22"
      />

    </div>
  );
};

export { StepperHeadItem };
