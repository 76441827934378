import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import './Modal.sass';

const Modal = ({
  className,
  overlayClassName,
  bodyOpenClassName,
  appElement,
  size,
  'data-testid': testId,
  scrollable,
  ...props
}) => {
  return (
    <ReactModal
      appElement={appElement || document.getElementById('root')}
      ariaHideApp={false}
      bodyOpenClassName={bodyOpenClassName}
      className={cn(
        className,
        `Modal--${size}`,
        {
          'Modal--scrollable': scrollable,
        },
      )}
      closeTimeoutMS={300}
      overlayClassName={overlayClassName}
      testId={testId}
      {...props}
    />
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  bodyOpenClassName: PropTypes.string,
  appElement: PropTypes.object, // PropTypes.node не подходит
  'data-testid': PropTypes.string,
  size: PropTypes.oneOf(['large', 'small']),
  scrollable: PropTypes.bool,
};

Modal.defaultProps = {
  className: 'Modal',
  overlayClassName: 'Modal__Overlay',
  bodyOpenClassName: 'Modal__DocumentBody',
  appElement: undefined,
  'data-testid': 'modal',
  size: 'large',
  scrollable: true,
};

export { Modal };
