import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const MapIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="MapIcon" ref={iconRef} viewBox="0 0 24 24" {...props}>
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth} d="M1 6v16l7-4 8 4 7-4V2l-7 4-8-4-7 4Zm7-4v16m8-12v16" />
    </IconInner>
  );
};

export const MapIcon = memo(MapIconInner);
