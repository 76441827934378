import { Schema, get } from '../../json-transform';

const streamIdOptionValue = (option) => option?.streamId;

export const getStreamsLogsQS = new Schema({
  log_time__gte: get('log_time__gte.0').asISOString(),
  log_time__lt: get('log_time__lt.0').asISOString(),
  account_id: get('account_id').ifEmptyString(undefined),
  stream_ids: get('stream_ids').asArrayOf(streamIdOptionValue).ifEmpty(undefined),
  statuses: get('statuses').asArrayFromString().ifEmptyString(undefined),
  page: get('page').asInt(),
  page_size: get('page_size').asInt(),
});
