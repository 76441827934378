import { createSelector } from '@reduxjs/toolkit';
import { selectStreamOptions } from '@vlabs/fsd-kit/entities/sources/luna-streams/store/streams';
import { roles } from '@vlabs/shared/config';

import { selectAccountRole } from '@vlabs/pages/auth/selectors';
import { selectAccountDepartments } from '@vlabs/pages/departments/selectors';

import { selectSourceOptions as selectVLAccessSourceOptions } from './vl-access/selectors';

const createOptionsGroup = (label, options) => {
  if (!options?.length) return null;

  return {
    label,
    options,
  };
};

export const selectLunaStreamSourceOptionsByAccount = createSelector(
  selectAccountDepartments,
  selectStreamOptions,
  selectAccountRole,
  (departments, streams, role) => {
    if (role === roles.ADMIN) return streams;

    const allowedStreamIds = [];

    if (departments === null) {
      return streams;
    }

    if (departments.length) {
      departments.forEach(({ cameraIds }) => cameraIds.forEach((camId) => {
        if (!allowedStreamIds.includes(camId)) allowedStreamIds.push(camId);
      }));
    }

    return streams.filter(({ streamId }) => allowedStreamIds.includes(streamId));
  },
);

export const selectSourceOptionsByAccount = createSelector(
  selectVLAccessSourceOptions,
  selectLunaStreamSourceOptionsByAccount,
  (vlAccessOptions, lsOptions) => {
    const rawGroups = [
      createOptionsGroup('LunaStreams', lsOptions),
      createOptionsGroup('VLAccess', vlAccessOptions),
    ];

    return rawGroups.filter((group) => !!group);
  },
);

export const selectVLAccessOptionsByAccount = createSelector(
  selectVLAccessSourceOptions,
  (vlAccessOptions) => {
    return [createOptionsGroup('VLAccess', vlAccessOptions)].filter((group) => !!group);
  });

export const selectStreamIdOptionsByAccount = createSelector(
  selectLunaStreamSourceOptionsByAccount,
  (streamOptions) => {
    return streamOptions.map(({ streamId, label }) => ({ label, value: streamId }));
  });
