import React, { useState, useCallback, useEffect } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { CircleInfoIcon } from '@vlabs/icons';
import { MatchTypeImage } from '@vlabs/shared/assets/images';
import { SampleWithActions } from '@vlabs/shared/components/sample/SampleWithActions';
import { SimilarityTag } from '@vlabs/shared/components/similarity-tag/SimilarityTag';
import { EventInfoCell } from '@vlabs/shared/legacy-components/event-info-cell/EventInfoCell';
import InfoRow from '@vlabs/shared/legacy-components/info-row/InfoRow';
import { useTranslation } from 'react-i18next';
import './EventTopMatchCell.sass';

export const EventTopMatchCell = ({
  topMatch,
  onClick,
}) => {
  const { t } = useTranslation();
  const [event, setEvent] = useState({ ...topMatch, isExist: true });

  const fetchEvent = useCallback(async () => {
    try {
      const { data } = await apiContainer.lunaClient.events.get(topMatch?.event_id);
      setEvent({ ...data, ...event });
    } catch ({ error }) {
      // Если событие удалено - 'Object not found'
      if (error.response.data.error_code === 23001) {
        setEvent({ ...event, isExist: false });
        console.error('Event not found:', error);
      } else {
        throw error;
      }
    }
  }, [topMatch?.event_id]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { fetchEvent(); }, []);

  return (
    <>
      {event?.isExist === true && (
        <div className="EventTopMatch__Sample">
          {event?.uiFaceDetection && (
            <SampleWithActions
              data-testid="topMatchSample"
              dataTip={t('events:проверьте настройки сценария')}
              imageUrl={event.uiFaceDetection?.uiSampleURL}
              onGoToClick={onClick}
              type="face"
            />
          )}
          {event?.uiBodyDetection && (
            <SampleWithActions
              data-testid="topMatchSample"
              dataTip={t('events:проверьте настройки сценария')}
              imageUrl={event.uiBodyDetection?.uiSampleURL}
              onGoToClick={onClick}
              type="body"
            />
          )}
        </div>
      )}
      <div className="EventTopMatch__Info">
        <SimilarityTag
          className="EventTopMatch__SimilarityTag"
          data-testid="TopMatch__SimilarityTag"
          value={event?.similarity}
        />
        <InfoRow
          columnDirection
          data-testid="topMatchCell.matchType"
          icon={<img alt="match type icon" className="StaticIcon" src={MatchTypeImage} />}
          title={t('events:match type')}
          value={t('event')}
        />
        <EventInfoCell original={event} />

        {!event?.isExist && (
          <div className="EventTopMatchCell__DeletedEvent">
            <CircleInfoIcon className="StaticIcon" />
            {t('events:event deleted')}
          </div>
        )}
      </div>
    </>
  );
};
