/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect, useContext, useCallback, useState } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { CircleInfoIcon } from '@vlabs/icons';
import { ButtonToggleWithPermissions } from '@vlabs/shared/components/button-toggle-with-permissions/ButtonToggleWithPermissions';
import { PhotoUploadForm } from '@vlabs/shared/components/photo-upload-form';
import { permissions } from '@vlabs/shared/config';
import { detectOnImageRequest } from '@vlabs/shared/requests/detectOnImageRequest';
import { DETECTION_TYPES } from '@vlabs/shared/requests/detectOnImageRequest/constants';
import validate from '@vlabs/shared/validators';
import { ButtonToggle, ButtonToggleGroup, Control, Divider, ListPicker } from '@vlabs/uikit';
import i18n from 'i18next';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { viewerCan } from '@vlabs/pages/auth/selectors';

import { SearchContext } from '../search-context';
import st from '../SearchPage.module.sass';
import { EventPreview } from './EventPreview';
import { FacePreview } from './FacePreview';

const referenceTypeByInput = {
  image: 'sdk_descriptor',
  event: 'event',
  face: 'face_external_id',
};

const EventInput = ({ inputError, inputValue, onInputChange, resetInput }) => (
  <>
    <Control.Input
      data-testid="input.eventId"
      hasError={!!inputError}
      id="eventId"
      isClearable
      label={i18n.t('id события')}
      onChange={(e) => onInputChange(e?.target?.value, {
        validationError: validate.uuid()(e?.target?.value),
      })}
      onClear={resetInput}
      placeholder={i18n.t('id события')}
      value={inputValue}
    />
    {inputError && (<span className={st.ErrorMessage}>{inputError}</span>)}
  </>
);

const FaceInput = ({ referenceType, onReferenceTypeChange, inputError, inputValue, onInputChange, resetInput }) => (
  <>
    <div className={st.Subtitle}>{i18n.t('id лица')}</div>
    <ButtonToggleGroup
      className={st.ButtonToggle}
      itemClassName={st.ButtonToggle__Item}
      onChange={onReferenceTypeChange}
      testId="buttonSubgroup"
      value={referenceType}
    >
      <ButtonToggle value="face_external_id">{i18n.t('external id')}</ButtonToggle>
      <ButtonToggle value="face">{i18n.t('LUNA ID')}</ButtonToggle>
    </ButtonToggleGroup>

    <Divider small />

    {referenceType === 'face_external_id' && (
      <Control.Input
        data-testid="input.externalId"
        isClearable
        onChange={(e) => onInputChange(e?.target?.value, { debounced: true })}
        onClear={resetInput}
        placeholder={i18n.t('external id')}
        value={inputValue}
      />
    )}

    {referenceType === 'face' && (
      <>
        <Control.Input
          data-testid="input.faceId"
          hasError={!!inputError}
          isClearable
          onChange={(e) => onInputChange(e?.target?.value, {
            validationError: validate.uuid()(e?.target?.value),
          })}
          onClear={resetInput}
          placeholder={i18n.t('id лица')}
          value={inputValue}
        />
        {inputError && (<span className={st.ErrorMessage}>{inputError}</span>)}
      </>
    )}
  </>
);
const ReferenceSelectorComponent = ({ can }) => {
  const { t } = useTranslation();
  const {
    setMatcherType,
    inputType,
    setInputType,
    setFile,
    file,
    setDetections,
    detections,
    setInputError,
    inputError,
    setInputValue,
    inputValue,
    referenceType,
    referencesPreview,
    setReferenceType,
    setReferences,
    setSelectedDetectionsId,
    selectedDetectionsId,
    resetSearchInfo,
    resetSearchState,
    resetMatchResults,
    setCandidateOrigin,
  } = useContext(SearchContext);
  const isPreviewExists = (file && detections?.all?.length > 0) || referencesPreview?.data?.length > 0;

  useEffect(() => {
    if (detections === undefined
      || detections?.all?.length === 0
      || detections?.length === 0
      || !(selectedDetectionsId?.length > 0)) return;

    const imageReferences = selectedDetectionsId.map((detectionId) => {
      setMatcherType(detections.byId[detectionId]?.type);
      if (detections.byId[detectionId].type === 'body' && can([permissions.event.view, permissions.event.matching], { method: 'allof' })) {
        setCandidateOrigin('events');
      }
      return {
        id: detectionId,
        type: referenceType,
        data: detections.byId[detectionId]?.attributes.descriptor.sdk_descriptor,
      };
    });

    setReferences(imageReferences);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detections, selectedDetectionsId, setReferences, referenceType]);

  useEffect(() => {
    if (!file) return;
    async function detectImage() {
      const imageDetections = await detectOnImageRequest(
        file,
        [DETECTION_TYPES.FACES, DETECTION_TYPES.BODIES],
        apiContainer.lunaClient.sdk.estimateFace,
        apiContainer.lunaClient.sdk.estimateBody,
      );
      setDetections(imageDetections);
    }
    detectImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const [selectedDetections, setSelectedDetections] = useState([]);

  const isSelected = useCallback((detectionId) => {
    return selectedDetections.some((d) => d.id === detectionId);
  }, [selectedDetections]);

  const onSelectHandler = useCallback((detectionId) => {
    setSelectedDetections([detections.all.find((detection) => detection.id === detectionId)]);
  }, [detections, setSelectedDetections]);

  const onReferenceTypeChange = (value) => {
    setReferenceType(value);
    setInputValue('');
    resetSearchInfo();
  };

  const onFileSelect = (newFile) => {
    if (!newFile) return;
    setFile(newFile);
  };

  const resetFile = () => {
    setFile(undefined);
    setDetections(undefined);
    setSelectedDetectionsId(undefined);
    resetSearchState();
  };

  const resetInput = () => {
    resetSearchState();
    setInputError(undefined);
    setInputValue('');
  };

  const setReferencesDebounced = useMemo(
    () => debounce(setReferences, 1000),
    [setReferences],
  );

  const onInputChange = (value, {
    validationError = undefined,
    debounced = false,
  }) => {
    setInputValue(value);
    if (!value) return resetInput();
    if (typeof validationError === 'string') {
      setInputError(validationError);
      return undefined;
    }

    setInputError(undefined);
    resetMatchResults();
    if (debounced) {
      setReferencesDebounced([{ id: value, type: referenceType }]);
    } else {
      setReferences([{ id: value, type: referenceType }]);
    }

    return undefined;
  };

  const onChangeDetections = (v) => {
    resetMatchResults();
    setSelectedDetectionsId(v);
  };

  const onChangeInputType = (v) => {
    setInputType(v);
    setReferenceType(referenceTypeByInput[v]);
    setInputValue('');
    resetSearchInfo();
    setMatcherType('face');
  };

  return (
    <>
      {/* Выбор типа поиска */}
      <div className={st.InfoCol_flex}>
        {/* Управление типом поиска */}
        <div className={st.ButtonToggleGroup}>
          <ButtonToggleWithPermissions
            className={st.ButtonToggle}
            itemClassName={st.ButtonToggle__Item}
            onChange={onChangeInputType}
            testId="inputTypeButtonGroup"
            value={inputType}
          >
            <ButtonToggle permissions={{ rules: [permissions.resources.sdk] }} value="image">{t('search:фотография')}</ButtonToggle>
            <ButtonToggle permissions={{ rules: [permissions.event.matching] }} value="event">{t('search:событие')}</ButtonToggle>
            <ButtonToggle permissions={{ rules: [permissions.face.matching] }} value="face">{t('search:лицо')}</ButtonToggle>
          </ButtonToggleWithPermissions>

          <div className={st.FileSelector_margin}>
            <div className={st.Col}>
              {inputType === 'image' && (
              <PhotoUploadForm
                detections={detections?.all}
                errorProps={{ errors: { message: detections?.length === 0 && t('search:ошибка.детекции отсутствуют') } }}
                file={file}
                isMultiDetections
                onFileSelect={onFileSelect}
                onResetFile={resetFile}
                onSelectSampleId={onChangeDetections}
                selectedStateProps={{ selectedDetections, setSelectedDetections }}
              />
              )}

              {inputType === 'event' && (
                <EventInput
                  inputError={inputError}
                  inputValue={inputValue}
                  onInputChange={onInputChange}
                  resetInput={resetInput}
                />
              )}

              {inputType === 'face' && (
                <FaceInput
                  inputError={inputError}
                  inputValue={inputValue}
                  onInputChange={onInputChange}
                  onReferenceTypeChange={onReferenceTypeChange}
                  referenceType={referenceType}
                  resetInput={resetInput}
                />
              )}
            </div>
          </div>
        </div>

        {/* Дополнительная информация */}
        <div className={st.Information}>
          {!isPreviewExists && (
            <div className={st.InfoPanel}>
              <CircleInfoIcon className={st.Icon} />

              <div className="Body-2">
                {referenceType === 'sdk_descriptor' && (
                  <>
                    {t('search:подсказка.загрузка фото')}
                    <br />
                    {t('search:подсказка.загрузка фото условие')}
                  </>
                )}
                {referenceType === 'face_external_id' && (
                  <>
                    {t('search:подсказка.внешний id')}
                    <br />
                    {t('search:подсказка.внешний id условие')}
                  </>
                )}
                {referenceType === 'event' && t('search:подсказка.event id')}
                {referenceType === 'face' && t('search:подсказка.face id')}
              </div>
            </div>
          )}

          {inputType === 'face' && referencesPreview?.data?.length > 0 && (
            <FacePreview />
          )}

          {inputType === 'event' && referencesPreview?.data?.length > 0 && (
            <EventPreview />
          )}

          {/* Дополнительный блок выбора референса */}
          {referenceType === 'sdk_descriptor'
          && file !== undefined
          && detections?.all?.length > 0 && (
          <div className={st.ListPicker_col}>
            {detections?.faces?.length > 0 && (
            <>
              <span className={st.Count} data-testid="detections_count">
                {t('search:number of detected faces', { count: detections.faces.length })}
              </span>
              <ListPicker
                detections={detections.faces}
                isSelected={isSelected}
                onSelect={onSelectHandler}
              />
              <Divider small />
            </>
            )}
            {detections?.bodies?.length > 0 && (
            <>
              <span className={st.Count} data-testid="detections_count">
                {t('search:number of detected bodies', { count: detections.bodies.length })}
              </span>
              <ListPicker
                detections={detections.bodies}
                isSelected={isSelected}
                onSelect={onSelectHandler}
              />
            </>
            )}
          </div>
          )}

        </div>
      </div>
    </>
  );
};

ReferenceSelectorComponent.propTypes = {
  // referenceType: PropTypes.string,
  referencesPreview: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.any),
    pageIndex: PropTypes.number,
  }),
  can: PropTypes.func.isRequired,
};

ReferenceSelectorComponent.defaultProps = {
  // referenceType: 'sdk_descriptor',
  referencesPreview: undefined,
};

export const ReferenceSelector = connect((state) => ({
  can: viewerCan(state),
}),
)(ReferenceSelectorComponent);
