import React, { useState, useEffect, useCallback } from 'react';

import { apiContainer } from '@vlabs/api-bindings';
import { createStream as createStreamAction, deleteStream as deleteStreamAction, updateStream as updateStreamAction, fetchGroups } from '@vlabs/fsd-kit/entities/sources/luna-streams/store/streams';
import StreamEditForm from '@vlabs/fsd-kit/features/sources/luna-streams/ui/streams';
import { routes } from '@vlabs/shared/navigation/routes';
import { UUID_PATTERN } from '@vlabs/shared/validators';
import { openConfirmPopup } from '@vlabs/uikit';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

function EditStreamPageComponent({
  streamId,
  createStream,
  updateStream,
  deleteStream,
  afterDeleteStream,
  afterCreateStream,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [streamData, setStreamData] = useState(undefined);
  const [isNotFound, setIsNotFound] = useState(false);

  useEffect(() => { dispatch(fetchGroups); }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (streamId === 'create') return;
      try {
        const data = await apiContainer.lunaStreamsClient.streams.get(streamId);
        setStreamData(data);
      } catch (error) {
        if (error?.response?.status === 404) setIsNotFound(true);
        else throw error;
      }
    })();
  }, [streamId, dispatch]);

  useEffect(() => {
    const keyDown = (e) => {
      if (e.key === 'Escape') {
        history.push(generatePath(routes.sources.lunaStreams.streams.view, { streamId }));
      }
    };
    document.removeEventListener('keydown', keyDown);
    document.addEventListener('keydown', keyDown);
    return () => {
      document.removeEventListener('keydown', keyDown);
    };
  }, [history, streamId]);

  const onDelete = useCallback(() => {
    const message = <span>{t('lunaStreamsStreams:форма.удаление.текст')}</span>;

    const onConfirm = async () => {
      await deleteStream(streamId);
      afterDeleteStream();
    };

    openConfirmPopup({
      title: t('lunaStreamsStreams:форма.удаление.заголовок'),
      message,
      type: 'delete',
      onConfirm,
    });
  }, [streamId, deleteStream, afterDeleteStream, t]);

  const onSubmit = useCallback(async (params) => {
    if (streamId === 'create') {
      const createdStreamId = await createStream(params);
      if (createdStreamId?.match(UUID_PATTERN)) afterCreateStream(createdStreamId);
    } else updateStream(streamId, params);
  }, [streamId, createStream, updateStream, afterCreateStream]);

  if (isNotFound) {
    return (
      <div
        className="NotFoundPage"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div className="NotFoundPage__Content">
          {t('lunaStreamsStreams:api.notFound', { stream: { stream_id: streamId } })}
        </div>
      </div>
    );
  }

  return (
    <StreamEditForm
      onDelete={onDelete}
      onSubmit={onSubmit}
      state={streamId === 'create' ? 'create' : 'edit'}
      values={streamData}
    />
  );
}

EditStreamPageComponent.propTypes = {
  streamId: PropTypes.string.isRequired,
  createStream: PropTypes.func.isRequired,
  updateStream: PropTypes.func.isRequired,
  deleteStream: PropTypes.func.isRequired,
  afterCreateStream: PropTypes.func.isRequired,
  afterDeleteStream: PropTypes.func.isRequired,
};

export const EditStreamPage = connect(() => ({
}), (dispatch) => ({
  createStream: (params) => dispatch(createStreamAction(params)),
  updateStream: (streamId, params) => dispatch(updateStreamAction(streamId, params)),
  deleteStream: (streamId) => dispatch(deleteStreamAction(streamId)),
}))(EditStreamPageComponent);
