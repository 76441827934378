import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const PlayerPauseIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="PlayerPauseIcon" ref={iconRef} viewBox="0 0 24 24" {...props}>
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth} d="M10 4H6v16h4V4Zm8 0h-4v16h4V4Z" />
    </IconInner>
  );
};

export const PlayerPauseIcon = memo(PlayerPauseIconInner);
