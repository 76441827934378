import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const LinkBrokenIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="LinkBrokenIcon" ref={iconRef} viewBox="0 0 24 24" {...props}>
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth} d="M8.97 11.8a3.776 3.776 0 0 0-.93-.2 3.13 3.13 0 0 0-2.51.82L2.8 15.1a3.16 3.16 0 0 0-.82 2.52 4.46 4.46 0 0 0 1.29 2.67v0a4.46 4.46 0 0 0 2.67 1.29 3.16 3.16 0 0 0 2.56-.78l2.68-2.69A3.13 3.13 0 0 0 12 15.6a3.767 3.767 0 0 0-.2-.93m2.83-2.87c.302.1.614.168.93.2a3.17 3.17 0 0 0 2.52-.82L20.8 8.5a3.16 3.16 0 0 0 .82-2.52 4.46 4.46 0 0 0-1.29-2.67v0a4.46 4.46 0 0 0-2.67-1.29 3.16 3.16 0 0 0-2.56.78l-2.68 2.72a3.17 3.17 0 0 0-.82 2.52c.032.316.1.628.2.93m-6.07 0h-2m5.24-3.24v-2m5.66 16.14v-2m5.24-3.24h-2" />
    </IconInner>
  );
};

export const LinkBrokenIcon = memo(LinkBrokenIconInner);
