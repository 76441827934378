import { api } from '@vlabs/api-bindings/src/i18n';
import accounts from '@vlabs/pages/accounts/i18n/accounts_ru.json';
import featuresPage from '@vlabs/pages/app/i18n/featuresPage_ru.json';
import info from '@vlabs/pages/app/i18n/info_ru.json';
import plugins from '@vlabs/pages/app/i18n/plugins_ru.json';
import auth from '@vlabs/pages/auth/i18n/auth_ru.json';
import checks from '@vlabs/pages/checks/i18n/checks_ru.json';
import departments from '@vlabs/pages/departments/i18n/departments_ru.json';
import events from '@vlabs/pages/events/i18n/events_ru.json';
import externalID from '@vlabs/pages/external-id/i18n/externalID_ru.json';
import faces from '@vlabs/pages/faces/i18n/faces_ru.json';
import handlers from '@vlabs/pages/handlers/i18n/handlers_ru.json';
import lists from '@vlabs/pages/lists/i18n/lists_ru.json';
import vlNotifier from '@vlabs/pages/notifications/i18n/vlNotifier_ru.json';
import searchByDocuments from '@vlabs/pages/search-by-documents/i18n/searchByDocuments_ru.json';
import search from '@vlabs/pages/search/i18n/search_ru.json';
import sources from '@vlabs/pages/sources/i18n/sources_ru.json';
import lunaStreams from '@vlabs/pages/sources/luna-streams/translations/ru';
import statistic from '@vlabs/pages/statistic/i18n/statistic_ru.json';
import tasks from '@vlabs/pages/tasks/i18n/tasks_ru.json';
import timeTracking from '@vlabs/pages/time-tracking-tasks/i18n/timeTracking_ru.json';
import { vaReporter } from '@vlabs/pages/va-reporter/i18n';
import verifiers from '@vlabs/pages/verifiers/i18n/verifiers_ru.json';
import { shared } from '@vlabs/shared/i18n';
import { i18n as uikitI18n } from '@vlabs/uikit';

export default {
  accounts,
  auth,
  departments,
  events,
  externalID,
  faces,
  featuresPage,
  handlers,
  info,
  checks,
  lists,
  search,
  searchByDocuments,
  sources,
  tasks,
  verifiers,
  vlNotifier,
  plugins,
  timeTracking,
  statistic,
  ...lunaStreams,
  ...api.ru,
  ...vaReporter.ru,
  ...shared.ru,
  ...uikitI18n.ru,
};
