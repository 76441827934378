import React, { memo } from 'react';

import { useDynamicStroke } from '../../hooks/useDynamicStroke';
import { IconInner } from '../../IconInner';

const CircleCheckIconInner = (props) => {
  const { iconRef, strokeWidth } = useDynamicStroke();
  return (
    <IconInner name="CircleCheckIcon" ref={iconRef} viewBox="0 0 24 24" {...props}>
      <path d="M7.49976 12.8L10.129 15.4292C10.1681 15.4684 10.2317 15.4682 10.2707 15.4289L16.7 8.9502M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    </IconInner>
  );
};

export const CircleCheckIcon = memo(CircleCheckIconInner);
