import React from 'react';

import { ArrowStraightUpRightIcon } from '@vlabs/icons';
import { Control } from '@vlabs/uikit';

import st from './StatisticsCard.module.sass';

export const StatisticsCard = ({ title, image, onClick }) => {
  return (
    <div className={st.StatisticsCard}>
      <div className={st.Title}>
        <h5>{title}</h5>
        <Control.RoundButton className={st.RoundButton} icon={<ArrowStraightUpRightIcon />} kind="secondary" onClick={onClick} />
      </div>
      <div>
        <img alt={title} className={st.Image} src={image} />
      </div>
    </div>
  );
};
