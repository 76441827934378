import React from 'react';

import { CircleCheckIcon } from '@vlabs/icons';
import { detectionType } from '@vlabs/uikit/src/types';
import cn from 'classnames';
import PropTypes from 'prop-types';

import st from './ListPicker.module.sass';

export function ListPicker({
  detections,
  isSelected,
  onSelect,
}) {
  return (
    <div className={st.Root}>
      {detections.map(({ image, type, id }) => (
        <div
          className={cn({
            InteractionWrapper: true,
            [st.Detection]: true,
            [st[`Detection_${type}`]]: type,
            [st.Detection_selected]: isSelected(id),
          })}
          data-testid="listPicker.detection"
          key={id}
          onClick={() => onSelect(id)}
          onKeyPress={() => onSelect(id)}
          role="button"
          tabIndex="0"
        >
          <img
            alt={id}
            className={st.Image}
            key={id}
            src={image}
          />
          <CircleCheckIcon className={cn({
            [st.SelectionStatus]: true,
            [st.SelectionStatus_selected]: isSelected(id),
          })}
          />
          <div className="InteractionOverlay InteractionOverlay_dense" />
        </div>
      ))}
    </div>
  );
}

ListPicker.propTypes = {
  detections: PropTypes.arrayOf(detectionType),
  isSelected: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

ListPicker.defaultProps = {
  detections: [],
};
