import React, { forwardRef } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import './BaseButton.sass';

const BaseButton = forwardRef(({
  variant,
  kind,
  children,
  fullWidth,
  icon,
  className,
  round,
  as,
  disabled,
  title,
  size,
  ...props
}, externalRef) => {
  const Component = as || 'button';

  return (
    <Component
      className={cn(
        className,
        'InteractionWrapper',
        {
          Button: true,
          Button_fullWidth: fullWidth,
          RoundButton: round,
          [`Button_${kind}`]: kind,
          [`Button_${variant}`]: variant,
          'Button--disabled': disabled,
          [`Button__size_${size}`]: size,
          Button_iconText: (icon && children),
          Button_onlyIcon: (icon && !children),
        },
      )}
      disabled={disabled}
      ref={externalRef}
      title={title}
      {...props}
    >
      {icon && (
        <span className="Icon Button__Icon">
          {icon}
        </span>
      )}

      <div className="Button__Content Subtitle-2">
        {children}
      </div>

      <div className="Button__Overlay InteractionOverlay" />
    </Component>
  );
});

BaseButton.displayName = 'BaseButton';

BaseButton.propTypes = {
  variant: PropTypes.oneOf([
    'filled',
    'flat',
    'invisible',
    'outlined',
    'dimmed',
  ]),
  kind: PropTypes.oneOf([
    'primary',
    'negative',
    'attention',
    'positive',
    'secondary',
    'alarm',
  ]),
  type: PropTypes.oneOf([
    'button',
    'submit',
    'reset',
  ]),
  size: PropTypes.oneOf([
    'medium',
    'small',
  ]),
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  round: PropTypes.bool,
  title: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

BaseButton.defaultProps = {
  variant: 'filled',
  kind: 'primary',
  type: 'button',
  size: 'medium',
  fullWidth: false,
  children: undefined,
  className: undefined,
  icon: undefined,
  disabled: undefined,
  round: undefined,
  title: undefined,
  as: undefined,
};

export { BaseButton };
