import { createSlice } from '@reduxjs/toolkit';

import { SLICE_NAME } from './constants';

const initialState = {
  pageData: {
    data: [],
    meta: {
      count: 0,
    },
  },
  groupsPageData: {
    data: [],
    meta: {
      count: 0,
    },
  },
  streams: [],
  streamOptions: [],
  groups: [],
  groupOptions: [],
  byId: {},
  groupById: {},
  filters: [],
  groupsFilters: {},
  pageIndex: 0,
  groupsPageIndex: 0,
  pageSize: 25,
  groupsPageSize: 25,
  pageSizeOptions: [10, 25, 50, 100],
  groupsPageSizeOptions: [10, 25, 50],
  state: 'notAsked',
  groupsState: 'notAsked',
};

const store = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setPageData(state, { payload }) {
      state.pageData = payload;
    },
    setStreams(state, { payload }) {
      state.streams = payload;
      state.byId = {};
      (payload || []).forEach((stream) => {
        state.byId[stream.stream_id] = stream;
      });

      state.streamOptions = payload.map(({ stream_id, name, preview }) => ({
        streamId: stream_id,
        preview,
        value: name,
        label: name,
      }));
      state.state = 'loaded';
    },
    setFilters(state, { payload: { filters } = {} }) {
      if (filters !== undefined) state.filters = filters;
    },
    setPageIndex(state, { payload }) {
      if (payload !== undefined) state.pageIndex = payload;
    },
    setPageSize(state, { payload }) {
      if (payload !== undefined) state.pageSize = payload;
    },
    setGroupsPageData(state, { payload }) {
      state.groupsPageData = payload;
      state.groupById = {};
      (payload.data || []).forEach((group) => {
        state.groupById[group.group_id] = group;
      });
      state.state = 'loaded';
    },
    setGroups(state, { payload }) {
      state.groups = payload;
      state.groupById = {};
      (payload.data || []).forEach((group) => {
        state.groupById[group.group_id] = group;
      });
      state.groupOptions = payload.map(({ group_name, group_id, account_id }) => ({
        label: group_name,
        value: group_name,
        groupId: group_id,
        accountId: account_id,
      }));
      state.state = 'loaded';
    },
    setGroupOptions(state, { payload }) {
      state.groupOptions = payload;
    },
    setGroupsPageIndex(state, { payload }) {
      state.groupsPageIndex = payload;
    },
    setGroupsPageSize(state, { payload }) {
      state.groupsPageSize = payload;
    },
  },
});

export default store.reducer;

export const {
  setFilters,
  setPageIndex,
  setPageSize,
  setPageData,
  setStreams,
  setGroupsPageData,
  setGroups,
  setGroupOptions,
  setGroupsPageSize,
} = store.actions;
